import {DialogActions, Grid, Skeleton} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import TextFormInput from "../genericui/formcomponents/TextFormInput";
import SettingsService from "../../services/SettingsService";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {PowerSettingsNew} from "@mui/icons-material";
import SubscriptionService from "../../services/SubscriptionService";

export default function ProfileSettingsForm(props) {
    const [formData, setFormData] = useState({});
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isFirstLoad) {
            SettingsService.getProfileInfo().then((response) => {
                    setFormData(response.data);
                },
                _ => {
                    props.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.");
                }
            ).finally(() => setIsFirstLoad(false))
        }
    });

    function handleFormFieldChange(event) {
        let currentFormData = formData;
        currentFormData[event.target.id] = event.target.value;
        setFormData(currentFormData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        SettingsService.updateProfile(formData).then(response => props.showSuccessMessage("Tu perfil ha sido actualizado con éxito")
        ).catch(_ => props.handleAPIError("No se ha podido actualizar tu perfil. Revisa los datos o intenta de nuevo más tarde.")
        ).finally(() => {
            setIsLoading(false);
        })
    }

    function logOut() {
        window.localStorage.clear();
        window.location.replace('/login/')
    }

    if (isFirstLoad) {
        return <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container spacing={4} maxWidth="md" sx={{mt: 1}}>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
            </Grid>
        </Box>
    }

    const getManageSubscriptionLink = () => {
        SubscriptionService.getPortalLink().then((response) => {
            window.open(response.data.link, "_blank");
        })
    }

    return <form onSubmit={handleSubmit}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'right'}}>
                <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'center', m: 1}}>
                    <Grid item xs={12} sm={6}>
                        <Button fullWidth color="info" variant="contained" onClick={getManageSubscriptionLink}>Gestionar
                            mi suscripción</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFormInput
                        onChange={handleFormFieldChange}
                        id="first_name"
                        defaultValue={formData.first_name}
                        label="Nombre"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFormInput
                        onChange={handleFormFieldChange}
                        id="last_name"
                        defaultValue={formData.last_name}
                        label="Apellidos"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFormInput
                        onChange={handleFormFieldChange}
                        id="username"
                        defaultValue={formData.username}
                        label="Nombre de Usuario"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFormInput
                        onChange={handleFormFieldChange}
                        id="email"
                        defaultValue={formData.email}
                        label="Email"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DialogActions>
                        <Button variant='contained' type="submit" fullWidth>{isLoading ?
                            <CircularProgress/> : "Guardar"}</Button>
                    </DialogActions>
                </Grid>
                <Grid item sx={{mt: 8}} xs={12}>
                    <Grid item xs={12}>
                        <Button variant='outlined' fullWidth startIcon={<PowerSettingsNew/>} onClick={logOut}>Cerrar
                            sesión</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </form>
}