import api from './Api';

class TODOService {
    getAllTasks() {
        return api.get('/tasks/');
    }

    addTask(task) {
        return api.post('/tasks/', task);
    }

    updateTask(id, task) {
        return api.patch(`/tasks/${id}/`, task);
    }

    deleteTask(id) {
        return api.delete(`/tasks/${id}/`);
    }
}

export default new TODOService();