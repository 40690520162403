import {
    Autocomplete,
    DialogActions,
    Grid,
} from "@mui/material";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import GenericModal from "../../../genericui/modals/GenericModal";
import TextFormInput from "../../../genericui/formcomponents/TextFormInput";
import ColorsSelectInput from "../../../genericui/calendar/ColorsSelectInput";

export default function AddTabModal(props) {
    const [currentFormData, setCurrentFormData] = useState({
        name: "",
        color: "#039BE5",
        copyFrom: "",
    });
    const finalMarksTabIndex = props.tabs.indexOf(props.tabs.find((element) => element.id === 0));
    const otherTabs = [...props.tabs];
    otherTabs.splice(finalMarksTabIndex, 1);

    function submitChanges(event) {
        event.preventDefault();
        props.onSubmit(currentFormData);
        props.onClose();
    }

    function handleFormFieldChange(event) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData[event.target.id] = event.target.value;
        setCurrentFormData(localCurrentFormData);
    }

    function handleColorFieldChange(newValue) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData.color = newValue;
        setCurrentFormData(localCurrentFormData);
    }

    function handleCopyFromFieldChange(event, value) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData.copyFrom = value ? value.id : "";
        setCurrentFormData(localCurrentFormData);
    }

    return <GenericModal>
        <form onSubmit={submitChanges}>
            <Grid container maxWidth="sm" spacing={1.5}>
                <Grid item xs={12} sm={12}>
                    <TextFormInput
                        onChange={handleFormFieldChange}
                        id="name"
                        label="Nombre de la pestaña"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ColorsSelectInput color={currentFormData.color} handleChange={handleColorFieldChange}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Autocomplete
                        id="subject"
                        options={otherTabs}
                        getOptionLabel={(option) => option.name}
                        onChange={handleCopyFromFieldChange}
                        renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                            size="small" label="Copiar columnas de pestaña existente..."
                                                            variant="standard"/>}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <DialogActions>
                    <Button onClick={props.onClose}>Cancelar</Button>
                    <Button type="submit" variant='contained'>Guardar</Button>
                </DialogActions>
            </Grid>
        </form>
    </GenericModal>
}