import React, {useEffect, useState} from "react";
import DiaryEventsService from "../../services/DiaryService";
import {parseISO} from "date-fns";
import DiaryCustomEditor from "../class_diary/DiaryCustomEditor";
import {Skeleton, Typography} from "@mui/material";
import Scheduler from "@aldabil/react-scheduler";
import {FIELDS, TRANSLATIONS, MONTH, WEEK, DAY} from "./DiaryConfig";
import es from "date-fns/locale/es";
import DiaryEventCustomRenderer from "../class_diary/DiaryEventCustomRenderer";
import MainCard from "../genericui/cards/MainCard";
import {useOutletContext} from "react-router-dom";
import SettingsService from "../../services/SettingsService";

export default function ClassDiary() {
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (isFirstLoad) {
            SettingsService.getCalendarSettings().then((response) => {
                setSettings(response.data);
            },
            _ => {
                context.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.");
            }
        ).finally(() => setIsFirstLoad(false))
        }
    }, [context, isFirstLoad]);

    function getDiaryEvents(params) {
        return DiaryEventsService.getExistingDiaryEvents(params.start.toISOString(), params.end.toISOString())
            .then((response) => {
                    const newData = response.data

                    newData.forEach((element) => {
                            element.event_id = element.id;
                            element.start = new Date(parseISO(element.start));
                            element.end = new Date(parseISO(element.end));
                        }
                    );
                    return newData;
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus eventos. Inténtalo de nuevo más tarde.");
                    setIsFirstLoad(false);
                });
    }

    function onEventDrop(droppedOn, updatedEvent, originalEvent) {
        return DiaryEventsService.updateDiaryEvent(updatedEvent, originalEvent.event_id).then(
            () => {
                const event = updatedEvent;
                event.event_id = updatedEvent.id;
                return event;
            },
            _ => context.handleAPIError("No se ha podido actualizar tu evento. Inténtalo de nuevo más tarde."));
    }

    function customEditor(scheduler) {
        return <DiaryCustomEditor scheduler={scheduler} handleAPIError={context.handleAPIError}/>;
    }

    function onDelete(deletedId) {
        return DiaryEventsService.deleteDiaryEvent(deletedId).then(
            () => deletedId,
            _ => context.handleAPIError("No se ha podido borrar el evento. Inténtalo de nuevo más tarde.")
        );
    }

    function viewerExtraComponent(fields, event) {
        return (
            <>
                {event.body ? _getBodyDiv(event.body) : null}
            </>
        );
    }

    function _getBodyDiv(body) {
        return (
            <>
                <Typography variant="h6">Contenido:</Typography>
                <div dangerouslySetInnerHTML={{__html: body}}/>
            </>
        );
    }

        if (isFirstLoad) {
            return (<Skeleton variant="rectangular" height={"1000px"}/>);
        }
        return <MainCard title="📅 Diario de Clase" sx={{overflowY: 'auto',}}>
            <Scheduler
                day={DAY}
                week={WEEK}
                month={MONTH}
                fields={FIELDS}
                translations={TRANSLATIONS}
                hourFormat={"24"}
                locale={es}
                draggable={false}
                customEditor={customEditor}
                onEventDrop={onEventDrop}
                onDelete={onDelete}
                getRemoteEvents={getDiaryEvents}
                eventRenderer={DiaryEventCustomRenderer}
                viewerExtraComponent={viewerExtraComponent}
                view={settings.diary_default_mode}
            />
        </MainCard>;
}