import api from "./Api";

class AIService {
    getSDAs() {
        return api.get("/ai/sdas");
    }

    saveSDA(data) {
        return api.post("/ai/sdas", data);
    }

    generateSDA(data) {
        return api.post("/ai/sdas/generate", data);
    }

    getEjercicios() {
        return api.get("/ai/ejercicios");
    }

    saveEjercicio(data) {
        return api.post("/ai/ejercicios", data);
    }

    generateEjercicio(data) {
        return api.post("/ai/ejercicios/generate", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    checkActivity(data) {
        return api.post("/ai/checker", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    generateRubric(data) {
        return api.post("/ai/rubrics/generate", data);
    }
}

export default new AIService();