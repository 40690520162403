import TextField from "@mui/material/TextField";
import 'react-quill/dist/quill.snow.css';


export default function TextFormInput(props) {
    return (
        <>
            <TextField
                {...props}
                sx={{m: '1%', mt: 1.5, minWidth: '48%'}}
                size='small'
                variant='standard'
                autoComplete="off"
            />
        </>
    );
}