import React, {Component} from 'react';

import {Button, Divider} from '@mui/material';
import TextFormInput from "../../genericui/formcomponents/TextFormInput";
import StudentsService from "../../../services/StudentsService";
import StudentPresenter from "../presenters/StudentPresenter";
import Typography from "@mui/material/Typography";


export default class EditStudentForm extends Component {
    constructor(props) {
        super(props);

        this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            studentData: {},
        };
    }

    handleFormFieldChange = (event) => {
        let currentFormData = this.state.studentData;
        currentFormData[event.target.id] = event.target.value;
        this.setState({studentData: currentFormData});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        StudentsService.updateStudent(this.state.studentData, this.props.id).then(response => {
                const student = StudentPresenter.getStudentFromAPI(response.data);
                this.props.onUpdateStudent(student);
                this.props.onClose();
            },
            _ => this.props.handleAPIError("No se ha podido actualizar el alumno. Revisa los datos o intenta de nuevo más tarde."))
    };

    render() {
        return (
            <React.Fragment>
                <form className={"Avatar"} onSubmit={this.handleSubmit}>
                    <Typography>¡Atención! al introducir datos aquí, estos no se anonimizarán. Asegúrate de no introducir información sensible y/o de tener el consentimiento expreso.</Typography>
                    <TextFormInput id="first_name" onChange={this.handleFormFieldChange} label="Nombre"
                                   defaultValue={this.props.student.first_name} required fullWidth/>
                    <TextFormInput id="last_name" onChange={this.handleFormFieldChange} label="Apellidos"
                                   defaultValue={this.props.student.last_name} required fullWidth/>
                    <Divider sx={{mt: 1.5}}/>
                    <Button type="submit" variant="contained" sx={{mt: 1.5}}>Guardar</Button>
                </form>
            </React.Fragment>);
    }
};
