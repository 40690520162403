import React, {useState} from "react";
import StudentsService from "../../../services/StudentsService";
import TextFormInput from "../../genericui/formcomponents/TextFormInput";
import {
    Button,
    Checkbox,
    ClickAwayListener,
    DialogActions,
    Divider,
    FormControlLabel,
    Grid,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";

export default function MassAddStudentForm(props) {
    const {classroomId} = useParams();
    const [formData, setFormData] = useState({});
    const [numberOfStudents, setNumberOfStudents] = useState(1);
    const [createdStudents, setCreatedStudents] = useState([]);
    const [selected, setSelected] = useState(true);
    const tooltipMessage = "Para cumplir con la LOPD, se deben anonimizar todos los nombres. Si desmarcas esta casilla, declaras que tienes consentimiento de los padres o tutores legales para dar esta información.";

    const [isTooltipOpen, setIsToolTipOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setIsToolTipOpen(false);
    };

    const handleTooltipOpen = () => {
        setIsToolTipOpen(true);
    };

    const checkboxLabel = <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography>Anonimizar nombres y guardar solo sus iniciales y su primer apellido. </Typography>
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={isTooltipOpen}
                disableFocusListener
                title={tooltipMessage}
            >
                <Button onClick={handleTooltipOpen} variant="text">Más Información</Button>
            </Tooltip>
        </ClickAwayListener>
    </Box>;

    function handleFormFieldChange(event) {
        let currentFormData = formData;
        currentFormData[event.target.id] = event.target.value;
        setFormData(currentFormData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        for (let i = 1; i <= numberOfStudents; i++) {
            let firstName = formData['first_name' + i];
            let lastName = formData['last_name' + i];
            createNewStudent(firstName, lastName);
        }
        props.onClose();
    }

    function createNewStudent(firstName, lastName) {
        StudentsService.createNewStudent({
            "first_name": firstName,
            "last_name": lastName
        }, selected).then((response) => {
                StudentsService.addStudentToClassrooms({'classroom_ids': [classroomId]}, response.data.id).then(
                    response => {
                        let currentCreatedStudents = [...createdStudents];
                        currentCreatedStudents.push(response.data);
                        setCreatedStudents(currentCreatedStudents);
                        props.onNewStudents(currentCreatedStudents);
                    },
                    _ => props.handleAPIError("No se han podido crear los alumnos. Revisa los datos o intenta de nuevo más tarde."));
            },
            _ => props.handleAPIError("No se han podido crear los alumnos. Revisa los datos o intenta de nuevo más tarde."))
    }

    function nameFormBlock(fieldId) {
        return (<React.Fragment key={"block" + fieldId}>
            <Grid container sx={{display: 'flex', alignItems: 'center', mb: 1}} spacing={1}>
                <Grid item xs={12} lg={6}>
                    <TextFormInput id={"first_name" + fieldId} onChange={handleFormFieldChange}
                                   label={"Nombre " + fieldId}
                                   key={"first_name" + fieldId} required fullWidth/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextFormInput id={"last_name" + fieldId} onChange={handleFormFieldChange}
                                   label={"Apellidos " + fieldId}
                                   key={"last_name" + fieldId} required fullWidth/>
                </Grid>
            </Grid>
            <Divider/>
        </React.Fragment>);
    }

    function renderFields() {
        let formFieldsToReturn = [];
        for (let i = 1; i <= numberOfStudents; i++) {
            formFieldsToReturn.push(nameFormBlock(i));
        }
        return formFieldsToReturn;
    }

    function moreStudents() {
        return (
            <Button key={"add-button"} onClick={() => {
                setNumberOfStudents(numberOfStudents + 1);
            }}>+ Añadir un estudiante al formulario</Button>
        );
    }

    function lessStudents() {
        return (
            <Button key={"remove-button"} onClick={() => {
                if (numberOfStudents > 1) {
                    setNumberOfStudents(numberOfStudents - 1);
                }
            }}>- Quitar un estudiante del formulario</Button>
        );
    }

    return (
        <React.Fragment>
            <form key={"massform"} onSubmit={handleSubmit}>
                <Typography>Introduce tantos alumnos como necesites.</Typography>
                <Divider/>
                {renderFields()}
                {moreStudents()}
                {lessStudents()}
                <Divider/>
                <Grid container sx={{display: 'flex', alignItems: 'center', mb: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={selected} onChange={() => setSelected(!selected)}/>}
                            label={checkboxLabel}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            <Button onClick={props.onClose} fullWidth sx={{mt: 1.5}}>Cancelar</Button>
                            <Button key={"submit-mass-form"} type="submit" variant="contained"
                                    sx={{mt: 1.5}} fullWidth>Añadir</Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    );
}