import React, {useEffect, useState} from 'react';

import {Autocomplete, Button} from '@mui/material';
import TextFormInput from "../../genericui/formcomponents/TextFormInput";
import ClassroomsService from "../../../services/ClassroomService"
import TextField from "@mui/material/TextField";
import {COMUNIDADES} from "../utils";

export default function NewClassroomForm(props) {
    const [newClassroomData, setNewClassroomData] = useState({
        "name": "",
        "description": "",
        "id": "",
        "academic_year": "2024",
        "created_by_id": "",
        "lomloe_level": "",
        "lomloe_region": "",
    });

    const yearOptions = [{'label': '2024/25', 'value': "2024"}];
    const [academicLevels, setAcademicLevels] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            ClassroomsService.getAcademicInfo().then((response) => {
                    setAcademicLevels(response.data.levels);
                },
                _ => props.handleAPIError("No se ha podido crear la clase. Revisa los datos o intenta de nuevo más tarde.")
            );
            setIsFirstLoad(false);
        }
    }, [isFirstLoad, props]);

    function handleFormFieldChange(event) {
        let currentFormData = newClassroomData;
        currentFormData[event.target.id] = event.target.value;
        setNewClassroomData(currentFormData);
    }

    function handleFormOptionChange(event, value) {
        let currentFormData = newClassroomData;
        const parentId = event.target.id.split("-")[0]
        currentFormData[parentId] = value.id;
        setNewClassroomData(currentFormData);
    }

    function handleAcademicYearChange(event, value) {
        let currentClassroomData = newClassroomData;
        currentClassroomData["academic_year"] = value.value;
        setNewClassroomData(currentClassroomData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        ClassroomsService.createNewClassroom(newClassroomData).then(response => {
                props.onNewClassroom(response.data);
                props.onClose();
            },
            _ => props.handleAPIError("No se ha podido crear la clase. Revisa los datos o intenta de nuevo más tarde.")
        );
    }

    return (
        <React.Fragment>
            <form className={"Avatar"} onSubmit={handleSubmit}>
                <TextFormInput id="name" onChange={handleFormFieldChange} label="Nombre de la Clase" required
                               fullWidth/>
                <Autocomplete
                    disablePortal
                    id="new-class-year"
                    defaultValue={yearOptions[0]}
                    options={yearOptions}
                    label="Año Académico"
                    getOptionLabel={(option) => option.label}
                    onChange={handleAcademicYearChange}
                    renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                        size="small" id="academic_year" required label="Año Académico"
                                                        variant="standard"/>}
                />
                <Autocomplete
                    id="lomloe_region"
                    options={COMUNIDADES}
                    getOptionLabel={(option) => option.name}
                    onChange={handleFormOptionChange}
                    renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                        size="small" required label="Comunidad Autónoma"
                                                        variant="standard"/>}
                />
                <Autocomplete
                    id="lomloe_level"
                    options={academicLevels}
                    getOptionLabel={(option) => option.name}
                    onChange={handleFormOptionChange}
                    renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                        size="small" required label="Nivel"
                                                        variant="standard"/>}
                />
                <TextFormInput id="description" onChange={handleFormFieldChange} label="Descripción" multiline
                               minRows={3} fullWidth/>
                <Button type="submit" variant="contained" sx={{mt: 1.5}}>Añadir</Button>
            </form>
        </React.Fragment>
    );
}
