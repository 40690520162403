import api from "./Api";
import {LATEST_CLASSROOM_ID_FROM_2023} from "../components/classrooms/utils";

class SubjectService {
    getSubjectsByClassroom(classroomId) {
        return api.get('/classrooms/' + classroomId + '/lomloe-subjects/');
    }

    createNewSubject(classroomId, data) {
        return api.post('/classrooms/' + classroomId + '/lomloe-subjects/', data);
    }

    deleteSubject(classroomId, subjectId) {
        return api.delete('/classrooms/' + classroomId + '/lomloe-subjects/' + subjectId + '/');
    }

    getSubjectSummary(classroomId, subjectId) {
        return api.get('/classrooms/' + classroomId + '/subjects/' + subjectId + '/summary/');
    }

    getSubjectSummaryByTabs(classroomId, subjectId, selectedTabsIds, headerName) {
        const tabs = selectedTabsIds.join(",");
        if (classroomId < LATEST_CLASSROOM_ID_FROM_2023)
            return api.get('/classrooms/' + classroomId + '/subjects/' + subjectId + '/swapped-summary/?tabs=' + tabs + '&header=' + headerName);
        return api.get('/classrooms/' + classroomId + '/subjects/' + subjectId + '/lomloe-summary/?tabs=' + tabs + '&header=' + headerName);
    }

    getLomloeSubjectsByRegionAndLevel(region, level) {
        return api.get('/lomloev2/subjects/?region=' + region + '&level=' + level);
    }

}

export default new SubjectService();
