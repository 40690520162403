import TextField from "@mui/material/TextField";
import React from "react";

export function EventTitleInput(props) {
    return <TextField
        label="Añade un título"
        value={props.value}
        onChange={(e) => props.handleChange(e.target.value, "title")}
        error={!!props.error}
        helperText={!!props.error && props.error["title"]}
        fullWidth
        variant='standard'
        inputProps={{style: {fontSize: 28}}}
        InputLabelProps={{style: {fontSize: 28}}}
        required
    />;
}