import {Grid, Skeleton, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import SubjectService from "../../../services/SubjectService";
import AllSubjectsSummary from "../../classrooms/subjects/AllSubjectsSummary";
import {evaluationBoxStyles} from "../../classrooms/utils";

export default function ViewStudentGrid(props) {
    const {classroomId} = useParams();
    const context = useOutletContext();
    const [subjects, setSubjects] = React.useState([]);
    const [isFirstLoad, setIsFirstLoad] = React.useState(true);

    const fieldsMap = {
        'first_name': "Nombre",
        'last_name': "Apellidos",
    }

    function renderAllFields() {
        return (
            <>
                {Object.entries(props.student).map(([key, value], i) => {
                    if (key in fieldsMap) {
                        return (
                            <Grid key={key + value} item xs={6}>
                                <Box>
                                    <Typography key={key + '1'} variant="subtitle2"
                                                color="inherit">{fieldsMap[key] + ":"}</Typography>
                                    <Typography key={key + '2'} variant="subtitle1"
                                                component="pre">{value ? value : '\u00A0-'}</Typography>
                                </Box>
                            </Grid>
                        );
                    }

                    return (<></>);
                })}

            </>
        );
    }

    useEffect(() => {
        SubjectService.getSubjectsByClassroom(classroomId).then(
            subjects => {
                setSubjects(subjects.data);
                setIsFirstLoad(false);
            },
            _ => context.handleAPIError("Ha ocurrido un error cargando tus asignaturas. Inténtalo de nuevo más tarde.")
        );
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {renderAllFields()}
                </Grid>
                <Grid container sx={{p: 1}}>
                    <Grid item xs={12} sx={evaluationBoxStyles}>{isFirstLoad ? <Skeleton/> :
                            <AllSubjectsSummary subjects={subjects} studentId={props.student.id}/>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}