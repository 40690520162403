import api from "./Api";


class StudentsService {
    getStudentsByCreator() {
        return api.get('/students/');
    }

    getStudentsByClassroom(classroomId) {
        return api.get('/students/associated_to_classroom/' + classroomId + '/');
    }

    createNewStudent(data, isAnonymous) {
        return api.post(`/students/?anonymize=${!!isAnonymous}`, data);
    }

    updateStudent(data, studentId) {
        return api.patch('/students/' + studentId + '/', data);
    }

    deleteStudent(studentId) {
        return api.delete('/students/' + studentId + '/');
    }

    addStudentToClassrooms(data, studentId) {
        return api.post('/students/' + studentId + '/add_classrooms/', data)
    }

    removeStudentFromClassrooms(data, studentId) {
        return api.post('/students/' + studentId + '/remove_classrooms/', data)
    }
}

export default new StudentsService();
