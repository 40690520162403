import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import {Home, School, AutoFixHigh, StickyNote2, Today} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function BottomNavigationMenu(props) {
    const [selectedModule, setSelectedModule] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if(isFirstLoad) {
            const pathList = document.location.pathname.toString().split('/');
            let currentPath = "";
            if (pathList.length > 1) {
                currentPath = pathList[1];
            }
            switch (currentPath) {
                case "clases":
                    setSelectedModule(1);
                    break;
                case "agenda":
                    setSelectedModule(2);
                    break;
                case "notas":
                    setSelectedModule(3);
                    break;
                case "ia":
                    setSelectedModule(4);
                    break;
                default:
                    setSelectedModule(0);
            }
            setIsFirstLoad(false);
        }
    }, [isFirstLoad])

        return <BottomNavigation
            elevation={3}
            showLabels
            value={selectedModule}
            onChange={(event, newValue) => {
                setSelectedModule(newValue);
            }}
        >
            <BottomNavigationAction sx={{ minWidth: "70px", maxWidth: "70px", p: 0 }} component={Link} to="/" label="Inicio" icon={<Home/>}/>
            <BottomNavigationAction sx={{ minWidth: "70px", maxWidth: "70px", p: 0 }} component={Link} to="/clases/" label="Clases" icon={<School/>}/>
            <BottomNavigationAction sx={{ minWidth: "70px", maxWidth: "70px", p: 0 }} component={Link} to="/agenda/" label="Agenda" icon={<Today/>}/>
            <BottomNavigationAction sx={{ minWidth: "70px", maxWidth: "70px", p: 0 }} component={Link} to="/post-its/" label="Post-its" icon={<StickyNote2/>}/>
            <BottomNavigationAction sx={{ minWidth: "70px", maxWidth: "70px", p: 0 }} component={Link} to="/ia/sdas" label="IA" icon={<AutoFixHigh/>}/>
        </BottomNavigation>
}