import DiaryEventsService from "../../services/DiaryService";
import {parseISO} from "date-fns";

export function submitDiaryEvent(scheduler, currentState, handleAPIError) {
    const EIGHT_HOURS_IN_MILLISECONDS = 8*60*60000;
    scheduler.loading(true);
    let start = new Date(currentState.date);
    let end = new Date(currentState.date);
    end.setTime(end.getTime() + EIGHT_HOURS_IN_MILLISECONDS);
    const isEdited = scheduler.edited;

    const data = {
        id: currentState.id,
        title: "Diario - " + start.toLocaleDateString(),
        event_type: "CLASS DIARY",
        start: start.toISOString(),
        end: end.toISOString(),
        body: currentState.body,
        color: currentState.color,
    };
    if (isEdited) {
        DiaryEventsService.updateDiaryEvent(data, data.id).then(
            response => _refreshSchedulerWithNewData(response.data, scheduler, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    } else {
        DiaryEventsService.createNewDiaryEvent(data).then(
            response => _refreshSchedulerWithNewData(response.data, scheduler, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    }
}

function _refreshSchedulerWithNewData(responseData, scheduler, isEdited) {
    const newData = responseData;
    newData.event_id = newData.id;
    newData.start = new Date(parseISO(newData.start));
    newData.end = new Date(parseISO(newData.end));

    scheduler.onConfirm(newData, isEdited ? "edit" : "create");
    scheduler.loading(false);
    scheduler.close();
}
