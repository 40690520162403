import api from "./Api";


class MeetingsEventsService {
    getExistingMeetingEvents(start, end) {
        const params = {"start": start, "end": end};
        return api.get('/scheduler/meeting-events/', { params });
    }

    createNewMeetingEvent(data) {
        return api.post('/scheduler/meeting-events/', data);
    }

    updateMeetingEvent(data, eventId) {
        return api.patch('/scheduler/meeting-events/' + eventId + '/', data);
    }

    deleteMeetingEvent(eventId) {
        return api.delete('/scheduler/meeting-events/' + eventId + '/');
    }
}

export default new MeetingsEventsService();
