import {DialogActions, Divider, FormControl, Grid, InputLabel, Select, Skeleton} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import SettingsService from "../../services/SettingsService";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";

const TIME_CHOICES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
const STEP_CHOICES = [15, 30, 45, 60]
const VIEW_CHOICES = [{value: "day", label: "Día"}, {value: "week", label: "Semana"}, {value: "month", label: "Mes"}]
const DIARY_VIEW_CHOICES = [{value: "week", label: "Semana"}, {value: "month", label: "Mes"}]

export default function CalendarSettingsForm(props) {
    const [formData, setFormData] = useState({});
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isFirstLoad) {
            SettingsService.getCalendarSettings().then((response) => {
                    setFormData(response.data);
                },
                _ => {
                    props.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.");
                }
            ).finally(() => setIsFirstLoad(false))
        }
    });

    function handleFormFieldChange(event, item) {
        let currentFormData = formData;
        currentFormData[event.target.name] = event.target.value;
        setFormData(currentFormData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        SettingsService.updateCalendarSettings(formData).then(response => props.showSuccessMessage("Tu perfil ha sido actualizado con éxito")
        ).catch(_ => props.handleAPIError("No se ha podido actualizar tu perfil. Revisa los datos o intenta de nuevo más tarde.")
        ).finally(() => {
            setIsLoading(false);
        })
    }

    if (isFirstLoad) {
        return <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container spacing={4} maxWidth="md" sx={{mt: 1}}>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton/>
                </Grid>
            </Grid>
        </Box>
    }

    return <form onSubmit={handleSubmit}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{mb: 3}}
        >
            <PlannerForm/>
        </Box>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{mb: 3}}
        >
            <MeetingsForm/>
        </Box>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{mb: 3}}
        >
            <TutoriasForm/>
        </Box>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{mb: 3}}
        >
            <DiaryForm/>
        </Box>
    </form>

    function PlannerForm() {
        return <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'left'}}>
                <Grid item xs={12}>
                    <Divider>Ajustes del Planner</Divider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="planner_start_hour_label">Hora de inicio de tus clases</InputLabel>
                        <Select
                            id="planner_start_hour"
                            name="planner_start_hour"
                            labelId="planner_start_hour_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.planner_start_hour}
                            label="Hora de inicio de tus clases"
                            size="small"
                        >
                            {TIME_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="planner_end_hour_label">Hora de finalización de tus clases</InputLabel>
                        <Select
                            id="planner_end_hour"
                            name="planner_end_hour"
                            labelId="planner_end_hour_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.planner_end_hour}
                            label="Hora de finalización de tus clases"
                            size="small"
                        >
                            {TIME_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="planner_step_label">Tiempo entre eventos por defecto</InputLabel>
                        <Select
                            id="planner_step"
                            name="planner_step"
                            labelId="planner_step_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.planner_step}
                            label="Tiempo entre eventos por defecto"
                            size="small"
                        >
                            {STEP_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice} minutos</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="planner_default_mode_label">Vista del calendario por defecto</InputLabel>
                        <Select
                            id="planner_default_mode"
                            name="planner_default_mode"
                            labelId="planner_default_mode_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.planner_default_mode}
                            label="Vista del calendario por defecto"
                            size="small"
                        >
                            {VIEW_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice.value}>{choice.label}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'right'}}>
                        <Grid item xs={12} sm={6}>
                            <DialogActions>
                                <Button variant='contained' type="submit" fullWidth>{isLoading ?
                                    <CircularProgress/> : "Guardar"}</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    }

    function MeetingsForm() {
        return <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'left'}}>
                <Grid item xs={12}>
                    <Divider>Ajustes de las Reuniones</Divider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="meetings_start_hour_label">Hora de inicio del horario de reuniones</InputLabel>
                        <Select
                            id="meetings_start_hour"
                            name="meetings_start_hour"
                            labelId="meetings_start_hour_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.meetings_start_hour}
                            label="Hora de inicio del horario de reuniones"
                            size="small"
                        >
                            {TIME_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="meetings_end_hour_label">Hora de finalización del horario de reuniones</InputLabel>
                        <Select
                            id="meetings_end_hour"
                            name="meetings_end_hour"
                            labelId="meetings_end_hour_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.meetings_end_hour}
                            label="Hora de finalización del horario de reuniones"
                            size="small"
                        >
                            {TIME_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="meetings_step_label">Tiempo entre eventos por defecto</InputLabel>
                        <Select
                            id="meetings_step"
                            name="meetings_step"
                            labelId="meetings_step_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.meetings_step}
                            label="Tiempo entre eventos por defecto"
                            size="small"
                        >
                            {STEP_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice} minutos</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="meetings_default_mode_label">Vista del calendario por defecto</InputLabel>
                        <Select
                            id="meetings_default_mode"
                            name="meetings_default_mode"
                            labelId="meetings_default_mode_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.meetings_default_mode}
                            label="Vista del calendario por defecto"
                            size="small"
                        >
                            {VIEW_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice.value}>{choice.label}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'right'}}>
                        <Grid item xs={12} sm={6}>
                            <DialogActions>
                                <Button variant='contained' type="submit" fullWidth>{isLoading ?
                                    <CircularProgress/> : "Guardar"}</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    }

    function TutoriasForm() {
        return <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'left'}}>
                <Grid item xs={12}>
                    <Divider>Ajustes de las Tutorías</Divider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="tutorias_start_hour_label">Hora de inicio del horario de tutorías</InputLabel>
                        <Select
                            id="tutorias_start_hour"
                            name="tutorias_start_hour"
                            labelId="tutorias_start_hour_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.tutorias_start_hour}
                            label="Hora de inicio del horario de tutorías"
                            size="small"
                        >
                            {TIME_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="tutorias_end_hour_label">Hora de finalización del horario de tutorías</InputLabel>
                        <Select
                            id="tutorias_end_hour"
                            name="tutorias_end_hour"
                            labelId="tutorias_end_hour_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.tutorias_end_hour}
                            label="Hora de finalización del horario de tutorías"
                            size="small"
                        >
                            {TIME_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="tutorias_step_label">Tiempo entre eventos por defecto</InputLabel>
                        <Select
                            id="tutorias_step"
                            name="tutorias_step"
                            labelId="tutorias_step_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.tutorias_step}
                            label="Tiempo entre eventos por defecto"
                            size="small"
                        >
                            {STEP_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice}>{choice} minutos</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="tutorias_default_mode_label">Vista del calendario por defecto</InputLabel>
                        <Select
                            id="tutorias_default_mode"
                            name="tutorias_default_mode"
                            labelId="tutorias_default_mode_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.tutorias_default_mode}
                            label="Vista del calendario por defecto"
                            size="small"
                        >
                            {VIEW_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice.value}>{choice.label}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'right'}}>
                        <Grid item xs={12} sm={6}>
                            <DialogActions>
                                <Button variant='contained' type="submit" fullWidth>{isLoading ?
                                    <CircularProgress/> : "Guardar"}</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    }

    function DiaryForm() {
        return <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'left'}}>
                <Grid item xs={12}>
                    <Divider>Ajustes del Diario de Clase</Divider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                        <InputLabel sx={{zIndex: 0}} id="diary_default_mode_label">Vista del calendario del Diario de Clase</InputLabel>
                        <Select
                            id="diary_default_mode"
                            name="diary_default_mode"
                            labelId="diary_default_mode_label"
                            onChange={handleFormFieldChange}
                            defaultValue={formData.diary_default_mode}
                            label="Vista del calendario del Diario de Clase"
                            size="small"
                        >
                            {DIARY_VIEW_CHOICES.map((choice, i) => {
                                return (<MenuItem key={i} value={choice.value}>{choice.label}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} maxWidth="md" sx={{justifyContent: 'right'}}>
                        <Grid item xs={12} sm={6}>
                            <DialogActions>
                                <Button variant='contained' type="submit" fullWidth>{isLoading ?
                                    <CircularProgress/> : "Guardar"}</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    }
}