import * as React from 'react';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Delete} from "@mui/icons-material";
import ConfirmDeletionModal from "../modals/ConfirmDeletionModal";
import {useState} from "react";

export default function DeleteDataGridColumnMenuItem(props) {
    const {callback, colDef} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [callbackFunction, setCallbackFunction] = useState(() => {
    })

    function handleClick() {
        setIsModalOpen(true);
        setCallbackFunction(() => () => callback(colDef));
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    return (
        <>{isModalOpen ? <ConfirmDeletionModal isOpen={isModalOpen} onClose={closeModal}
                                               onConfirm={callbackFunction} {...props}/> : <></>}
            <MenuItem onClick={handleClick}>
                <ListItemIcon>
                    <Delete fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Eliminar columna</ListItemText>
            </MenuItem>
        </>
    );
}
