import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Checkbox, Chip,
    Divider,
    FormControl,
    Grid,
    Select,
    Skeleton,
    Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import SubjectService from "../../../services/SubjectService";
import TextField from "@mui/material/TextField";
import {CheckBox, CheckBoxOutlineBlank, Download} from "@mui/icons-material";
import {exportExcel} from "./presenters/ExcelPresenter";
import Button from "@mui/material/Button";
import {useParams} from "react-router-dom";
import {LATEST_CLASSROOM_ID_FROM_2023} from "../utils";

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

export default function MarksSummary(props) {
    const {classroomId, subjectId} = useParams();
    const foundIndex = props.tabs.findIndex(element => element.id === 0);
    const tabs = [...props.tabs];
    tabs.splice(foundIndex, 1);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [selectedCriteria, setSelectedCriteria] = useState("Criterios");
    const [selectedTabs, setSelectedTabs] = useState(tabs);
    const [isLoading, setIsLoading] = useState(false);

    function fillColumns(selectedTabsIds, columnHeaderName) {
        setIsLoading(true);
        SubjectService.getSubjectSummaryByTabs(classroomId, subjectId, selectedTabsIds, columnHeaderName).then((response) => {
            setColumns(response.data.columns);
            setRows(response.data.rows);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (isFirstLoad) {
            setIsLoading(true);
            const selectedTabsIds = selectedTabs.map(element => element.id);
            SubjectService.getSubjectSummaryByTabs(classroomId, subjectId, selectedTabsIds, "Criterios").then((response) => {
                setColumns(response.data.columns);
                setRows(response.data.rows);
                setIsLoading(false);
                setIsFirstLoad(false);
            })
        }
    }, [props, isFirstLoad, selectedTabs, selectedCriteria, classroomId, subjectId]);

    function CriteriaSelect(props) {
        return <FormControl fullWidth>
            <Select
                id="select"
                value={selectedCriteria}
                onChange={handleSelectedCriteriaChange}
            >
                <MenuItem value={"Criterios"}>Criterios</MenuItem>
                <MenuItem value={"Competencias Específicas"}>Competencias Específicas</MenuItem>
                {classroomId > LATEST_CLASSROOM_ID_FROM_2023 && <MenuItem value={"Competencias Clave"}>Competencias Clave</MenuItem>}
                {classroomId > LATEST_CLASSROOM_ID_FROM_2023 && <MenuItem value={"Descriptores"}>Descriptores</MenuItem>}
            </Select>
        </FormControl>
    }

    function handleSelectedCriteriaChange(event) {
        const newHeaderName = event.target.value;
        const selectedTabsIds = selectedTabs.map(element => element.id);
        fillColumns(selectedTabsIds, newHeaderName);
        setSelectedCriteria(newHeaderName);
        changeFirstHeaderName(newHeaderName);
    }

    function changeFirstHeaderName(newHeaderName) {
        const localColumns = [...columns];
        localColumns[0].headerName = newHeaderName;
        setColumns(localColumns);
    }

    function handleSelectedTabsChange(event, values) {
        setSelectedTabs(values);
        const selectedTabsIds = values.map(element => element.id);
        fillColumns(selectedTabsIds, selectedCriteria);
        setIsLoading(true);
    }

    if (isFirstLoad) {
        return <Skeleton variant="rectangular" height={"800px"}/>
    }
    return <>
        <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
            <Grid item xs={12}>
                <CriteriaSelect/>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="tabs"
                    name='tabs'
                    multiple
                    fullWidth
                    value={selectedTabs}
                    onChange={handleSelectedTabsChange}
                    disableCloseOnSelect={true}
                    options={tabs}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip variant="outlined" label={option.name} {...getTagProps({index})}/>
                        ));
                    }
                    }
                    renderOption={(props, option, state) => {
                        const isChecked = selectedTabs.some(element => element.id === option.id);
                        return <>
                            <li {...props}>
                                <Grid container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                      columns={16}
                                >
                                    <Grid item xs={2}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={state.selected || isChecked}
                                        />
                                    </Grid>
                                    <Grid item xs={14}>
                                        <Typography component='div'>
                                            {option.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                            <Divider/>
                        </>
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            fullWidth
                            id="tabs-input"
                            label="Pestañas"
                            size='medium'
                            variant="standard"
                            helperText="Selecciona qué pestañas quieres que aparezcan en el resumen."
                        />
                    )}
                />
            </Grid>
        </Grid>
        {isLoading ? <Skeleton variant="rectangular" height={"60vh"}/> :
            <Box>
                <Box>
                    <Button
                        onClick={() => exportExcel(rows.slice(1), columns, "Resumen Lomloe " + selectedCriteria)}
                        startIcon={<Download/>}>Descargar a Excel</Button>
                </Box>
                <Box sx={{height: "60vh"}}>
                    <DataGrid
                        columnHeaderHeight={0}
                        columns={columns}
                        rows={rows}
                        localeText={{
                            toolbarExportCSV: "Descargar a Excel",
                            toolbarExport: "Descargar",
                            toolbarExportLabel: "Descargar",
                        }}
                        slots={{
                            toolbar: props.exportToolbar,
                        }}
                        getCellClassName={(item) => {
                            if (item.value !== null) {
                                return 'filled';
                            }
                            return '';
                        }}
                        getRowHeight={() => 'auto'}
                        getRowClassName={(item) => {
                            if (item.indexRelativeToCurrentPage % 2 === 0) {
                                return "odd";
                            }
                            return "";
                        }}
                        disableSelectionOnClick
                        hideFooter
                        disableColumnMenu
                    />
                </Box>
            </Box>}
    </>
}