import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {StickyNote2, PowerSettingsNew, Settings, School, Today, Home, AutoFixHigh} from "@mui/icons-material";
import {Link, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {Component} from "react";
import SubscriptionMenuItem from "./SubscriptionMenuItem";

export class MainListItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedModule: "",
        }
    }

    componentDidMount() {
        const pathList = document.location.pathname.toString().split('/');
        let currentPath = "";
        if (pathList.length > 1) {
            currentPath = pathList[1];
        }
        this.setState({selectedModule: currentPath});
    }

    render() {
        return <>
            <Tooltip title="Inicio" arrow placement="right">
                <ListItemButton selected={this.state.selectedModule === ""} component={Link} to="/">
                    <ListItemIcon>
                        <Home color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Inicio"/>
                </ListItemButton>
            </Tooltip>
            <Tooltip title="Clases" arrow placement="right">
                <ListItemButton selected={this.state.selectedModule === "clases"} component={Link} to="/clases/">
                    <ListItemIcon>
                        <School color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Clases"/>
                </ListItemButton>
            </Tooltip>
            <Tooltip title="Agenda" arrow placement="right">
                <Box>
                    <ListItemButton selected={this.state.selectedModule === "agenda"} component={Link} to="/agenda/">
                        <ListItemIcon>
                            <Today color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Agenda"/>
                    </ListItemButton>
                </Box>
            </Tooltip>

            <Tooltip title="Post-its" arrow placement="right">
                <Box>
                    <ListItemButton selected={this.state.selectedModule === "notas"} component={Link} to="/post-its/">
                        <ListItemIcon>
                            <StickyNote2 color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Post-its"/>
                    </ListItemButton>
                </Box>
            </Tooltip>

            <Tooltip title="Inteligencia Artificial" arrow placement="right">
                <Box>
                    <ListItemButton selected={this.state.selectedModule === "ia"} component={Link} to="/ia/sdas">
                        <ListItemIcon>
                            <AutoFixHigh color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Inteligencia Artificial"/>
                    </ListItemButton>
                </Box>
            </Tooltip>
        </>
    }
}

export class SecondaryListItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedModule: "",
        }
    }

    componentDidMount() {
        const pathList = document.location.pathname.toString().split('/');
        let currentPath = "";
        if (pathList.length > 1) {
            currentPath = pathList[1];
        }
        this.setState({selectedModule: currentPath});
    }

    logout = function () {
        window.localStorage.clear();
        window.location.replace('/login/')
    }

    render() {
        return (
            <React.Fragment>
                <SubscriptionMenuItem/>
                <ListItemButton selected={this.state.selectedModule === "ajustes"} component={Link}
                                to="/ajustes/">
                    <ListItemIcon>
                        <Settings color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Ajustes"/>
                </ListItemButton>

                <ListItemButton onClick={() => this.logout()}>
                    <ListItemIcon>
                        <PowerSettingsNew color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión"/>
                </ListItemButton>

            </React.Fragment>);
    }
}
