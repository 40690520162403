import React, {useEffect, useState} from "react";
import StudentsService from "../../../services/StudentsService";
import ClassroomsService from "../../../services/ClassroomService";
import {Button, Checkbox, FormControl, FormControlLabel, FormLabel} from "@mui/material";
import Box from "@mui/material/Box";
import WarningMessage from "../../genericui/feedback/WarningMessage";

export default function MassAddToClassroomForm(props) {
    const [allClassrooms, setAllClassrooms] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [classroomsToAdd, setClassroomsToAdd] = useState(props.student.classrooms);
    const [classroomsToRemove, setClassroomsToRemove] = useState([]);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (isFirstLoad) {
            ClassroomsService.getCreatedClassrooms().then((classrooms) => {
                    setAllClassrooms(classrooms.data);
                    setIsFirstLoad(false);
                },
                _ => props.handleAPIError("No se ha podido cargar tus datos. Intenta de nuevo más tarde.")
            )
        }
    })

    function handleChange(event) {
        const id = parseInt(event.target.id);
        let localClassroomsToAdd = classroomsToAdd;
        let localClassroomsToRemove = classroomsToRemove;

        if (!event.target.checked && localClassroomsToAdd.length === 1) {
            const message = "¡Debes seleccionar al menos una clase!"
            doShowWarningMessage(message);
        } else {
            if (event.target.checked) {
                const index = localClassroomsToRemove.indexOf(id);
                if (index > -1) {
                    localClassroomsToRemove.splice(index, 1);
                }
                localClassroomsToAdd.push(id);
            }
            if (!event.target.checked) {
                const index = localClassroomsToAdd.indexOf(id);
                if (index > -1) {
                    localClassroomsToAdd.splice(index, 1);
                }
                localClassroomsToRemove.push(id);
            }
                setClassroomsToAdd(unique(classroomsToAdd));
                setClassroomsToRemove(unique(localClassroomsToRemove));
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        StudentsService.addStudentToClassrooms({'classroom_ids': classroomsToAdd}, props.student.id).then(response => {
                StudentsService.removeStudentFromClassrooms({'classroom_ids': classroomsToRemove}, props.student.id).then(response => {
                    },
                    _ => props.handleAPIError("No se han podido actualizar los alumnos. Revisa los datos o intenta de nuevo más tarde."));
                props.onNewStudents(classroomsToAdd);
                props.onClose();
            },
            _ => props.handleAPIError("No se han podido actualizar los alumnos. Revisa los datos o intenta de nuevo más tarde."));
    }

    function unique(array) {
        return [...new Set(array || [])]
    }

    function doShowWarningMessage(message) {
        toggleWarningMessage();
        setMessage(message);
    }

    function toggleWarningMessage() {
        setShowWarningMessage(!showWarningMessage);
    }

    function renderClassroomItems() {
        return (
            <>
                {allClassrooms.map((classroom, i) => {
                        const selected = classroomsToAdd.includes(classroom.id) && !classroomsToRemove.includes(classroom.id);
                        return (
                            <React.Fragment key={classroom.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={selected} onChange={handleChange} id={classroom.id.toString()}/>
                                    }
                                    label={classroom.name}
                                />
                            </React.Fragment>
                        );
                    }
                )}</>);
    }

    return (
        <>
            {showWarningMessage &&
                <WarningMessage message={message} disableSuccessMessage={toggleWarningMessage}/>}
            <Box sx={{display: 'flex'}}>
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                        <FormLabel component="legend">Selecciona las clases a las que quieres añadir este
                            alumno</FormLabel>
                        {renderClassroomItems()}
                        <Button key={"submit-form"} type="submit" variant="contained"
                                sx={{mt: 1.5}}>Guardar</Button>
                    </FormControl>
                </form>
            </Box>
        </>
    );
}