import {useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Divider, ListItem, Skeleton} from "@mui/material";
import SDAListItem from "./SDAListItem";
import SDADetails from "./SDADetails";
import GenericModal from "../../genericui/modals/GenericModal";
import Typography from "@mui/material/Typography";
import AIService from "../../../services/AIService";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import Box from "@mui/material/Box";

export default function SDAsList() {
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [sdas, setSdas] = useState([]);
    const [isViewSDAOpen, setIsViewSDAOpen] = useState(false);
    const [selectedSDA, setSelectedSDA] = useState({});

    useEffect(() => {
        if (isFirstLoad) {
            AIService.getSDAs().then(
                response => setSdas(response.data),
                _ => context.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.")
            ).finally(() => setIsFirstLoad(false))
        }
    }, [context, isFirstLoad]);

    function mapSDAsIntoList() {
        return (<>
            {sdas.map((sda, i) => {
                return (
                    <React.Fragment key={'maincontainersda' + i}>
                        <Divider key={"bottom-divider" + i} sx={{my: 1}}/>
                        <ListItem key={"sdalistitem" + sda.id}>
                            <SDAListItem sda={sda} onClick={() => {
                                handleClickOnView(sda);
                            }}/>
                        </ListItem>
                        {i + 1 === sda.length && <Divider key="bottom-divider" sx={{my: 1}}/>}
                    </React.Fragment>
                );
            })}
        </>);
    }

    function handleClickOnView(sda) {
        setSelectedSDA(sda);
        setIsViewSDAOpen(true);
    }

    if (isFirstLoad) {
        return (<Skeleton variant="rectangular" height={"600px"}/>);
    }

    return <>
        {isViewSDAOpen && <GenericModal title={selectedSDA.title} onClose={() => setIsViewSDAOpen(false)}>
            <SDADetails sda={selectedSDA.markdown}/>
        </GenericModal>}
        <Box sx={{p: 2}}>
            <Button fullWidth variant="contained" color="info" href="/ia/sdas" startIcon={<ArticleIcon/>}>Generar
                SDAs</Button>
        </Box>
        {sdas.length > 0 ? mapSDAsIntoList() : <Typography>📄 No tienes ninguna SDA guardada aún ¡Prueba generando una y guardándola en Mis
            SDAs!</Typography>}
    </>
}