import TutoriasEventsService from "../../services/TutoriasService";
import {parseISO} from "date-fns";

export function submitTutoriaEvent(scheduler, currentState, classrooms, students, handleAPIError) {
    scheduler.loading(true);
    let start = new Date(currentState.date);
    let end = new Date(currentState.date);
    start.setTime(currentState.start);
    end.setTime(currentState.end);
    const isEdited = scheduler.edited;
    const studentFound = students.find(({id}) => id === currentState.student_id);
    const studentName = studentFound.first_name + " " + studentFound.last_name;

    const data = {
        id: currentState.id,
        title: studentName + " - Tutoría",
        event_type: "TUTORIA",
        start: start.toISOString(),
        end: end.toISOString(),
        body: currentState.body,
        color: currentState.color,
        classroom_id: currentState.classroom_id,
        student_id: currentState.student_id,
        requested_by: currentState.requested_by,
    };
    if (isEdited) {
        TutoriasEventsService.updateTutoriaEvent(data, data.id).then(
            response => _refreshSchedulerWithNewData(response.data, scheduler, classrooms, studentName, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    } else {
        TutoriasEventsService.createNewTutoriaEvent(data).then(
            response => _refreshSchedulerWithNewData(response.data, scheduler, classrooms, studentName, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    }
}

function _refreshSchedulerWithNewData(responseData, scheduler, classrooms, studentName, isEdited) {
    const newData = responseData;
    const classroomFound = classrooms.find(({id}) => id === newData.classroom_id);

    newData.classroom_name = classroomFound.name;
    newData.student_name = studentName;
    newData.event_id = newData.id;
    newData.start = new Date(parseISO(newData.start));
    newData.end = new Date(parseISO(newData.end));

    scheduler.onConfirm(newData, isEdited ? "edit" : "create");
    scheduler.loading(false);
    scheduler.close();
}
