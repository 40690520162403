import api from "./Api";


class ClassroomsService {
    getCreatedClassrooms() {
        return api.get('/classrooms/');
    }

    createNewClassroom(data) {
        return api.post('/classrooms/', data);
    }

    updateClassroom(data, classroomId) {
        return api.patch('/classrooms/' + classroomId + '/', data);
    }

    deleteClassroom(classroomId) {
        return api.delete('/classrooms/' + classroomId + '/');
    }

    cloneClassroom(classroomId) {
        return api.post('/classrooms/' + classroomId + '/clone/')
    }

    getClassroomById(classroomId) {
        return api.get('/classrooms/' + classroomId + '/');
    }

    getAcademicInfo() {
        return api.get('/classrooms/lomloe-academic-info');
    }

    getAvailableSubjects(classroomId) {
        return api.get(`/classrooms/lomloe-academic-info/${classroomId}/`);
    }
}

export default new ClassroomsService();
