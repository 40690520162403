import api from "./Api";


class SettingsService {
    getProfileInfo() {
        return api.get('/users/settings/profile/');
    }

    updateProfile(formData) {
        return api.patch('/users/settings/profile/', formData);
    }
    getCalendarSettings() {
        return api.get('/users/settings/calendar/');
    }

    updateCalendarSettings(formData) {
        return api.patch('/users/settings/calendar/', formData);
    }
}

export default new SettingsService();
