import {useTheme} from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useCountdown} from "./genericui/info/countdown";
import SettingsService from "../services/SettingsService";
import {Link, Skeleton} from "@mui/material";
import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";

const SubscriptionMenuItem = (props) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isTrial, setIsTrial] = useState(true);
    const [endDate, setEndDate] = useState(null);
    const [days, hours, minutes, seconds] = useCountdown(endDate);

    useEffect(() => {
        SettingsService.getProfileInfo().then(response => {
            setIsSubscribed(response.data.is_subscription_active);
            setIsTrial(response.data.is_trial);
            const date = new Date(response.data.end_date);
            setEndDate(date);
            setIsLoading(false);
        })
    }, []);

    if (isLoading)
        return <Skeleton variant="rectangular" width="100%" height="100%"/>

    if (isTrial)
        return <>
            <ListItemButton component={Link} to="/suscripcion/"
                            sx={{backgroundColor: theme.palette.error.main, color: "white"}}>
                <Typography fontWeight="bold">Prueba Gratuita activa</Typography>
            </ListItemButton>
            <ListItemButton component={Link} to="/suscripcion/"
                            sx={{backgroundColor: theme.palette.error.main, color: "white"}}>
                {days > 0 && <Typography>Termina en {days}d {hours}h {minutes}m {seconds}s</Typography>}
            </ListItemButton>
        </>

    if (isSubscribed)
        return <>
            <ListItemButton component={Link} to="/ajustes/"
                            sx={{backgroundColor: theme.palette.success.light, color: "white"}}>
                <Typography fontWeight="bold">4Docentes Premium</Typography>
            </ListItemButton>
            <ListItemButton component={Link} to="/ajustes/"
                            sx={{backgroundColor: theme.palette.success.light, color: "white"}}>
                <Typography fontWeight="bold">Termina el {endDate.toLocaleDateString()}</Typography>
            </ListItemButton>
        </>

    return null;
}

export default SubscriptionMenuItem;