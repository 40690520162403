import Box from "@mui/material/Box";
import React, {useEffect} from "react";
import TokenService from "../../services/TokenService";
import {Alert} from "@mui/lab";

const CheckoutForm = () => {
    const expired = new URLSearchParams(window.location.search).get("expired");
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return <Box>
        {expired && <><Alert severity="warning" sx={{m: 3}}>Tu prueba gratuita ha acabado. ¡Suscríbete para no perder tus contenidos!</Alert></>}
        {React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1PoBe8K9j6wztABbzHnAPweH",
        "publishable-key": "pk_live_51O872RK9j6wztABbIZvA9G7440dfpcffzL4CCzr9m2nljxNsTwYLX10DChqvObaFyulbViKDQX0CuULq3uQ6SvXV00Grp7GTV7",
        "customer-email": TokenService.getUserEmail(),
    })}</Box>;
}

export default CheckoutForm;