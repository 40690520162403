import api from "./Api";


class DiaryEventsService {
    getExistingDiaryEvents(start, end) {
        const params = {"start": start, "end": end};
        return api.get('/scheduler/class-diary-events/', { params });
    }

    createNewDiaryEvent(data) {
        return api.post('/scheduler/class-diary-events/', data);
    }

    updateDiaryEvent(data, eventId) {
        return api.patch('/scheduler/class-diary-events/' + eventId + '/', data);
    }

    deleteDiaryEvent(eventId) {
        return api.delete('/scheduler/class-diary-events/' + eventId + '/');
    }
}

export default new DiaryEventsService();
