import api from "./Api";


class TutoriasEventsService {
    getExistingTutoriasEvents(start, end) {
        const params = {"start": start, "end": end};
        return api.get('/scheduler/tutoria-events/', { params });
    }

    createNewTutoriaEvent(data) {
        return api.post('/scheduler/tutoria-events/', data);
    }

    updateTutoriaEvent(data, eventId) {
        return api.patch('/scheduler/tutoria-events/' + eventId + '/', data);
    }

    deleteTutoriaEvent(eventId) {
        return api.delete('/scheduler/tutoria-events/' + eventId + '/');
    }
}

export default new TutoriasEventsService();
