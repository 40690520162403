import {Navigate} from "react-router-dom";
import {useEffect, useState} from "react";
import SubscriptionService from "../../services/SubscriptionService";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ProcessPayment = ({sessionId}) => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        SubscriptionService.getSession(sessionId).then((response) => {
                setStatus(response.data.status);
                setCustomerEmail(response.data.customer_email);
            });
    }, []);

    if (status === 'open') {
        return (
            <Navigate to="/suscripcion"/>
        )
    }

    if (status === 'complete') {
        return <Box>
            <Typography variant="h4" sx={{textAlign: 'center'}}>¡Gracias por suscribirte!</Typography>
            <Typography variant="h5" sx={{textAlign: 'center'}}>Empieza hoy mismo a disfrutar de tu servicio premium.</Typography>
            <Typography sx={{textAlign: 'center'}}>Enviaremos un correo de confirmación a: {customerEmail}</Typography>
        </Box>
    }

    return null;
}

export default ProcessPayment;