import React, {useState} from "react";
import {Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Select} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import AIService from "../../../services/AIService";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import EjercicioDetails from "./EjercicioDetails";
import {MuiFileInput} from 'mui-file-input';
import {AttachFile, Close} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import SaveIcon from "@mui/icons-material/Save";
import SubscriptionPopup from "../../subscription/popup";
import SettingsService from "../../../services/SettingsService";

const EjerciciosForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState("");
    const {handleAPIError, handleSuccessMessage} = useOutletContext();
    const [level, setLevel] = useState("");
    const [subject, setSubject] = useState("");
    const [respuestas, setRespuestas] = useState("");
    const [tipoEjercicio, setTipoEjercicio] = useState("Respuesta libre corta");
    const [numeroPreguntas, setNumeroPreguntas] = useState(5);
    const [otros, setOtros] = useState("");
    const [error, setError] = useState("");
    const [file, setFile] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);


    function sendForm() {
        setLoading(true);

        const formData = new FormData();
        formData.append('level', level);
        formData.append('subject', subject);
        formData.append('otros', otros);
        formData.append('file', file);
        formData.append('tipo', tipoEjercicio);
        formData.append('respuestas', respuestas);
        formData.append('numero', numeroPreguntas);

        generateContent(formData);
    }

    function generateContent(formData) {
        SettingsService.getProfileInfo().then(response => {
            if (!response.data.is_subscription_active) {
                setIsPopupOpen(true);
            }
        })
        if (file) {
            AIService.generateEjercicio(formData).then(response => {
                    setOutput(response.data.text);
                    setLoading(false);
                },
                _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
            ).catch(error => {
                handleAPIError(error);
                setLoading(false);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false);
            setError("Debes subir un archivo");
        }
    }

    function handleUpload(value) {
        if (value?.size > 1242880) {
            setError("El archivo es demasiado grande. Tamaño maximo: 5MB");
        } else {
            setError("");
            setFile(value);
        }
    }

    function saveCurrentEjercicio() {
        setLoading(true);
        const ejercicioToSend = {
            "level": level,
            "subject": subject,
            "otros": otros,
            "markdown": output,
            "tipo": tipoEjercicio,
            "respuestas": respuestas,
            "numero": numeroPreguntas,
        }
        AIService.saveEjercicio(ejercicioToSend).then(response => {
                handleSuccessMessage("¡Ejercicio guardado correctamente!")
            },
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        ).finally(() => {
            setLoading(false)
        });
    }

    return <>
        {isPopupOpen && <SubscriptionPopup onClose={() => setIsPopupOpen(false)}/>}
        <Grid container spacing={2} sx={{p: 2, display: 'flex'}}>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="info" href="/ia/mis-ejercicios" startIcon={<ArticleIcon/>}>Ver
                    mis Ejercicios guardados</Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Nivel" variant="outlined" placeholder="1º Primaria"
                           onChange={(e) => setLevel(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Área" variant="outlined" placeholder="Matemáticas"
                           onChange={(e) => setSubject(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="tipo">Tipo de Ejercicios</InputLabel>
                    <Select labelId="tipo" fullWidth label="Tipo de Ejercicios" variant="outlined" value={tipoEjercicio}
                            onChange={(e) => setTipoEjercicio(e.target.value)}>
                        <MenuItem value="Respuesta libre corta">Respuesta libre corta</MenuItem>
                        <MenuItem value="Respuesta libre larga">Respuesta libre larga</MenuItem>
                        <MenuItem value="Tipo Test">Tipo Test</MenuItem>
                        <MenuItem value="Verdadero / Falso">Verdadero / Falso</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiFileInput
                    placeholder="Sube un archivo con tu temario"
                    InputProps={{
                        inputProps: {accept: ".pdf, .doc, .docx"},
                        startAdornment: <AttachFile/>
                    }}
                    error={Boolean(error)}
                    helperText={error}
                    value={file}
                    onChange={handleUpload}
                    fullWidth
                    clearIconButtonProps={{
                        title: "Quitar archivo",
                        children: <Close fontSize="small"/>
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <TextField fullWidth label="Número de preguntas" type="number" variant="outlined" defaultValue="5"
                           InputProps={{inputProps: {min: 0, max: 100}}}
                           onChange={(e) => setNumeroPreguntas(e.target.value)}/>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControlLabel control={<Checkbox onChange={(e) => setRespuestas(e.target.checked)}/>}
                                  label="Incluir respuestas"/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField fullWidth label="Otras indicaciones" multiline maxRows={4} minRows={2} variant="outlined"
                           placeholder="Escribe algunas indicaciones como temática, comunidad autónoma, ideas, contexto..."
                           onChange={(e) => setOtros(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={sendForm}>¡Generar ejercicios!</LoadingButton>
            </Grid>
            {output && <Grid item xs={12}>
                <LoadingButton fullWidth loading={loading} variant="contained" color="primary"
                               onClick={saveCurrentEjercicio} startIcon={<SaveIcon/>}>Guardar en Mis
                    Ejercicios</LoadingButton>
            </Grid>}
            <Grid item xs={12}>
                {output && <EjercicioDetails ejercicio={output}/>}
            </Grid>
        </Grid>
    </>
}

export default EjerciciosForm;