import axios from "axios";
import TokenService from "./TokenService";

const instance = axios.create({
    baseURL: "/api",
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig && originalConfig.url !== "/auth/signin" && originalConfig.url !== "/auth/token/refresh/" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                originalConfig.headers = { ...originalConfig.headers };

                try {
                    const rs = await instance.post("/auth/token/refresh/", {
                        refresh: TokenService.getLocalRefreshToken(),
                    });

                    const {access} = rs.data;
                    TokenService.updateLocalAccessToken(access);

                    return instance(originalConfig);
                } catch (_error) {
                    window.location.replace('/login/')
                    return Promise.reject(_error);
                }
            }
            if (err.response.status === 402) {
                window.location.replace('/suscripcion/?expired=true')
            }
        }

        return Promise.reject(err);
    }
);

export default instance;
