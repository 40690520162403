import MainCard from "../genericui/cards/MainCard";
import {Tab, Tabs} from "@mui/material";
import React, {useEffect, useState} from "react";
import ErrorMessage from "../genericui/feedback/ErrorMessage";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {Link, Outlet} from "react-router-dom";
import SuccessMessage from "../genericui/feedback/SuccessMessage";
import AssignmentIcon from '@mui/icons-material/Assignment';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import TableChartIcon from '@mui/icons-material/TableChart';

export default function IALayout() {
    const [currentTab, setCurrentTab] = useState(0);
    const [message, setMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

    useEffect(() => {
        function setTabFromPath() {
            const path = document.location.pathname.toString();
            if (path.includes("ejercicios")) {
                setCurrentTab(3);
            } else if (path.includes("sdas")) {
                setCurrentTab(0);
            } else if (path.includes("rubricas")) {
                setCurrentTab(1);
            } else if (path.includes("comprobador")) {
                setCurrentTab(2);
            }
            else {
                setCurrentTab(0);
            }
        }

        setTabFromPath()
        window.onpopstate = () => {
            setTabFromPath()
        }

    }, [])

    function handleAPIError(message) {
        setMessage(message);
        setIsErrorMessageVisible(true);
    }

    function handleSuccessMessage(message) {
        setMessage(message);
        setIsSuccessMessageVisible(true);
    }


    return <MainCard title="🪄 Generación con Inteligencia Artificial" sx={{minHeight: "600px"}}>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={message}/>}
        {isSuccessMessageVisible && <SuccessMessage disableSuccessMessage={() => setIsSuccessMessageVisible(false)} message={message}/>}
        <Tabs value={currentTab} onChange={(e, value) => setCurrentTab(value)} variant="fullWidth">
            <Tab icon={<AutoFixHighIcon/>} wrapped label="Generar/Ver SDA" component={Link} to="/ia/sdas"/>
            <Tab icon={<TableChartIcon/>} wrapped label="Generar/Ver Rúbricas" component={Link} to="/ia/rubricas"/>
            <Tab icon={<SmartButtonIcon/>} wrapped label="Comprobar actividades con LOMLOE" component={Link} to="/ia/comprobador-lomloe"/>
            <Tab icon={<AssignmentIcon/>} wrapped label="Generar/Ver ejercicios" component={Link} to="/ia/ejercicios"/>
        </Tabs>
        <Outlet context={{handleAPIError, handleSuccessMessage}}/>
    </MainCard>
}