import Box from "@mui/material/Box";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export function ClassroomsPlannerInput(props) {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <FormControl required fullWidth>
            <InputLabel size="small" id="classroom">Elige una Clase</InputLabel>
            <Select
                labelId="classroom"
                label="Elige una Clase"
                id="classroom"
                size='small'
                value={props.state.classroom_id}
                onChange={(e) => props.handleChange(e.target.value, "classroom_id")}
            >{props.classrooms.map((classroom, i) => {
                return (<MenuItem key={i} value={classroom.id}>{classroom.name}</MenuItem>);
            })}
            </Select>
        </FormControl>
    </Box>;
}
