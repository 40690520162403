import React, {useEffect, useState} from "react";
import {Skeleton, Typography} from "@mui/material";
import Scheduler from "@aldabil/react-scheduler";
import {FIELDS, TRANSLATIONS, WEEK} from "./MeetingsCalendarConfig";
import es from "date-fns/locale/es";
import MeetingsEventCustomRenderer from "./MeetingsEventCustomRenderer";
import {parseISO} from "date-fns";
import MeetingsEventsService from "../../services/MeetingsEventsService";
import MeetingsCustomEditor from "./MeetingsCustomEditor";
import MainCard from "../genericui/cards/MainCard";
import {useOutletContext} from "react-router-dom";
import SettingsService from "../../services/SettingsService";

export default function MeetingsCalendar() {
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [settings, setSettings] = useState({});
    const [week, setWeek] = useState(WEEK);

    useEffect(() => {
        if (isFirstLoad) {
            SettingsService.getCalendarSettings().then((response) => {
                    setSettings(response.data);
                    const weekCopy = {...week};
                    weekCopy.step = response.data.meetings_step;
                    weekCopy.startHour = response.data.meetings_start_hour;
                    weekCopy.endHour = response.data.meetings_end_hour;
                    setWeek(weekCopy);
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.");
                }
            ).finally(() => setIsFirstLoad(false))
        }
    }, [isFirstLoad, context, week]);

    function getMeetingEvents(params) {
        return MeetingsEventsService.getExistingMeetingEvents(params.start.toISOString(), params.end.toISOString())
            .then((response) => {
                    const newData = response.data

                    newData.forEach((element) => {
                            element.event_id = element.id;
                            element.start = new Date(parseISO(element.start));
                            element.end = new Date(parseISO(element.end));
                        }
                    );
                    return newData;
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus eventos. Inténtalo de nuevo más tarde.");
                    setIsFirstLoad(false);
                });
    }

    function onEventDrop(droppedOn, updatedEvent, originalEvent) {
        return MeetingsEventsService.updateMeetingEvent(updatedEvent, originalEvent.event_id).then(
            () => {
                const event = updatedEvent;
                event.event_id = updatedEvent.id;
                return event;
            },
            _ => context.handleAPIError("No se ha podido actualizar tu evento. Inténtalo de nuevo más tarde."));
    }

    function customEditor(scheduler) {
        return <MeetingsCustomEditor scheduler={scheduler}/>;
    }

    function onDelete(deletedId) {
        return MeetingsEventsService.deleteMeetingEvent(deletedId).then(
            () => deletedId,
            _ => context.handleAPIError("No se ha podido borrar el evento. Inténtalo de nuevo más tarde.")
        );
    }

    function viewerExtraComponent(fields, event) {
        return (
            <>
                <Typography variant="h6">Reunión:</Typography>
                <Typography>{event.meeting_type}</Typography>
                {event.body ? _getBodyDiv(event.body) : null}
            </>
        );
    }

    function _getBodyDiv(body) {
        return (
            <>
                <Typography variant="h6">Notas de la Reunión:</Typography>
                <div dangerouslySetInnerHTML={{__html: body}}/>
            </>
        );
    }

    if (isFirstLoad) {
        return (<Skeleton variant="rectangular" height={"1000px"}/>);
    }
    return (
        <>
            <MainCard title="👩‍🏫👨‍🏫 Reuniones" sx={{overflowY: 'auto',}}>
                <Scheduler
                    week={week}
                    fields={FIELDS}
                    translations={TRANSLATIONS}
                    hourFormat={"24"}
                    locale={es}
                    customEditor={customEditor}
                    onEventDrop={onEventDrop}
                    onDelete={onDelete}
                    getRemoteEvents={getMeetingEvents}
                    eventRenderer={MeetingsEventCustomRenderer}
                    viewerExtraComponent={viewerExtraComponent}
                    view={settings.meetings_default_mode}
                />
            </MainCard>
        </>
    );
}