import React, {useEffect, useState} from 'react';

import {Autocomplete, Button} from '@mui/material';
import TextFormInput from "../../genericui/formcomponents/TextFormInput";
import ClassroomsService from "../../../services/ClassroomService"
import TextField from "@mui/material/TextField";
import {COMUNIDADES} from "../utils";

export default function UpdateClassroomForm(props) {
    const [classroomData, setClassroomData] = useState(
        {
            "name": props.classroom.name,
            "description": props.classroom.description,
            "id": props.classroom.id,
            "academic_year": props.classroom.academic_year,
            "created_by_id": props.classroom.created_by_id,
            "lomloe_level": "",
            "lomloe_region": "",
        }
    )

    const yearOptions = [{'label': '2024/25', 'value': "2024"}];
    const [academicLevels, setAcademicLevels] = useState([]); // [{name: "Primaria - 1º", id: 1}];
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            ClassroomsService.getAcademicInfo().then((response) => {
                setAcademicLevels(response.data.levels);
            },
            _ => props.handleAPIError("No se ha podido crear la clase. Revisa los datos o intenta de nuevo más tarde.")
            );
            setIsFirstLoad(false);
        }
        let currentFormData = classroomData;
        if (currentFormData["lomloe_level"]) currentFormData["lomloe_level"] = props.classroom.lomloe_level;
        if (currentFormData["lomloe_region"]) currentFormData["lomloe_region"] = props.classroom.lomloe_region
        setClassroomData(currentFormData);
    }, [isFirstLoad, classroomData, props])

    function handleFormFieldChange(event) {
        let currentFormData = classroomData;
        currentFormData[event.target.id] = event.target.value;
        setClassroomData(currentFormData);
    }

    function handleFormOptionChange(event, value) {
        let currentFormData = classroomData;
        const parentId = event.target.id.split("-")[0]
        currentFormData[parentId] = value.id;
        setClassroomData(currentFormData);
    }

    function handleAcademicYearChange(event, value) {
        let currentClassroomData = classroomData;
        currentClassroomData["academic_year"] = value.value;
        setClassroomData(currentClassroomData);
    }

    function handleSubmit(e) {
        e.preventDefault();
        ClassroomsService.updateClassroom(classroomData, classroomData.id).then(response => {
                props.onNewClassroom(response.data);
                props.onClose();
            },
            _ => props.handleAPIError("No se ha podido actualizar la clase. Revisa los datos o intenta de nuevo más tarde.")
        );
    }

    return <React.Fragment>
        <form className={"Avatar"} onSubmit={handleSubmit}>
            <TextFormInput id="name" onChange={handleFormFieldChange} label="Nombre de la Clase"
                           defaultValue={classroomData.name} required fullWidth/>
            <Autocomplete
                disablePortal
                defaultValue={yearOptions[0]}
                id="new-class-year"
                options={yearOptions}
                label="Curso de la Clase"
                getOptionLabel={(option) => option.label}
                onChange={handleAcademicYearChange}
                size="small"
                renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}}
                                                    {...params} id="academic_year" variant="standard"
                                                    required label="Curso de la Clase"/>}
            />
            <Autocomplete
                    id="lomloe_region"
                    options={COMUNIDADES}
                    getOptionLabel={(option) => option.name}
                    defaultValue={props.classroom.region}
                    onChange={handleFormOptionChange}
                    renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                        size="small" required label="Comunidad Autónoma"
                                                        variant="standard"/>}
                />
                <Autocomplete
                    id="lomloe_level"
                    options={academicLevels}
                    getOptionLabel={(option) => option.name}
                    defaultValue={props.classroom.level}
                    onChange={handleFormOptionChange}
                    renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                        size="small" required label="Nivel"
                                                        variant="standard"/>}
                />
            <TextFormInput id="description" defaultValue={classroomData.description}
                           onChange={handleFormFieldChange} label="Descripción" multiline
                           minRows={3} fullWidth/>
            <Button type="submit" variant="contained" sx={{mt: 1.5}}>Actualizar</Button>
        </form>
    </React.Fragment>
};
