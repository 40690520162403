import axios from "axios";
import TokenService from "./TokenService";

class GoogleFormsService {
    submitFormResponse(formId, formData, entryNameUser) {
        const entryNameFeedback = Object.keys(formData)[0];
        const formValue = formData[entryNameFeedback];
        const user = TokenService.getUserEmail();
        const url = `https://docs.google.com/forms/u/0/d/e/${formId}/formResponse?${entryNameFeedback}=${formValue}&${entryNameUser}=${user}`;

        return axios({
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }

    submitSubjectForm(formId, formData, entryNameUser) {
        const user = TokenService.getUserEmail();
        const url = `https://docs.google.com/forms/u/0/d/e/${formId}/formResponse?entry.1450519640=${formData.subject}&entry.863752102=${formData.region}&entry.1426956477=${formData.level}&${entryNameUser}=${user}`;

        return axios({
            "url": url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }
}

export default new GoogleFormsService();