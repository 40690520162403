import StudentsService from "../../../services/StudentsService";
import React, {useEffect, useState} from "react";
import {useOutletContext} from 'react-router-dom';
import {DataGrid, GridColumnMenu} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Skeleton, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import MaterialService from "../../../services/MaterialService";
import DeleteDataGridColumnMenuItem from "../../genericui/datagrid/DeleteDataGridColumnMenuItem";
import {useParams} from "react-router-dom";
import {trackingBoxStyles} from "../utils";

const DELETE_COLUMN_MESSAGE = "¿De verdad quieres eliminar esta columna? Esta acción no se podrá deshacer.";

export default function MaterialsLayout() {
    const context = useOutletContext();
    const {classroomId} = useParams();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [newColumnName, setNewColumnName] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            Promise.all([
                StudentsService.getStudentsByClassroom(classroomId),
                MaterialService.getMaterial(classroomId),
            ]).then(([studentsResponse, materialResponse]) => {
                    processReceivedColumns(materialResponse.data.columns);
                    processReceivedRows(materialResponse.data.columns, materialResponse.data.delivered_material, studentsResponse.data);
                    setIsLoading(false);
                },
                _ => context.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde."))
        }
    }, [isFirstLoad, classroomId, processReceivedRows, context]);

    function processReceivedColumns(material) {
        const materialList = [
            {
                field: 'Numero',
                headerName: 'Número',
                width: 80,
                type: 'number',
            },
            {
                field: 'Nombre',
                headerName: 'Nombre',
                width: 250,
                type: 'string',
            },
        ];
        if (material.length > 0) {
            material.forEach((element) => {
                materialList.push({
                    field: element,
                    headerName: element,
                    width: 150,
                    type: 'boolean',
                });
            });
        }
        setColumns(materialList);
    }

    function processReceivedRows(receivedColumns, receivedMaterial, receivedStudents) {
        const receivedRows = [];
        if (receivedStudents.length > 0) {
            receivedStudents.forEach((element, i) => {
                const received = {
                    id: element.id,
                    Nombre: element.first_name + " " + element.last_name,
                    Numero: i + 1,
                };
                receivedColumns.forEach(element => received[element] = false);
                receivedRows.push(received);
            });

            let studentId = 0;
            receivedMaterial.forEach((element) => {
                studentId = element.student_id;
                element.delivered_material_list.forEach((materialListElement) => {
                    receivedRows.find(row => row.id === studentId)[materialListElement] = true;
                })
            });
        }

        setRows(receivedRows);
    }

    function handleClick(clickedField) {
        if (clickedField.value === false) {
            MaterialService.markDelivered(classroomId, clickedField.field, clickedField.id).then(_ => _,
                _ => context.handleAPIError("Ha ocurrido un error enviando los datos. Recarga la página o intenta de nuevo más tarde.")
            );
            rows.find(row => row.id === clickedField.id)[clickedField.field] = !clickedField.value;
        }
        if (clickedField.value === true) {
            MaterialService.unmarkDelivered(classroomId, clickedField.field, clickedField.id).then(_ => _,
                _ => context.handleAPIError("Ha ocurrido un error enviando los datos. Recarga la página o intenta de nuevo más tarde.")
            );
            rows.find(row => row.id === clickedField.id)[clickedField.field] = !clickedField.value;
        }
    }

    function addColumn() {
        if (newColumnName) {
            MaterialService.addColumn(classroomId, newColumnName).then((response) => {
                    const localColumns = [...columns];
                    localColumns.push({
                        field: newColumnName,
                        headerName: newColumnName,
                        width: 150,
                        type: 'boolean',
                    });
                    setColumns(localColumns);
                    updateRowsWithNewColumn(localColumns[localColumns.length - 1].field);
                },
                _ => context.handleAPIError("Ha ocurrido un error añadiendo esta columna. Recarga la página o intenta de nuevo más tarde.")
            );
        } else {
            context.handleAPIError("Escribe el nombre para el material");
        }
    }

    function updateRowsWithNewColumn() {
        const localRows = [...rows];
        localRows.forEach((element) => {
            element[newColumnName] = false;
        })
        setRows(localRows);
    }

    function CustomColumnMenu(classroomId) {
        return (
            <GridColumnMenu
                {...classroomId}
                slots={{
                    columnMenuSortItem: null,
                    columnMenuColumnsItem: null,
                    columnMenuFilterItem: DeleteMaterialColumnItem,
                }}
            />
        );
    }

    function DeleteMaterialColumnItem(args) {
        return <DeleteDataGridColumnMenuItem callback={deleteColumn} message={DELETE_COLUMN_MESSAGE} {...args}/>
    }

    function deleteColumn(columnDefinition) {
        MaterialService.removeColumn(classroomId, columnDefinition.field).then(
            _ => {
                removeColumn(columnDefinition.field)
            },
            _ => context.handleAPIError("Ha ocurrido un error. Recarga la página o inténtalo de nuevo más tarde.")
        )
    }

    function removeColumn(columnName) {
        const localColumns = [...columns];
        const index = localColumns.map(function (x) {
            return x.field;
        }).indexOf(columnName);
        localColumns.splice(index, 1);
        setColumns(localColumns);
    }

    if (isLoading) {
        return (<Skeleton variant="rectangular" height={"1000px"}/>);
    }

    return (
        <Box sx={trackingBoxStyles}>
            <Stack direction="row" spacing={1} sx={{mb: 1, mt: 1}}>
                <TextField label={"Nueva entrega..."} value={newColumnName}
                           onChange={(event) => setNewColumnName(event.target.value)} size="small"/>
                <Button variant="contained" size="small" onClick={addColumn}>
                    Añadir
                </Button>
            </Stack>
            <Box sx={{height: 700}}>
                <DataGrid
                    onCellClick={handleClick}
                    columns={columns}
                    rows={rows}
                    slots={{
                        columnMenu: CustomColumnMenu,
                    }}
                    getCellClassName={(params) => {
                        switch (params.value) {
                            case true:
                                return 'green';
                            case false:
                                return 'red';
                            default:
                                return 'name';
                        }
                    }}
                    getRowClassName={(item) => {
                        if (item.indexRelativeToCurrentPage % 2 === 0) {
                            return "odd";
                        }
                        return "";
                    }}
                    disableSelectionOnClick
                    hideFooter
                />
            </Box>
        </Box>
    );
}