import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import {createBrowserHistory} from 'history';
import {HistoryRouter as Router} from "redux-first-history/rr6"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthService from "./services/AuthService";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

const history = createBrowserHistory();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

Bugsnag.start({
    apiKey: '4d7d3652afff3836b34fca96787215df',
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
        const user = AuthService.getCurrentUser();
        event.addMetadata('release', {"version": "0.1.5"})
        event.setUser(user.pk, user.email, user.username)
    }
})

BugsnagPerformance.start({apiKey: '4d7d3652afff3836b34fca96787215df'})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

root.render(
    <Router history={history}>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </Router>
);
serviceWorkerRegistration.register();
