import es from "date-fns/locale/es";
import {React, useEffect, useState} from "react";
import Scheduler from "@aldabil/react-scheduler";
import PlannerEventsService from "../../services/PlannerService";
import {parseISO} from "date-fns";
import ClassroomsService from "../../services/ClassroomService";
import {WEEK, TRANSLATIONS, FIELDS} from "./PlannerConfig";
import PlannerCustomEditor from "./PlannerCustomEditor";
import PlannerEventCustomRenderer from "./PlannerEventCustomRenderer";
import {Skeleton, Typography} from "@mui/material";
import MainCard from "../genericui/cards/MainCard";
import SettingsService from "../../services/SettingsService";
import {useOutletContext} from "react-router-dom";

export default function ClassScheduler() {
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [settings, setSettings] = useState({});
    const [week, setWeek] = useState(WEEK);
    const [classrooms, setClassrooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            Promise.all([
                ClassroomsService.getCreatedClassrooms(),
                SettingsService.getCalendarSettings(),
            ]).then(([classroomsResponse, settingsResponse]) => {
                    setClassrooms(classroomsResponse.data);
                    setSettings(settingsResponse.data);
                    const weekCopy = {...week};
                    weekCopy.step = settingsResponse.data.planner_step;
                    weekCopy.startHour = settingsResponse.data.planner_start_hour;
                    weekCopy.endHour = settingsResponse.data.planner_end_hour;
                    setWeek(weekCopy);
                    setIsLoading(false);
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.");
                })
        }
    }, [isFirstLoad, context, week]);

    function getPlannerEvents(params) {
        return PlannerEventsService.getExistingPlannerEvent(params.start.toISOString(), params.end.toISOString())
            .then((response) => {
                    const newData = response.data;
                    const dataToAdd = [];

                    newData.forEach((element) => {
                            const classroomFound = classrooms.find(({id}) => id === element.classroom_id);
                            if (classroomFound) {
                                element.event_id = element.id;
                                element.classroom_name = classroomFound.name;
                                element.start = new Date(parseISO(element.start));
                                element.end = new Date(parseISO(element.end));
                                dataToAdd.push(element);
                            }
                        }
                    );
                    return dataToAdd;
                },
                _ => {
                    context.handleAPIError("No se han podido cargar tus eventos. Inténtalo de nuevo más tarde.");
                });
    }

    function onEventDrop(droppedOn, updatedEvent, originalEvent) {
        return PlannerEventsService.updatePlannerEvent(updatedEvent, originalEvent.event_id).then(
            () => {
                const event = updatedEvent;
                event.event_id = updatedEvent.id;
                return event;
            }, _ => context.handleAPIError("No se han podido cargar tus eventos. Inténtalo de nuevo más tarde."))
    }

    function customEditor(scheduler) {
        return <PlannerCustomEditor scheduler={scheduler} classrooms={classrooms}/>;
    }

    function onDelete(deletedId) {
        return PlannerEventsService.deletePlannerEvent(deletedId).then(
            () => deletedId,
            _ => context.handleAPIError("No se ha podido borrar el evento. Inténtalo de nuevo más tarde."));
    }

    function viewerExtraComponent(fields, event) {
        return (
            <>
                <Typography variant="h6">Clase:</Typography>
                <Typography>{event.classroom_name}</Typography>
                {event.body ? _getBodyDiv(event.body) : null}
            </>
        );
    }

    function _getBodyDiv(body) {
        return (
            <>
                <Typography variant="h6">Contenido:</Typography>
                <div dangerouslySetInnerHTML={{__html: body}}/>
            </>
        );
    }

    if (isLoading) {
        return (<Skeleton variant="rectangular" height={"1000px"}/>);
    }
    return <MainCard title="🕐 Planner" sx={{overflowY: 'auto',}}>
        <Scheduler
            week={week}
            fields={FIELDS}
            translations={TRANSLATIONS}
            hourFormat={"24"}
            locale={es}
            customEditor={customEditor}
            onEventDrop={onEventDrop}
            onDelete={onDelete}
            getRemoteEvents={getPlannerEvents}
            eventRenderer={PlannerEventCustomRenderer}
            viewerExtraComponent={viewerExtraComponent}
            view={settings.planner_default_mode}
        />
    </MainCard>
}