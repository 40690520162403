import {
    Divider,
    Grid,
    List, ListItem, Skeleton,
    Tooltip,
} from '@mui/material';
import MainCard from "../../genericui/cards/MainCard";
import React, {Component} from "react";
import {Add} from "@mui/icons-material";
import Button from "@mui/material/Button";
import ClassroomsService from "../../../services/ClassroomService"
import ClassroomListItem from "./ClassroomListItem";
import SuccessMessage from "../../genericui/feedback/SuccessMessage";
import GenericModal from "../../genericui/modals/GenericModal";
import UpdateClassroomForm from "./UpdateClassroomForm";
import NewClassroomForm from "./NewClassroomForm";
import CRUDMenu from "../../genericui/menus/CRUDMenu";
import ErrorMessage from "../../genericui/feedback/ErrorMessage";
import {LATEST_CLASSROOM_ID_FROM_2023} from "../utils";


class NewClassroomButton extends Component {
    constructor(props) {
        super(props);

        this.handleOpenNewClassroomModal = this.handleOpenNewClassroomModal.bind(this);
        this.handleCloseNewClassroomModal = this.handleCloseNewClassroomModal.bind(this);

        this.state = {
            isModalActive: false,
        }
    }

    handleOpenNewClassroomModal() {
        this.setState({
            isModalActive: true,
        });
    }

    handleCloseNewClassroomModal() {
        this.setState({
            isModalActive: false,
        });
    }

    modal() {
        const title = "Añadir Clase";
        return (
            <>
                <GenericModal title={title} onClose={this.handleCloseNewClassroomModal}>
                    <NewClassroomForm {...this.props} onClose={this.handleCloseNewClassroomModal}
                                      onNewClassroom={this.props.handleNewClassroom}
                                      handleAPIError={this.props.handleAPIError}
                    />
                </GenericModal>
            </>);
    }

    render() {
        return (<>
                {this.state.isModalActive && this.modal()}
                <Tooltip title="Añadir Clase">
                    <Button variant="contained" startIcon={<Add/>} onClick={this.handleOpenNewClassroomModal}>
                        Añadir Clase
                    </Button>
                </Tooltip>
            </>
        );
    }
}

export default class Classrooms extends Component {
    constructor(props) {
        super(props);

        this._handleAPIError = this._handleAPIError.bind(this);
        this.handleClickOnClone = this.handleClickOnClone.bind(this);
        this.handleClickOnDelete = this.handleClickOnDelete.bind(this);
        this.handleNewClassroom = this.handleNewClassroom.bind(this);
        this.updateClassroom = this.updateClassroom.bind(this);
        this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
        this.handleClickOnUpdate = this.handleClickOnUpdate.bind(this);
        this.handleBack = this.handleBack.bind(this);

        this.state = {
            classroomsData: [],
            isFirstLoad: true,
            isLoading: true,
            selectedClassroom: null,
        };
    }

    componentDidMount() {
        if (this.state.isFirstLoad) {
            ClassroomsService.getCreatedClassrooms().then(
                classrooms => {
                    this.setState({
                        classroomsData: classrooms.data,
                        isLoading: false,
                        isFirstLoad: false,
                        isMessageVisible: false,
                        message: "",
                        isEditingClassroom: false,
                        editClassroom: {},
                    });
                },
                _ => this._handleAPIError("No se han podido cargar tus clases. Intenta de nuevo más tarde."));
        }
    }

    _handleAPIError(message) {
        this.setState({
            isLoading: false,
            errorMessage: message,
            isErrorMessageVisible: true,
        });
    }

    handleNewClassroom(classroomData) {
        this.setState({isMessageVisible: false});
        let currentClassrooms = this.state.classroomsData;
        currentClassrooms.push(classroomData);
        this.setState({
            classroomData: currentClassrooms,
        });
        this.triggerSuccessMessage("La Clase ha sido creada con éxito");
    }

    handleClickOnClone(classroomId) {
        this.setState({isLoading: true, isMessageVisible: false});
        ClassroomsService.cloneClassroom(classroomId).then((response) => {
                let currentClassrooms = this.state.classroomsData;
                currentClassrooms.push(response.data);
                this.setState({
                    classroomsData: currentClassrooms,
                    isLoading: false,
                });
                this.triggerSuccessMessage("La Clase ha sido duplicada con éxito");
            },
            _ => this._handleAPIError("No se ha podido duplicar la clase. Intenta de nuevo más tarde."));
    };

    handleClickOutsideModal() {
        this.setState({isEditingClassroom: false});
    }

    handleClickOnUpdate(classroom) {
        this.setState({isEditingClassroom: true, editClassroom: classroom});
    }

    triggerSuccessMessage(message) {
        this.setState({isMessageVisible: true, message: message});
    }

    handleClickOnDelete(classroomId) {
        this.setState({isLoading: true, isMessageVisible: false});
        ClassroomsService.deleteClassroom(classroomId).then((response) => {
                let currentClassrooms = this.state.classroomsData;
                currentClassrooms = currentClassrooms.filter(item => item.id !== classroomId);
                this.setState({
                    classroomsData: currentClassrooms,
                    isLoading: false,
                });
                this.triggerSuccessMessage("La Clase ha sido eliminada con éxito");
            },
            _ => this._handleAPIError("No se ha podido eliminar la clase. Intenta de nuevo más tarde."));
    };

    mapClassroomsIntoList() {
        return (<>
            {this.state.classroomsData.map((classroom, i) => {
                return (
                    <React.Fragment key={'maincontainer' + i}>
                        <ListItem disableGutters sx={{
                            "& .MuiListItemButton-root": {
                                paddingRight: "10px!important",
                                paddingLeft: "20px!important",
                            },
                        }} secondaryAction={
                            <CRUDMenu
                                key={i}
                                classroom={classroom}
                                handleClickOnClone={classroom.id > LATEST_CLASSROOM_ID_FROM_2023 ? () => {
                                    this.handleClickOnClone(classroom.id);
                                } : null}
                                handleClickOnDelete={() => {
                                    this.handleClickOnDelete(classroom.id);
                                }}
                                handleClickOnEdit={classroom.id > LATEST_CLASSROOM_ID_FROM_2023 ? () => {
                                    this.handleClickOnUpdate(classroom);
                                } : null}
                            />
                        }>
                            <ClassroomListItem
                                key={classroom.id + classroom.name + classroom.description + classroom.academic_year}
                                {...classroom}
                            />
                        </ListItem>
                        {i + 1 !== this.state.classroomsData.length && <Divider key={i} sx={{my: 1}}/>}
                    </React.Fragment>
                );
            })}
        </>);
    }

    renderEditClassroomModal() {
        const title = "Editar Clase";
        return (
            <>
                <GenericModal title={title} onClose={this.handleClickOutsideModal}>
                    <UpdateClassroomForm {...this.props} onNewClassroom={this.updateClassroom}
                                         classroom={this.state.editClassroom} onClose={this.handleClickOutsideModal}
                                         handleAPIError={this._handleAPIError}
                    />
                </GenericModal>
            </>
        );
    }

    updateClassroom(classroom) {
        this.setState({isLoading: true, isMessageVisible: false});
        let currentClassrooms = [...this.state.classroomsData];
        const index = currentClassrooms.map(function (x) {
            return x.id;
        }).indexOf(classroom.id);
        currentClassrooms[index] = classroom;
        this.setState({
            isLoading: false,
            classroomsData: currentClassrooms,
        });
        this.triggerSuccessMessage("La Clase ha sido actualizada con éxito");
    }

    displayEmptyClassroomsMessage() {
        return (
            <>
                <ListItem>🎓 No tienes ninguna Clase todavía. ¡Prueba añadiendo una! 🎓</ListItem>
            </>
        );
    }

    handleBack() {
        this.setState(
            {selectedClassroom: null}
        );
    }


    render() {
        if (this.state.isLoading) {
            return (<Skeleton variant="rectangular" height={"800px"}/>);
        }
        return (
            <>
                {this.state.isMessageVisible && <SuccessMessage message={this.state.message}/>}
                {this.state.isErrorMessageVisible &&
                    <ErrorMessage disableErrorMessage={() => this.setState({isErrorMessageVisible: false})}
                                  message={this.state.errorMessage}/>}
                <MainCard title="🎓 Clases" sx={{overflowY: 'auto',}}
                          secondary={<NewClassroomButton handleNewClassroom={this.handleNewClassroom} handleAPIError={this._handleAPIError}/>}>
                    <Grid container sx={{p: 0}}>
                        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                            {this.state.classroomsData.length > 0 ? this.mapClassroomsIntoList() : this.displayEmptyClassroomsMessage()}
                            {this.state.isEditingClassroom && this.renderEditClassroomModal()}
                        </List>
                    </Grid>
                </MainCard>
            </>
        );
    }
}