import {LoadingButton} from "@mui/lab";
import {Box} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import React, {useState} from "react";
import jsPDF from "jspdf";
import DownloadIcon from '@mui/icons-material/Download';

const RubricDetails = ({rubric}) => {
    const [isLoading, setIsLoading] = useState(false);

    const downloadPdfDocument = () => {
        const input = document.querySelector('.markdown-body');

        const doc = new jsPDF();
        setIsLoading(true);
        doc.html(input, {
            callback: function (doc) {
                // Save the PDF
                doc.save('Rubrica 4Docentes.pdf');
            },
            x: 15,
            y: 15,
            width: 170, //target width in the PDF document
            windowWidth: 700 //window width in CSS pixels
        });
        setIsLoading(false);
    }

    return (
        <>
            <LoadingButton variant="outlined" color="primary" onClick={downloadPdfDocument}
                           loading={isLoading} startIcon={<DownloadIcon/>}>Descargar</LoadingButton>
            <Box className='markdown-body'
                 sx={{
                     m: 1,
                     p: 2,
                     border: '1px solid #ccc'
                 }}>
                <Markdown
                    remarkPlugins={[remarkGfm]}>
                    {rubric}
                </Markdown>
            </Box>
        </>
    );
};

export default RubricDetails;