import ReactQuill from "react-quill";

function applyGoogleKeyboardWorkaround(editor) {
    try {
        if (editor.applyGoogleKeyboardWorkaround) {
            return
        }
        editor.applyGoogleKeyboardWorkaround = true;
        editor.quill.on('editor-change', function (eventName, ...args) {
            if (eventName === 'text-change') {
              let ops = args[0]['ops'];
              let oldSelection = editor.getSelection();
              let oldPos = oldSelection.index;
              let oldSelectionLength = oldSelection.length;

              if (ops[0]["retain"] === undefined || !ops[1] || !ops[1]["insert"] || !ops[1]["insert"] || ops[1]["insert"] !== "\n"  || oldSelectionLength > 0) {
                return
              }

              setTimeout(function () {
                let newPos = editor.getSelection().index;
                if (newPos === oldPos) {
                  editor.setSelection(editor.getSelection().index + 1, 0);
                }
              }, 30);
            }
          });
    } catch {
    }
}

export default function TextEditor(props) {
    applyGoogleKeyboardWorkaround(ReactQuill);
    return(<ReactQuill {...props} />);
}