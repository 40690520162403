import React, {Component} from "react";
import {Navigate, Outlet, Route, Routes, useParams} from "react-router-dom";

import Login from "./components/Login";
import NavBar from "./components/NavBar";
import PasswordUpdate from "./components/PasswordUpdate";
import AuthService from "./services/AuthService";
import Classrooms from "./components/classrooms/components/Classrooms";
import Notes from "./components/notes/Notes";
import Agenda from "./components/Agenda";
import ClassroomPanelLayout from "./components/classrooms/components/ClassroomPanelLayout";
import HomePage from "./components/home/HomePage";
import SignUp from "./components/SignUp";
import SettingsLayout from "./components/settings/SettingsLayout";
import PasswordReset from "./components/PasswordReset";
import PasswordResetChange from "./components/PasswordResetChange";
import MaterialsLayout from "./components/classrooms/materials/MaterialsLayout";
import AttendanceLayout from "./components/classrooms/attendance/AttendanceLayout";
import SubjectList from "./components/classrooms/subjects/SubjectsList";
import StudentsLayout from "./components/students/components/StudentsLayout";
import EvaluationLayout from "./components/classrooms/evaluation/EvaluationLayout";
import ClassScheduler from "./components/class_schedule/ClassSchedule";
import ClassDiary from "./components/class_diary/ClassDiary";
import MeetingsCalendar from "./components/meetings/MeetingsCalendar";
import Tutorias from "./components/tutorias/Tutorias";
import IALayout from "./components/ia/IALayout";
import SDAForm from "./components/ia/sdas/SDAForm";
import SDAsList from "./components/ia/sdas/SDAsList";
import EjerciciosForm from "./components/ia/ejercicios/EjerciciosForm";
import EjerciciosList from "./components/ia/ejercicios/EjerciciosList";
import Subscription from "./components/subscription";
import CheckerForm from "./components/ia/checker";
import RubricForm from "./components/ia/rubrics/RubricForm";


function PrivateRoutes(props) {
    const params = useParams();
    if (props.isAuthenticated) {
        return <NavBar><Outlet context={params}/></NavBar>
    }

    return <Navigate to='/login'/>
}

function PublicRoutes(props) {
    const params = useParams();
    if (props.isAuthenticated) {
        return <Navigate to='/'/>
    }
    return <Outlet context={params}/>

}

const reload = () => window.location.reload();

export default class Urls extends Component {
    constructor(props) {
        super(props);

        const currentUser = AuthService.getCurrentUser();

        if (currentUser !== null) {
            this.state = {
                isAuthenticated: !!currentUser,
            };
        } else {
            this.state = {
                isAuthenticated: false,
            };
        }
    }

    render() {
        return (
            <div>
                <Routes>
                    <Route element={<PrivateRoutes isAuthenticated={this.state.isAuthenticated}/>}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/actualizar-clave/" element={<PasswordUpdate/>}/>
                        <Route path="/clases/">
                            <Route path="" element={<Classrooms/>}/>
                            <Route element={<ClassroomPanelLayout/>}>
                                <Route path=":classroomId/asignaturas/:subjectId/" element={<EvaluationLayout/>}/>
                                <Route path=":classroomId/asignaturas" element={<SubjectList/>}/>
                                <Route path=":classroomId/asistencia" element={<AttendanceLayout/>}/>
                                <Route path=":classroomId/entregas" element={<MaterialsLayout/>}/>
                                <Route path=":classroomId/" element={<StudentsLayout/>}/>
                            </Route>
                        </Route>
                        <Route path="/agenda/">
                            <Route element={<Agenda/>}>
                                <Route path="" element={<ClassScheduler/>}/>
                                <Route path="diario" element={<ClassDiary/>}/>
                                <Route path="reuniones" element={<MeetingsCalendar/>}/>
                                <Route path="tutorias" element={<Tutorias/>}/>
                            </Route>
                        </Route>
                        <Route path="/post-its/" element={<Notes/>}/>
                        <Route path="/ia/" element={<IALayout/>}>
                            <Route path="sdas" element={<SDAForm/>}/>
                            <Route path="mis-sdas" element={<SDAsList/>}/>
                            <Route path="ejercicios" element={<EjerciciosForm/>}/>
                            <Route path="mis-ejercicios" element={<EjerciciosList/>}/>
                            <Route path="comprobador-lomloe" element={<CheckerForm/>}/>
                            <Route path="rubricas" element={<RubricForm/>}/>
                        </Route>
                        <Route path="/ajustes/" element={<SettingsLayout/>}/>
                        <Route path="/suscripcion" element={<Subscription/>}/>
                    </Route>
                    <Route element={<PublicRoutes isAuthenticated={this.state.isAuthenticated}/>}>
                        <Route path="/login/" element={<Login/>}/>
                        <Route path="/registro/" element={<SignUp/>}/>
                        <Route path="/recuperar-clave/" element={<PasswordReset/>}/>
                        <Route path="/cambiar-clave/" element={<PasswordResetChange/>}/>
                    </Route>
                    <Route path='/admin/*' onEnter={reload}/>
                    <Route path='/.well-known/assetlinks.json' onEnter={reload}/>
                </Routes>
            </div>
        )
    }
}