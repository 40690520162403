import {useEffect, useState} from "react";
import ReactStickyNotes from "@franciscoquero/react-sticky-notes/src";
import Box from "@mui/material/Box";
import NotesService from "../../services/NotesService";
import LoadingSpinner from "../genericui/feedback/LoadingSpinner";
import ErrorMessage from "../genericui/feedback/ErrorMessage";
import * as React from "react";

export default function Notes(props) {
    const [notes, setNotes] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    useEffect(() => {
        if (isFirstLoad) {
            NotesService.getExistingNotes().then((response) => {
                    setNotes(response.data);
                    setIsFirstLoad(false);
                },
                _ => {
                    _handleAPIError("No se ha reconocido la acción que intentas.");
                    setIsFirstLoad(false);
                })
        }
    });

    function handleNotesChange(type, payload, notes) {
        switch (type) {
            case 'add':
                NotesService.createNewNote(payload).then(r => setNotes(notes));
                break;
            case 'update':
                NotesService.updateNote(payload, payload.data.id).then(r => setNotes(notes));
                break;
            case 'delete':
                NotesService.deleteNote(payload.data.id).then(r => setNotes(notes));
                break;
            default:
                _handleAPIError("No se ha reconocido la acción que intentas.");
        }
    }

    function _handleAPIError(message) {

        setIsFirstLoad(false);
        setErrorMessage(message);
        setIsErrorMessageVisible(true);
    }

    if (isFirstLoad) {
        return <LoadingSpinner/>
    }
    return <Box sx={{width: "100%", height: "100%", color: "#000000BF"}}>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={errorMessage}/>}
        <ReactStickyNotes
            sessionKey="4docentes-notes"
            noteWidth="200px"
            noteHeight="200px"
            onChange={handleNotesChange}
            notes={notes}
        />
    </Box>;
}