import Box from "@mui/material/Box";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export function ClassroomsTutoriaInput(props) {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <FormControl required fullWidth>
            <InputLabel size="small" id="classroom">Elige una Clase</InputLabel>
            <Select
                labelId="classroom"
                label="Elige una Clase"
                id="classroom"
                size='small'
                value={props.state.classroom_id}
                onChange={(e) => props.handleChange(e.target.value, "classroom_id")}
            >{props.classrooms.map((classroom, i) => {
                return (<MenuItem key={i} value={classroom.id}>{classroom.name}</MenuItem>);
            })}
            </Select>
        </FormControl>
    </Box>;
}

export function StudentsTutoriaInput(props) {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <FormControl required fullWidth disabled={!props.state.classroom_id}>
            <InputLabel size="small"
                        id="student">{!props.state.classroom_id ? "Elige una clase primero" : "Elige un alunno"}</InputLabel>
            <Select
                labelId="student"
                label={!props.state.classroom_id ? "Elige una clase primero" : "Elige un alumno"}
                id="student"
                size='small'
                value={props.state.student_id}
                onChange={(e) => props.handleChange(e.target.value, "student_id")}
            >{props.students.map((student, i) => {
                if (student.classrooms.includes(props.state.classroom_id)) {
                    return (
                        <MenuItem key={i} value={student.id}>{student.first_name + " " + student.last_name}</MenuItem>);
                }
                return null;
            })}
            </Select>
        </FormControl>
    </Box>;
}

export function RequestedByTutoriaInput(props) {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <FormControl required fullWidth>
            <InputLabel size="small" id="student">Solicitado por</InputLabel>
            <Select
                labelId="requested_by"
                label="Solicitado por"
                id="requested_by"
                size='small'
                value={props.state.requested_by}
                onChange={(e) => props.handleChange(e.target.value, "requested_by")}
            >
                <MenuItem key={0} value="Madre">Madre</MenuItem>
                <MenuItem key={1} value="Padre">Padre</MenuItem>
                <MenuItem key={2} value="Tutor/a">Tutor/a</MenuItem>
                <MenuItem key={3} value="Otro">Otro</MenuItem>
            </Select>
        </FormControl>
    </Box>;
}