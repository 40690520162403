import {DatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import React from "react";

export default function CalendarDateInput(props) {
    return <DatePicker
        id="start-date"
        inputFormat='dd MMMM yyyy'
        label="Fecha"
        openTo="day"
        views={['day']}
        renderInput={(params) => <TextField required size='small' {...params} />}
        value={props.state.date}
        onChange={(e) => props.handleChange(e, "date")}
        required
    />;
}
