import {
    DialogActions,
    Grid,
} from "@mui/material";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import GenericModal from "../../../genericui/modals/GenericModal";
import TextFormInput from "../../../genericui/formcomponents/TextFormInput";
import ColorsSelectInput from "../../../genericui/calendar/ColorsSelectInput";

export default function EditTabModal(props) {
    const [currentFormData, setCurrentFormData] = useState({
        id: props.tab.id,
        name: props.tab.name,
        color: props.tab.color,
    });

    function submitChanges(event) {
        event.preventDefault();
        props.onSubmit(currentFormData);
        props.onClose();
    }

    function handleFormFieldChange(event) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData[event.target.id] = event.target.value;
        setCurrentFormData(localCurrentFormData);
    }

    function handleColorFieldChange(newValue) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData.color = newValue;
        setCurrentFormData(localCurrentFormData);
    }

    return <GenericModal>
        <form onSubmit={submitChanges}>
            <Grid container maxWidth="sm" spacing={1.5}>
                <Grid item xs={12} sm={12}>
                    <TextFormInput
                        onChange={handleFormFieldChange}
                        id="name"
                        label="Nombre de la pestaña"
                        defaultValue={currentFormData.name}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ColorsSelectInput color={currentFormData.color} handleChange={handleColorFieldChange}/>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <DialogActions>
                    <Button onClick={props.onClose}>Cancelar</Button>
                    <Button type="submit" variant='contained'>Guardar</Button>
                </DialogActions>
            </Grid>
        </form>
    </GenericModal>
}