import StudentsService from "../../../services/StudentsService";
import React, {useEffect, useState} from "react";
import {useOutletContext} from 'react-router-dom';
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {Skeleton} from "@mui/material";
import AttendanceService from "../../../services/AttendanceService";
import {useParams} from "react-router-dom";
import {trackingBoxStyles} from "../utils";

const MIN_DAY = new Date("2023-09-10");
const MAX_DAY = new Date();

export default function AttendanceLayout() {
    const context = useOutletContext();
    const {classroomId} = useParams();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        function generateDayColumns() {
            const currentColumns = [
                {
                    field: 'Numero',
                    headerName: 'Nº',
                    width: 40,
                    type: 'number',
                },
                {
                    field: 'Nombre',
                    headerName: 'Nombre',
                    width: 250,
                    type: 'string',
                },
            ];
            const currentDate = new Date(MAX_DAY.getTime());
            while (currentDate >= MIN_DAY) {
                const dayOfWeek = currentDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                    currentColumns.push({
                        field: currentDate.toISOString().split('T')[0],
                        headerName: currentDate.toISOString().split('T')[0],
                        width: 100,
                        type: 'boolean',
                        valueGetter: valueGetter,
                    });
                }
                currentDate.setDate(currentDate.getDate() - 1);
            }
            setColumns(currentColumns);
        }

        if (isFirstLoad) {
            setIsFirstLoad(false);
            Promise.all([StudentsService.getStudentsByClassroom(classroomId), AttendanceService.getMissingStudents(classroomId)]).then(([studentsReceived, attendanceReceived]) => {
                    generateDayColumns();
                    processReceivedRows(attendanceReceived.data.missing_students, studentsReceived.data);
                    setIsLoading(false);
                },
                _ => context.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde."))
        }
    }, [classroomId, isFirstLoad, context]);

    function valueGetter(col) {
        return col.value !== false;
    }

    function processReceivedRows(dailyAttendance, studentsReceived) {
        const receivedRows = [];
        if (studentsReceived.length > 0) {
            studentsReceived.forEach((element, i) => {
                const received = {
                    id: element.id,
                    Nombre: element.first_name + " " + element.last_name,
                    Numero: i + 1,
                };
                receivedRows.push(received);
            });

            let currentDay = 0;
            dailyAttendance.forEach((element) => {
                currentDay = element.date;
                element.missing_students.forEach((missingStudentId) => {
                    receivedRows.find(row => row.id === missingStudentId)[currentDay] = false;
                })
            });
        }
        setRows(receivedRows);
    }

    function handleClick(clickedField) {
        if (clickedField.value === true) {
            AttendanceService.markMissing(classroomId, clickedField.field, clickedField.id).then(_ => _,
                _ => context.handleAPIError("Ha ocurrido un error enviando los datos. Recarga la página o intenta de nuevo más tarde.")
            );
            rows.find(row => row.id === clickedField.id)[clickedField.field] = !clickedField.value;
        }
        if (clickedField.value === false) {
            AttendanceService.unmarkMissing(classroomId, clickedField.field, clickedField.id).then(_ => _,
                _ => context.handleAPIError("Ha ocurrido un error enviando los datos. Recarga la página o intenta de nuevo más tarde.")
            );
            rows.find(row => row.id === clickedField.id)[clickedField.field] = !clickedField.value;
        }
    }

    if (isLoading) {
        return (<Skeleton variant="rectangular" height={"1000px"}/>);
    }

    return (
        <Box sx={trackingBoxStyles}>
            <DataGrid
                onCellClick={handleClick}
                columns={columns}
                rows={rows}
                getCellClassName={(params) => {
                    switch (params.value) {
                        case true:
                            return 'green';
                        case false:
                            return 'red';
                        default:
                            return 'name';
                    }
                }}
                getRowClassName={(item) => {
                    if (item.indexRelativeToCurrentPage % 2 === 0) {
                        return "odd";
                    }
                    return "";
                }}
                disableSelectionOnClick
                disableColumnMenu
                hideFooter
            />
        </Box>
    );
}