import {alpha, Tab, Tabs} from "@mui/material";
import React from "react";
import AllSubjectsTable from "./AllSubjectsTable";
import SubjectSummaryTable from "./SubjectSummaryTable";

const ALL_SUBJECTS_ITEM = {
    name: "Todas",
    id: -1,
};


const AllSubjectsSummary = (props) => {
    const colorList = [
        "#D50000",
        "#8E24AA",
        "#0B8043",
        "#E67C73",
        "#7986CB",
        "#F4511E",
        "#F6BF26",
        "#3F51B5",
        "#33B679",
        "#616161",
        "#039BE5",
    ];

    const [currentTab, setCurrentTab] = React.useState(ALL_SUBJECTS_ITEM.id);
    const subjects = [ALL_SUBJECTS_ITEM, ...props.subjects];
    const studentId = props.studentId || null;

    if (subjects.length === 1) {
        return <></>
    }

    return <><Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)}
                      variant="scrollable"
                      allowScrollButtonsMobile>
                    {subjects.map((subject, i) =>  <Tab key={subject.id} value={subject.id} label={subject.name}
                                    sx={{
                                        backgroundColor: alpha(colorList[i % colorList.length], .1),
                                    }}/>
                    )}
                </Tabs>
        {currentTab === ALL_SUBJECTS_ITEM.id ? <AllSubjectsTable studentId={studentId} /> : <SubjectSummaryTable subjectId={currentTab} studentId={studentId}/>}
    </>
}

export default AllSubjectsSummary;