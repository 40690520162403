export const COLORS = [
    {value: 10, label: '🟢'},
    {value: 5, label: '🟡'},
    {value: 0, label: '🔴'}
];

export const SOBRESALIENTE_INSUFICIENTE = [
    {value: 10, label: 'Sobresaliente'},
    {value: 8, label: 'Notable'},
    {value: 6, label: 'Bien'},
    {value: 5, label: 'Suficiente'},
    {value: 2.5, label: 'Insuficiente'},
    {value: 0, label: 'Sin Calificación'},
];

export const EXCELENTE_NO_ADECUADO = [
    {label: 'Excelente', value: 10},
    {label: 'Bueno', value: 7.5},
    {label: 'Adecuado', value: 5},
    {label: 'No adecuado', value: 2.5},
    {value: 0, label: 'Sin Calificación'},
];

export const ACTIVITY_TYPES = [
    {label: "Nota sobre 10", value: "Sobre 10"},
    {label: "Colores", value: "Colores"},
    {label: "Excelente, Bueno, Adecuado, No Adecuado", value: "Excelente-No Adecuado"},
    {label: "Sobresaliente, Notable, Bien, Suficiente, Insuficiente", value: "Sobresaliente-Insuficiente"},
]
