export const evaluationBoxStyles = {
    width: '100%',
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fafafa',
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-cell': {
        borderRight: `1px solid #f0f0f0`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid #f0f0f0`,
        borderRight: `1px solid #f0f0f0`,
    },
    '& .name': {
        color: "primary.main",
    },
    '& .criterios': {
        color: "white",
        fontWeight: 'bold',
        backgroundColor: "evaluation.background",
        borderBottom: `1px solid #673ab7`,
    },
    '& .evaluations--header': {
        color: "white",
        backgroundColor: "evaluation.headerBackground",
        borderColor: "evaluation.headerBackground",
        whiteSpace: "normal",
        wordWrap: "break-word"
    },
    "& .odd .filled": {
        backgroundColor: "#f4f2fc",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 'bold',
    },
    '& .evaluations--final': {
        color: "white",
        backgroundColor: "evaluation.headerLightBackground",
        borderColor: "evaluation.headerLightBackground",
        whiteSpace: "normal",
        wordWrap: "break-word"
    },
}

export const trackingBoxStyles = {
    height: 750,
    width: '100%',
    '& .green': {
        backgroundColor: 'rgba(177,255,142,0.55)',
        color: '#ffffff',
    },
    '& .red': {
        backgroundColor: 'rgba(255,102,102,0.55)',
        color: '#ffffff',
    },
    '& .name': {
        color: '#512da8',
        fontWeight: 'bold',
    },
    '& .odd .green': {
        backgroundColor: 'rgba(177,255,142,1)',
        color: '#ffffff',
    },
    '& .odd .red': {
        backgroundColor: 'rgba(255,102,102,0.9)',
        color: '#ffffff',
    },
    '& .odd .name': {
        color: '#512da8',
        fontWeight: 'bold',
        backgroundColor: '#f4f2fc',
    },
}

export const LEVELS = [
    {id: 25, name: "Infantil 1er ciclo (0 años)"},
    {id: 26, name: "Infantil 1er ciclo (1 años)"},
    {id: 27, name: "Infantil 1er ciclo (2 años)"},
    {id: 28, name: "Infantil 2º ciclo (3 años)"},
    {id: 29, name: "Infantil 2º ciclo (4 años)"},
    {id: 30, name: "Infantil 2º ciclo (5 años)"},
    {id: 1, name: "1º Primaria"},
    {id: 2, name: "2º Primaria"},
    {id: 3, name: "3º Primaria"},
    {id: 4, name: "4º Primaria"},
    {id: 5, name: "5º Primaria"},
    {id: 6, name: "6º Primaria"},
    {id: 7, name: "1º ESO"},
    {id: 8, name: "2º ESO"},
    {id: 9, name: "3º ESO"},
    {id: 10, name: "4º ESO"},
    {id: 13, name: "1º Bachillerato"},
    {id: 14, name: "2º Bachillerato"},
    {id: 11, name: "FP - Grado medio"},
    {id: 12, name: "FP - Grado superior"},
]

export const COMUNIDADES = [
        {id: "ANDALUCIA", name: "Andalucía"},
        {id: "ARAGON", name: "Aragón"},
        {id: "ASTURIAS", name: "Asturias"},
        {id: "BALEARES", name: "Baleares"},
        {id: "CANARIAS", name: "Canarias"},
        {id: "CANTABRIA", name: "Cantabria"},
        {id: "CASTILLA_Y_LEON", name: "Castilla y León"},
        {id: "CASTILLA_LA_MANCHA", name: "Castilla-La Mancha"},
        {id: "CATALUNA", name: "Cataluña"},
        {id: "VALENCIA", name: "Comunidad Valenciana"},
        {id: "EXTREMADURA", name: "Extremadura"},
        {id: "GALICIA", name: "Galicia"},
        {id: "MADRID", name: "Madrid"},
        {id: "MURCIA", name: "Murcia"},
        {id: "NAVARRA", name: "Navarra"},
        {id: "PAIS_VASCO", name: "País Vasco"},
        {id: "LA_RIOJA", name: "La Rioja"},
        {id: "CEUTA", name: "Ceuta"},
        {id: "MELILLA", name: "Melilla"},
    ];

export const LATEST_CLASSROOM_ID_FROM_2023 = 6563;
