import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import ErrorMessage from "../genericui/feedback/ErrorMessage";
import * as React from "react";
import {Grid, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import {CalendarToday, Today} from "@mui/icons-material";
import EventsSummary from "./EventsSummary";
import SubCard from "../genericui/cards/SubCard";
import SuccessMessage from "../genericui/feedback/SuccessMessage";
import HomePageFeedbackForm from "./HomePageFeedbackForm";
import TodoList from "./TodoList";
import Button from "@mui/material/Button";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SubscriptionStatus from "./SubscriptionStatus";


export default function HomePage(props) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [message, setMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const TODAY = new Date();
    const YESTERDAY = getYesterdayDate();
    const TOMORROW = getTomorrowDate();

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        }
    }, [isFirstLoad]);

    function getYesterdayDate() {
        let yesterday = new Date(TODAY);
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    }

    function getTomorrowDate() {
        let tomorrow = new Date(TODAY);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

    function handleTabChange(event, newValue) {
        setCurrentTab(newValue);
    }

    function _showSuccessMessage(message) {
        setMessage(message);
        setIsSuccessMessageVisible(true);
    }

    function _handleAPIError(message) {
        setMessage(message);
        setIsErrorMessageVisible(true);
    }

    if (isFirstLoad) {
        return <Skeleton variant="rectangular" height={"1000px"}/>
    }
    return <Box sx={{flexGrow: 1}}>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={message}/>}
        {isSuccessMessageVisible &&
            <SuccessMessage disableSuccessMessage={() => setIsSuccessMessageVisible(false)} message={message}/>}
        <Grid container spacing={2} sx={{p: 0, display: 'flex'}}>
            <Grid item xs={12}>
                <SubscriptionStatus/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SubCard>
                            <Typography align="center" component="h2" variant="h5">Tu día</Typography>
                            <Tabs value={currentTab} onChange={handleTabChange}>
                                <Tab icon={<CalendarToday/>} wrapped label="Ayer" sx={{width: '33%'}}/>
                                <Tab icon={<Today/>} wrapped label="Hoy" sx={{width: '33%'}}/>
                                <Tab icon={<CalendarToday/>} wrapped label="Mañana" sx={{width: '33%'}}/>
                            </Tabs>
                            <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
                                {currentTab === 0 && <EventsSummary date={YESTERDAY} handleAPIError={_handleAPIError}/>}
                                {currentTab === 1 && <EventsSummary date={TODAY} handleAPIError={_handleAPIError}/>}
                                {currentTab === 2 && <EventsSummary date={TOMORROW} handleAPIError={_handleAPIError}/>}
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SubCard>
                            <Typography align="center" component="h2" variant="h5">🪄 ¡Prueba nuestra IA!</Typography>
                            <Typography align="center">Genera Situaciones de Aprendizaje y mucho más con nuestra Inteligencia Artificial.</Typography>
                            <Button align="center" fullWidth variant="contained" href="/ia/sdas" color="info" size="large" sx={{mt: 2}} startIcon={<AutoAwesomeIcon/>} endIcon={<SmartToyIcon/>}>¡Probar Inteligencia Artificial!</Button>
                        </SubCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SubCard>
                            <Typography align="center" component="h2" variant="h5">📋 Lista de tareas pendientes (TO-DO List)</Typography>
                            <Typography align="center">Organiza tus tareas diarias y mantén el control de tus actividades.</Typography>
                            <TodoList/>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item>
                        <SubCard>
                            <Grid container
                                  sx={{p: 0, display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                                <Grid item xs={12}>
                                    <Typography align="center" component="h2" variant="h5">Últimos
                                        tutoriales</Typography></Grid>
                                <Typography align="center" color="primary">Cómo gestionar tus Clases y añadir
                                    alumnos:</Typography>
                                <Grid item>
                                    {<iframe width="310" height="180"
                                             src="https://www.youtube.com/embed/WaELWLdosKg?si=Yot_3EgWNHDTLdNH"
                                             title="YouTube video player" frameBorder="0"
                                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                             allowFullScreen></iframe>}
                                </Grid>
                                <Typography align="center" color="primary">Cómo programar y preparar tus clases con
                                    nuestra agenda:</Typography>
                                <Grid item>
                                    {<iframe width="310" height="180"
                                             src="https://www.youtube.com/embed/TDyVE-859Og?si=bwzx5uXUktRiepja"
                                             title="YouTube video player" frameBorder="0"
                                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                             allowFullScreen></iframe>}
                                </Grid>
                                <Typography align="center" color="primary">Cómo realizar seguimiento de materiales
                                    entregados y otras entregas:</Typography>
                                <Grid item>
                                    {<iframe width="310" height="180"
                                             src="https://www.youtube.com/embed/e3eG-eH7v_U?si=j_eizou6YqSl3sH_"
                                             title="YouTube video player" frameBorder="0"
                                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                             allowFullScreen></iframe>}
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item>
                        <SubCard>
                            <HomePageFeedbackForm showSuccessMessage={_showSuccessMessage}
                                                  handleAPIError={_handleAPIError}/>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>;
}