export const WEEK = {
    weekDays: [0, 1, 2, 3, 4],
    weekStartOn: 1,
    navigation: true,
}

export const TRANSLATIONS = {
    navigation: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        today: "Hoy"
    },
    form: {
        addTitle: "Añadir Evento",
        editTitle: "Editar Evento",
        confirm: "Confirmar",
        delete: "Eliminar",
        cancel: "Cancelar"
    },
    event: {
        title: "Título",
        start: "Inicio",
        end: "Fin",
        allDay: "Todo el día"
    },
    moreEvents: "Más..."
}

export const FIELDS = [
    {
        name: "classroom_id",
        type: "select",
        default: "",
    },
    {
        name: "body",
        type: "input",
        default: "",
    },
    {
        name: "event_type",
        type: "input",
        default: "PLANNER",
    },
    {
        name: "color",
        type: "input",
        default: "",
    },
    {
        name: "title",
        type: "input",
        default: "",
    }
];
