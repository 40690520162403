import {Grid} from "@mui/material";
import React from "react";
import GenericModal from "../../genericui/modals/GenericModal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const SubscriptionPopup = ({onClose}) => {
    return <GenericModal title="¡Suscríbete ya!" onClose={onClose}>
            <Grid container maxWidth="sm" spacing={1.5}>
                <Grid item xs={12} sx={{justifyContent: "center", mt: 1}}>
                    <Typography align="center" variant="h4">3€/mes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Tu prueba gratuita acabará pronto.</Typography>
                    <Typography sx={{fontWeight: "bold"}}>¡Usa nuestra app sin límites por tan solo 3€/mes!</Typography>
                    <Typography>Ahorra tiempo en el curso 2024/25 con 4Docentes Premium y planifica tu curso con nuestra IA.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button href="/suscripcion" target="_blank" variant="contained" fullWidth align="center" sx={{my: 1}} color="success">¡Suscribirme!</Button>
                    <Button onClick={onClose} variant="outlined" fullWidth align="center" sx={{my: 1}}>Recordármelo más tarde</Button>
                </Grid>
            </Grid>
    </GenericModal>
}

export default SubscriptionPopup;
