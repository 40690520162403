import { Typography } from "@mui/material";

export default function MeetingsEventCustomRenderer(event) {
    return (
        <div style={{padding: 2}}>
            <Typography sx={{fontWeight: 'bold'}} variant="subtitle1" style={{fontSize: 12}} noWrap>
                {event.title}
            </Typography>
        </div>
    );
}