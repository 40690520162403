import PlannerEventsService from "../../services/PlannerService";
import {parseISO} from "date-fns";

export function submitPlannerEvent(scheduler, currentState, classrooms, handleAPIError) {
    scheduler.loading(true);
    let start = new Date(currentState.date);
    let end = new Date(currentState.date);
    start.setTime(currentState.start);
    end.setTime(currentState.end);
    const isEdited = scheduler.edited;

    const data = {
        id: currentState.id,
        title: currentState.title,
        event_type: "PLANNER",
        start: start.toISOString(),
        end: end.toISOString(),
        body: currentState.body,
        color: currentState.color,
        classroom_id: currentState.classroom_id,
    };
    if (isEdited) {
        PlannerEventsService.updatePlannerEvent(data, data.id).then(
            response => _refreshSchedulerWithNewData(response.data, scheduler, classrooms, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    } else if (currentState.isRepeating) {
        PlannerEventsService.createNewRecurringPlannerEvent(data, currentState.repeatDate).then(
            response => _refreshSchedulerWithNewData(response.data[0], scheduler, classrooms, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    } else {
        PlannerEventsService.createNewPlannerEvent(data).then(
            response => _refreshSchedulerWithNewData(response.data[0], scheduler, classrooms, isEdited),
            _ => handleAPIError("Ha ocurrido un error. Revisa los datos o intenta de nuevo más tarde.")
        );
    }
}

function _refreshSchedulerWithNewData(responseData, scheduler, classrooms, isEdited) {
    const newData = responseData;
    const classroomFound = classrooms.find(({id}) => id === newData.classroom_id);
    newData.classroom_name = classroomFound.name;
    newData.event_id = newData.id;
    newData.start = new Date(parseISO(newData.start));
    newData.end = new Date(parseISO(newData.end));

    scheduler.onConfirm(newData, isEdited ? "edit" : "create");
    scheduler.loading(false);
    scheduler.close();
}
