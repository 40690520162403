import {
    DataGrid,
    GridCellModes,
    GridColumnMenu,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useState} from "react";
import EvaluationService from "../../../services/EvaluationService";
import {COLORS, EXCELENTE_NO_ADECUADO, SOBRESALIENTE_INSUFICIENTE} from "./ValueOptions";
import Typography from "@mui/material/Typography";
import DeleteDataGridColumnMenuItem from "../../genericui/datagrid/DeleteDataGridColumnMenuItem";
import NewActivityModal from "./NewActivityModal";
import Button from "@mui/material/Button";
import {Add, CheckBox, CheckBoxOutlineBlank, Download} from "@mui/icons-material";
import {Autocomplete, Checkbox, Chip, Divider, Grid, Skeleton} from "@mui/material";
import EditActivityModal from "./EditActivityModal";
import EditDataGridColumnMenuItem from "../../genericui/datagrid/EditDataGridColumnMenuItem";
import CopyMarksColumnMenuItem from "./datagrid_items/CopyMarksColumnMenuItem";
import CopyMarksModal from "./CopyMarksModal";
import {exportExcel} from "./presenters/ExcelPresenter";
import {useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {LATEST_CLASSROOM_ID_FROM_2023} from "../utils";

const AVERAGE_ID = -999999999;
const NAME_AND_NUMBER_SUFFIX_COLUMNS = [
    {
        field: 'Nombre2',
        headerName: 'Nombre',
        minWidth: 150,
        type: 'string',
        editable: false,
        headerClassName: 'evaluations--header',
        sortable: false,
        marks: [],
        headerAlign: 'left',
        align: "left",
    },
    {
        field: 'Numero2',
        headerName: 'Número',
        width: 80,
        type: 'number',
        editable: false,
        headerClassName: 'evaluations--header',
        sortable: false,
        marks: [],
        headerAlign: 'center',
        align: "right",
    },
]
const NAME_AND_NUMBER_PREFIX_COLUMNS = [
    {
        field: 'Numero',
        headerName: 'Número',
        width: 80,
        type: 'number',
        editable: false,
        headerClassName: 'evaluations--header',
        sortable: false,
        marks: [],
        headerAlign: 'center',
        align: "right",
    },
    {
        field: 'Nombre',
        headerName: 'Nombre',
        minWidth: 150,
        type: 'string',
        editable: false,
        headerClassName: 'evaluations--header',
        sortable: false,
        marks: [],
        headerAlign: 'left',
        align: "left",
    },
];

const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

function NewColumnButton(props) {
    return <Grid container sx={{p: 0, display: "flex", alignItems: "center"}}>
        <Grid item xs={12} md={4} sx={{m: 1}}>
            <Button variant="contained" fullWidth size="small" onClick={props.onClick}
                    startIcon={<Add/>}>
                Nueva Columna
            </Button>
        </Grid>
    </Grid>;
}

export default function EvaluationTable(props) {
    const {classroomId, subjectId} = useParams();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const processedRows = props.students;
    const criterios = props.criterios;
    const [isNewActivityModalOpen, setIsNewActivityModalOpen] = useState(false);
    const [isEditActivityModalOpen, setIsEditActivityModalOpen] = useState(false);
    const [currentEditColumn, setCurrentEditColumn] = useState({});
    const [rows, setRows] = useState([]);
    const [currentTab, setCurrentTab] = useState(-1);
    const [currentCopyColumn, setCurrentCopyColumn] = useState({});
    const [isCopyMarksModalOpen, setIsCopyMarksModalOpen] = useState(false);
    const [selectedTabs, setSelectedTabs] = useState(props.tabs.slice(0, -1));
    const [columns, setColumns] = useState(NAME_AND_NUMBER_PREFIX_COLUMNS);

    function presentActivitiesByTab(data) {
        const localColumns = [...NAME_AND_NUMBER_PREFIX_COLUMNS];
        data.forEach((element) => {
            const description = element.criterios.length > 0 ? "Criterios " + element.criterios.map(item => item.code).join(", ") : "Sin criterios";
            localColumns.push({
                id: element.id,
                field: element.id.toString(),
                headerName: element.title,
                description: description,
                minWidth: element.width,
                type: getActivityType(element),
                valueOptions: getValueOptions(element),
                headerAlign: 'center',
                editable: element.id !== AVERAGE_ID && classroomId > LATEST_CLASSROOM_ID_FROM_2023,
                sortable: false,
                headerClassName: element.id === AVERAGE_ID ? 'evaluations--final' : 'evaluations--header',
                align: 'center',
                dates: element.start_date ? dateValue(element.start_date) + ' - ' + dateValue(element.end_date) : "",
                marks: element.marks,
                percentage: element.percentage,
                renderHeader: customRenderHeader,
                criterios: element.criterios,
                start_date: element.start_date,
                end_date: element.end_date,
                mark_type: element.type,
                preProcessEditCellProps: (params) => {
                    const hasError = params.props.value < 0 || params.props.value > 10;
                    return {...params.props, error: hasError};
                },
            });
        })
        return localColumns;
    }

    useEffect(() => {
        if (props.tab !== currentTab) {

            setCurrentTab(props.tab);
            setIsFirstLoad(true);
            if (props.tab === 0) {
                const selectedTabsIds = selectedTabs.map(element => element.id);
                EvaluationService.getFinalMarksSummaryByTabs(classroomId, subjectId, selectedTabsIds).then((response) => {
                        const newColumns = presentActivitiesByTab(response.data);
                        const filledRows = setAllCellsToMinimumValue(newColumns, processedRows);
                        setRows(filledRows);
                        setColumns(newColumns);
                        setIsFirstLoad(false);
                    },
                    _ => props.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde.")
                )
            } else {
                EvaluationService.getActivitiesByTab(classroomId, props.tab, subjectId).then((response) => {
                        const newColumns = presentActivitiesByTab(response.data);
                        const filledRows = setAllCellsToMinimumValue(newColumns, processedRows);
                        setRows(filledRows);
                        setColumns(newColumns);
                        setIsFirstLoad(false);
                    },
                    _ => props.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde.")
                )
            }

        }
    }, [classroomId, props, columns, processedRows, currentTab, subjectId, presentActivitiesByTab, selectedTabs])

    function getActivityType(activity) {
        if (activity.type === "Sobre 10") {
            return "number";
        }
        return "singleSelect";
    }

    function getValueOptions(activity) {
        switch (activity.type) {
            case "Colores":
                return COLORS;
            case "Excelente-No Adecuado":
                return EXCELENTE_NO_ADECUADO;
            case "Sobresaliente-Insuficiente":
                return SOBRESALIENTE_INSUFICIENTE;
            default:
                return null;
        }
    }

    function dateValue(dateString) {
        const date = new Date(dateString);
        return date.getDate() + "/" + (date.getMonth() + 1);
    }

    function customRenderHeader(params) {
        return <div style={{width: "auto"}}>
            <Box sx={{textAlign: "center", fontWeight: 'bold'}}><Typography
                sx={{wordWrap: "break-word", whiteSpace: "normal"}}>{params.colDef.headerName}</Typography></Box>
            <Box sx={{textAlign: "center"}}><Typography variant="subtitle2">{params.colDef.dates}</Typography></Box>
            <Box sx={{textAlign: "center"}}><Typography
                variant="subtitle2">{params.colDef.percentage !== null ? params.colDef.percentage + "%" : ""}</Typography></Box>
        </div>
    }

    function setAllCellsToMinimumValue(receivedColumns, rowsToProcess) {
        const processedRows = [...rowsToProcess];

        processedRows.forEach((currentRow) => {
            receivedColumns.forEach(column => {
                if (column.field !== "Nombre" && currentRow["Nombre"] !== "Nombre" && column.field !== "Numero" && currentRow["Numero"] !== "Número" && column.field !== "Nombre2" && currentRow["Nombre2"] !== "Nombre" && column.field !== "Numero2" && currentRow["Numero2"] !== "Número") {
                    currentRow[column.field] = 0;
                }
            });
        });

        let currentRowIndex;
        receivedColumns.forEach((column) => {
            if (column.marks) {
                column.marks.forEach(mark => {
                    currentRowIndex = processedRows.map(x => x.id).indexOf(mark.student_id);
                    if (currentRowIndex > -1) {
                        processedRows[currentRowIndex][column.field] = mark.value;
                    }
                })
            }
        });
        return processedRows;
    }

    function addColumn(columnData) {
        const dataToSend = {
            title: columnData.name,
            width: columnData.width,
            classroom_id: classroomId,
            criterios_list: columnData.criterios_list,
            start_date: columnData.start_date,
            end_date: columnData.end_date,
            subject_id: subjectId,
            type: columnData.type,
            percentage: columnData.percentage,
            tab_id: props.tab,
        }
        EvaluationService.createNewActivity(classroomId, dataToSend).then((response) => {
                const localColumns = [...columns];
                const description = dataToSend.criterios ? "Criterios " + dataToSend.criterios.map(item => item.code).join(", ") : "Sin criterios";
                localColumns.push({
                    id: response.data.id,
                    field: response.data.id.toString(),
                    description: description,
                    headerName: columnData.name,
                    minWidth: columnData.width,
                    type: getActivityType(response.data),
                    valueOptions: getValueOptions(response.data),
                    align: 'center',
                    headerAlign: 'center',
                    editable: classroomId > LATEST_CLASSROOM_ID_FROM_2023,
                    sortable: false,
                    headerClassName: 'evaluations--header',
                    dates: columnData.start_date ? dateValue(columnData.start_date) + ' - ' + dateValue(columnData.end_date) : "",
                    marks: [],
                    percentage: columnData.percentage,
                    renderHeader: customRenderHeader,
                    criterios: response.data.criterios,
                    start_date: response.data.start_date,
                    end_date: response.data.end_date,
                    mark_type: response.data.mark_type,
                    preProcessEditCellProps: (params) => {
                        const hasError = params.props.value < 0 || params.props.value > 10;
                        return {...params.props, error: hasError};
                    },
                });
                setColumns(localColumns);
                const localRows = [...rows];
                for (let i = 0; i < localRows.length; i++) {
                    localRows[i][response.data.id] = 0;
                }
                setRows(localRows);
            },
            _ => props.handleAPIError("Ha ocurrido un error añadiendo esta columna. Recarga la página o intenta de nuevo más tarde.")
        );
    }

    function editColumn(columnData) {
        const dataToSend = {
            id: columnData.id,
            title: columnData.name,
            criterios_list: columnData.criterios_list.map((value) => value.id),
            start_date: columnData.start_date,
            end_date: columnData.end_date,
            type: columnData.type,
            percentage: columnData.percentage,
        }
        EvaluationService.editActivity(classroomId, columnData.id, dataToSend).then((response) => {
                const description = response.criterios ? "Criterios " + response.criterios.map(item => item.code).join(", ") : "Sin criterios";
                const localColumns = [...columns];
                const indexFound = localColumns.map(x => x.id).indexOf(response.data.id);
                localColumns[indexFound] = {
                    id: response.data.id,
                    field: response.data.id.toString(),
                    description: description,
                    headerName: response.data.title,
                    minWidth: response.data.width,
                    type: getActivityType(response.data),
                    valueOptions: getValueOptions(response.data),
                    align: 'center',
                    headerAlign: 'center',
                    editable: classroomId > LATEST_CLASSROOM_ID_FROM_2023,
                    sortable: false,
                    headerClassName: 'evaluations--header',
                    dates: response.data.start_date ? dateValue(response.data.start_date) + ' - ' + dateValue(response.data.end_date) : "",
                    marks: localColumns[indexFound].marks,
                    percentage: response.data.percentage,
                    renderHeader: customRenderHeader,
                    criterios: response.data.criterios,
                    start_date: response.data.start_date,
                    end_date: response.data.end_date,
                    mark_type: response.data.mark_type,
                    preProcessEditCellProps: (params) => {
                        const hasError = params.props.value < 0 || params.props.value > 10;
                        return {...params.props, error: hasError};
                    },
                }
                setColumns(localColumns);
            },
            _ => props.handleAPIError("Ha ocurrido un error añadiendo esta columna. Recarga la página o intenta de nuevo más tarde.")
        );
    }

    function getRowClassName(params) {
        if (params.row.id === 0) {
            return "criterios";
        }

        return '';
    }

    function CustomColumnMenu(props) {
        const {colDef} = props;
        const isReadOnlyColumn = (classroomId < LATEST_CLASSROOM_ID_FROM_2023 || colDef.field === "Nombre" || colDef.field === "Numero" || colDef.field === "Nombre2" || colDef.field === "Numero2" || colDef.field === AVERAGE_ID.toString());
        const slots = {
            columnMenuUserItem: CopyMarksColumnItem,
            columnMenuColumnsItem: DeleteEvaluationColumnItem,
            columnMenuFilterItem: EditEvaluationColumnItem,
        }
        return <>{isReadOnlyColumn || currentTab === 0 ? null :
            <GridColumnMenu
                {...props}
                slots={slots}
            />
        }</>
    }

    function DeleteEvaluationColumnItem(args) {
        return <DeleteDataGridColumnMenuItem callback={deleteColumn} message={DELETE_COLUMN_MESSAGE} {...args}/>
    }

    function deleteColumn(columnDefinition) {
        EvaluationService.deleteActivity(classroomId, columnDefinition.id).then(
            _ => removeColumn(columnDefinition.field),
            _ => props.handleAPIError("Ha ocurrido un error. Recarga la página o inténtalo de nuevo más tarde.")
        )
    }

    function EditEvaluationColumnItem(args) {
        return <EditDataGridColumnMenuItem handleClick={() => handleClickOnEdit(args.colDef)}/>
    }

    function handleClickOnEdit(selectedColumn) {
        setCurrentEditColumn(selectedColumn);
        setIsEditActivityModalOpen(true);
    }

    function CopyMarksColumnItem(args) {
        return <CopyMarksColumnMenuItem handleClick={() => handleClickOnCopyMarks(args.colDef)}/>
    }

    function handleClickOnCopyMarks(selectedColumn) {
        setCurrentCopyColumn(selectedColumn);
        setIsCopyMarksModalOpen(true);
    }

    function removeColumn(columnName) {
        const localColumns = [...columns];
        const index = localColumns.map(function (x) {
            return x.field;
        }).indexOf(columnName);
        localColumns.splice(index, 1);
        setColumns(localColumns);
    }

    const [cellModesModel, setCellModesModel] = useState({});

    const handleCellClick = useCallback((params, event) => {
        if (params.isEditable && currentTab !== 0) {
            setCellModesModel((prevModel) => {
                return {
                    // Revert the mode of the other cells from other rows
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: {mode: GridCellModes.View}
                                }),
                                {}
                            )
                        }),
                        {}
                    ),
                    [params.id]: {
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({...acc, [field]: {mode: GridCellModes.View}}),
                            {}
                        ),
                        [params.field]: {mode: ((((prevModel[params.id] || {})[params.field] || {}).mode === GridCellModes.Edit || ((prevModel[params.id] || {})[params.field] || {}).ignoreModifications) ? GridCellModes.View : GridCellModes.Edit)}
                    }
                }
            });
        }
    }, [currentTab]);

    const handleMarkChange = (newRow, oldRow) => {
        const studentId = newRow.id;
        const localColumns = [...columns];
        const localRows = [...rows];

        Object.keys(newRow).forEach((key, i) => {
            if (newRow[key] !== oldRow[key]) {
                const affectedColumnIndex = localColumns.map(x => x.field).indexOf(key);
                const localMarks = localColumns[affectedColumnIndex].marks || [];
                const affectedColumnId = localColumns[affectedColumnIndex].id;
                localRows.find(row => row.id === studentId)[affectedColumnId] = newRow[key];

                if (localMarks && localMarks.find(mark => mark.student_id === studentId)) {
                    localMarks.find(mark => mark.student_id === studentId).value = newRow[key];
                } else {
                    localMarks.push({"student_id": studentId, "value": newRow[key]})
                }
                localColumns[affectedColumnIndex].marks = localMarks;

                EvaluationService.sendMarks(classroomId, key, studentId, localMarks).then((response) => {
                    setColumns(localColumns);
                    setRows(localRows);
                }).catch(
                    error => {
                        props.handleAPIError("Ha ocurrido un error . Por favor, intenta de nuevo más tarde.");
                    }
                )
            }
        })
        return newRow;
    }

    const handleCellModesModelChange = React.useCallback((newModel) => {
        setCellModesModel(newModel);
    }, []);

    function handleSelectedTabsChange(event, values) {
        setSelectedTabs(values);
        const selectedTabsIds = values.map(element => element.id);
        setIsFirstLoad(true);
        EvaluationService.getFinalMarksSummaryByTabs(classroomId, subjectId, selectedTabsIds).then((response) => {
                const newColumns = presentActivitiesByTab(response.data);
                const filledRows = setAllCellsToMinimumValue(newColumns, processedRows);
                setRows(filledRows);
                setColumns(newColumns);
                setIsFirstLoad(false);
            },
            _ => props.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde.")
        )
    }

    if (isFirstLoad) {
        return (<Skeleton variant="rectangular" height={"800px"}/>);
    }

    return <>
        {isNewActivityModalOpen && <NewActivityModal onSubmit={addColumn} criterios={criterios} tabId={props.tab}
                                                     onClose={() => setIsNewActivityModalOpen(false)}/>}
        {isEditActivityModalOpen &&
            <EditActivityModal onSubmit={editColumn} column={currentEditColumn} criterios={criterios}
                               onClose={() => setIsEditActivityModalOpen(false)}/>}
        {isCopyMarksModalOpen && <CopyMarksModal column={currentCopyColumn} tabs={props.tabs} classroomId={classroomId}
                                                 onClose={() => setIsCopyMarksModalOpen(false)} {...props}/>}
        {(currentTab !== 0 && classroomId>LATEST_CLASSROOM_ID_FROM_2023) && <NewColumnButton onClick={() => setIsNewActivityModalOpen(true)}/>}
        {currentTab === 0 && <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
            <Grid item xs={12}>
                <Autocomplete
                    id="tabs"
                    name='tabs'
                    multiple
                    fullWidth
                    value={selectedTabs}
                    onChange={handleSelectedTabsChange}
                    disableCloseOnSelect={true}
                    options={props.tabs.slice(0, -1)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip variant="outlined" label={option.name} {...getTagProps({index})}/>
                        ));
                    }
                    }
                    renderOption={(props, option, state) => {
                        const isChecked = selectedTabs.some(element => element.id === option.id);
                        return <>
                            <li {...props}>
                                <Grid container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                      columns={16}
                                >
                                    <Grid item xs={2}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={state.selected || isChecked}
                                        />
                                    </Grid>
                                    <Grid item xs={14}>
                                        <Typography component='div'>
                                            {option.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                            <Divider/>
                        </>
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            fullWidth
                            id="tabs-input"
                            label="Pestañas"
                            size='medium'
                            variant="standard"
                            helperText="Selecciona qué pestañas quieres que aparezcan en el resumen."
                        />
                    )}
                />
            </Grid>
        </Grid>}
        <Box sx={{whiteSpace: "normal !important"}}>
            <Box>
                <Button onClick={() => exportExcel(rows, columns, "Notas")} startIcon={<Download/>}>Descargar a
                    Excel</Button>
            </Box>
            <Box sx={{height: "65vh"}}>
                <DataGrid
                    cellModesModel={cellModesModel}
                    onCellModesModelChange={handleCellModesModelChange}
                    onCellClick={handleCellClick}
                    processRowUpdate={handleMarkChange}
                    onProcessRowUpdateError={(error) => console.log(error)}
                    rowModel='server'
                    columns={columns.length > 4 ? columns.concat(NAME_AND_NUMBER_SUFFIX_COLUMNS) : columns}
                    rows={rows}
                    isCellEditable={(params) => params.row.id !== 0}
                    getCellClassName={(item) => {
                        if (item.value !== null) {
                            return 'filled';
                        }
                        return '';
                    }}
                    getRowClassName={(item) => {
                        if (item.indexRelativeToCurrentPage % 2 === 0 && item.id > 0) {
                            return getRowClassName(item) + " odd";
                        }
                        return getRowClassName(item);
                    }}
                    slots={{
                        columnMenu: CustomColumnMenu,
                    }}
                    rowHeight={35}
                    columnHeaderHeight={95}
                    hideFooter
                />
            </Box>
        </Box></>
}

const DELETE_COLUMN_MESSAGE = "¿De verdad quieres eliminar esta columna? Esta acción no se podrá deshacer.";
