import * as React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MainCard from "../cards/MainCard";
import {Component} from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1.5,
    maxHeight: '95vh',
    overflow: 'auto',
};

export default class GenericModal extends Component {
    constructor(props) {
        super(props);

        this.handleOnClose = this.handleOnClose.bind(this);

        this.state = {
            open: true,
        }
    }

    handleOnClose() {
        if (this.props.onClose) {
            this.setState({
                open: false,
            });
            this.props.onClose();
        }
    }

    render() {
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    onClose={this.handleOnClose}
                    closeAfterTransition
                    sx={this.props.styles}
                >
                    <Fade in={this.state.open}>
                        <MainCard sx={{...style, ...this.props.styles}} title={this.props.title} {...this.props}>
                            {this.props.children}
                        </MainCard>
                    </Fade>
                </Modal>
            </>
        );
    }
}