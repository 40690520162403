import GenericModal from "../../genericui/modals/GenericModal";
import {Autocomplete, DialogActions, Grid, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import ClassroomsService from "../../../services/ClassroomService"
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import GoogleFormsService from "../../../services/GoogleFormsService";
import {useParams} from "react-router-dom";


const GOOGLE_FORM_ID = "1FAIpQLSeU_D6H5CRY4Sk4Z76L5i5daWfmARX221C11GkpuR_kmfxwwA";

export default function SubjectModal(props) {
    const {classroomId} = useParams();
    const [currentFormData, setCurrentFormData] = useState({});
    const [isRequestFormVisible, setIsRequestFormVisible] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [newSubjectName, setNewSubjectName] = useState("");
    const [loading, setLoading] = useState(false);
    const [level, setLevel] = useState("");
    const [region, setRegion] = useState("");

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            Promise.all([
                ClassroomsService.getAvailableSubjects(classroomId),
                ClassroomsService.getClassroomById(classroomId)
            ]).then(([subjectsResponse, classroomResponse]) => {
                setSubjects(subjectsResponse.data);
                setLevel(classroomResponse.data.level.name);
                setRegion(classroomResponse.data.region.name);
            },
                _ => props.handleAPIError("No se han podido cargar los datos. Revisa los datos o intenta de nuevo más tarde."))
        }
    }, [classroomId, isFirstLoad, props]);

    function submitChanges(event) {
        event.preventDefault();
        const localSubject = {};
        localSubject.classroom_id = classroomId;
        localSubject.lomloe_subject_id = currentFormData.lomloe_subject_id;
        props.onSave(localSubject);
    }

    function handleFormOptionChange(event, value) {
        let formData = currentFormData;
        formData.lomloe_subject_id = value.uuid;
        setCurrentFormData(formData);
    }

    function requestSubject() {
        setLoading(true);
        const formData = {
            "subject": newSubjectName,
            "level": level,
            "region": region,
        }
        const entryNameUser = "entry.98622711";
        GoogleFormsService.submitSubjectForm(GOOGLE_FORM_ID, formData, entryNameUser).then(
            _ => props.showSuccessMessage("Dentro de muy poco añadiremos tu asignatura. Recibirás un email."),
            errorResponse => {
                if (errorResponse.message === "Network Error") {
                    props.showSuccessMessage("Dentro de muy poco añadiremos tu asignatura. Recibirás un email.");
                } else {
                    props.handleAPIError("Ha ocurrido un error. Por favor, inténtalo más tarde.")
                }
            }
        ).finally(() => setLoading(false))
    }

    return <GenericModal onClose={props.onClose}>
        <form onSubmit={submitChanges}>
            <Grid container spacing={1} maxWidth="sm">
                <Grid item xs={12} sm={12}>
                    <Autocomplete
                        id="subject"
                        options={subjects}
                        getOptionLabel={(option) => option.name}
                        onChange={handleFormOptionChange}
                        renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                            size="small" required label="Asignatura"
                                                            variant="standard"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button variant="text" onClick={() => setIsRequestFormVisible(true)}>¿No encuentras tu asignatura?
                        Haz click aquí</Button>
                    {isRequestFormVisible && <Stack direction="row" spacing={1} sx={{mb: 1, mt: 1}}>
                        <TextField label={"Nueva asignatura..."} value={newSubjectName}
                                   onChange={(event) => setNewSubjectName(event.target.value)} size="small"/>
                        <LoadingButton loading={loading} variant="contained" size="small" onClick={requestSubject}>
                            Pedir asignatura
                        </LoadingButton>
                    </Stack>}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <DialogActions>
                        <Button onClick={props.onClose}>Cancelar</Button>
                        <Button type="submit" variant='contained'>Guardar</Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </form>
    </GenericModal>
}