import {useEffect, useState} from "react";
import SettingsService from "../../services/SettingsService";
import {Skeleton, Typography} from "@mui/material";
import SubCard from "../genericui/cards/SubCard";
import {useTheme} from "@mui/material/styles";
import * as React from "react";
import {useCountdown} from "../genericui/info/countdown";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const SubscriptionStatus = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isTrial, setIsTrial] = useState(true);
    const [endDate, setEndDate] = useState(null);
    const [days, hours, minutes, seconds] = useCountdown(endDate);

    useEffect(() => {
        SettingsService.getProfileInfo().then(response => {
            setIsSubscribed(response.data.is_subscription_active);
            setIsTrial(response.data.is_trial);
            const date = new Date(response.data.end_date);
            setEndDate(date);
            setIsLoading(false);
        })
    }, []);

    if (isLoading)
        return <Skeleton variant="rectangular" width="100%"/>

    if (isTrial)
        return <SubCard sx={{backgroundColor: theme.palette.primary.light, color: "white"}}>
            <Typography variant="h5" align="center" fontWeight="bold">Prueba gratuita activa</Typography>
            {days > 0 &&<Typography align="center">Termina en {days}d {hours}h {minutes}m {seconds}s</Typography>}
            <Box width="100%" textAlign="center" sx={{mt: 1}}>
                <Button variant="contained" color="warning" sx={{fontWeight: "bold"}} href="/suscripcion">¡Suscríbete antes de perder acceso!</Button>
            </Box>
        </SubCard>

    if (isSubscribed)
        return <SubCard sx={{backgroundColor: theme.palette.success.light, color: "white"}}>
            <Typography variant="h5" align="center" fontWeight="bold">Suscripción 4Docent.es premium activa</Typography>
            <Typography align="center">Termina el {endDate.toLocaleDateString()}</Typography>
        </SubCard>

    return null
}

export default SubscriptionStatus;
