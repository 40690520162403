import api from "./Api";


class NotesService {
    getExistingNotes() {
        return api.get('/notes/');
    }

    createNewNote(data) {
        return api.post('/notes/', data);
    }

    updateNote(data, Id) {
        return api.patch('/notes/' + Id + '/', data);
    }

    deleteNote(Id) {
        return api.delete('/notes/' + Id + '/');
    }
}

export default new NotesService();
