import React, {useEffect, useState} from 'react';

import {Button, Grid, TextField, Typography} from '@mui/material';
import AuthService from "../services/AuthService";
import Box from "@mui/material/Box";
import {useSearchParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";


function PasswordUpdate(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams,] = useSearchParams();
    const [userId,] = useState(searchParams.get("user_id"));
    const [token,] = useState(searchParams.get("token"));
    const [new_password1, setNewPassword1] = React.useState("");
    const [new_password2, setNewPassword2] = React.useState("");
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = useState({});

    useEffect(() => {
        if (props.isAuthenticated) {
            props.router.navigate("/");
            window.location.reload();
        }
    })

    const handleFormFieldChange = (event) => {
        setSuccess(false);
        switch (event.target.id) {
            case 'new_password1':
                setNewPassword1(event.target.value);
                break;
            case 'new_password2':
                setNewPassword2(event.target.value);
                break;
            default:
                return null;
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (new_password1 === new_password2) {
            setIsLoading(true);
            AuthService.resetPasswordConfirm(userId, token, new_password1, new_password2).then(
                _ => setSuccess(true),
                error => {
                    setError(error.response.data);
                },
                error => {
                    setError(error.response.data)
                }
            ).finally(() => setIsLoading(false))
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 6,
            ml: 3,
            mr: 3,
        }}
        >
            <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center', mt: 1}} maxWidth="sm">
                <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    {success ? <><Typography variant="button" className="success" gutterBottom>¡Contraseña Actualizada
                        correctamente!</Typography>
                        <Grid item xs={12} sx={{mb: 2}}>
                        <Button fullWidth variant="outlined" href="/login">
                            Volver a iniciar sesión
                        </Button>
                    </Grid></> : null}
                    {error.token &&
                        <><Typography sx={{color: "red"}} variant="button" gutterBottom>Ha ocurrido un error con su
                            solicitud. Probablemente
                            este código haya sido utilizado anteriormente. Por favor, vuelva a solicitar un cambio de
                            contraseña.</Typography>
                            <Grid item xs={12} sx={{mb: 2}}>
                            <Button fullWidth variant="outlined" href="/login">
                                Volver a iniciar sesión
                            </Button>
                        </Grid></>}
                </Grid>
                <Grid item>
                    <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center', mt: 1}}
                          maxWidth="328px">
                        <Grid item xs={12} sx={{mb: 2}}>
                            <Typography component="h1" variant="h5">
                                Cambiar Contraseña
                            </Typography>
                            <form className={"Avatar"} onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    size="small"
                                    name="new_password1"
                                    label="Introduce tu nueva contraseña"
                                    type="password"
                                    id="new_password1"
                                    autoComplete="new-password"
                                    onChange={handleFormFieldChange}
                                    error={error.new_password1}
                                    inputProps={{minLength: 8}}
                                    helperText={error.new_password1}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    size="small"
                                    name="new_password2"
                                    label="Introduce tu nueva contraseña de nuevo"
                                    type="password"
                                    id="new_password2"
                                    autoComplete="new-password"
                                    onChange={handleFormFieldChange}
                                    error={new_password1 !== new_password2 || error.new_password2}
                                    inputProps={{minLength: 8}}
                                    helperText={new_password1 !== new_password2 ? "Las contraseñas no coinciden" : error.new_password2}
                                />
                                <LoadingButton
                                    loading={isLoading}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="submit"
                                    sx={{mt: "16px"}}
                                >
                                    Cambiar Contraseña
                                </LoadingButton>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PasswordUpdate;
