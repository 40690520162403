import React from 'react';

import {Button, TextField, Typography} from '@mui/material';
import AuthService from "../services/AuthService";
import Box from "@mui/material/Box";


function PasswordUpdate(props) {
    const [new_password1, setNewPassword1] = React.useState(null);
    const [new_password2, setNewPassword2] = React.useState(null);
    const [success, setSuccess] = React.useState(false);

    const handleFormFieldChange = (event) => {
        setSuccess(false);
        switch (event.target.id) {
            case 'new_password1':
                setNewPassword1(event.target.value);
                break;
            case 'new_password2':
                setNewPassword2(event.target.value);
                break;
            default:
                return null;
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (new_password1 !== new_password2) {
            alert("Las contraseñas no coinciden")
        } else {
            let passwordFormData = {};
            passwordFormData['new_password1'] = new_password1;
            passwordFormData['new_password2'] = new_password2;

            AuthService.updatePassword(new_password1, new_password2).then(
                _ => setSuccess(true),
                _ => props.handleAPIError("Ha ocurrido un error. Revisa los datos o inténtalo de nuevo más tarde.")
            );
        }
    }

    return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 6,
                        ml: 3,
                        mr: 3,
                    }}
                >
                    {success ? <Typography variant="button" className="success" gutterBottom>¡Contraseña Actualizada
                        correctamente!</Typography> : null}
                    <Typography component="h1" variant="h5">
                        Cambiar Contraseña
                    </Typography>
                    <form className={"Avatar"} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            name="new_password1"
                            label="Introduce tu nueva contraseña"
                            type="password"
                            id="new_password1"
                            autoComplete="new-password"
                            onChange={handleFormFieldChange}
                            error={new_password1 !== new_password2}
                            inputProps={{minLength: 8}}
                            helperText={new_password1 !== new_password2 ? "Las contraseñas no coinciden" : null}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            name="new_password2"
                            label="Introduce tu nueva contraseña de nuevo"
                            type="password"
                            id="new_password2"
                            autoComplete="new-password"
                            onChange={handleFormFieldChange}
                            error={new_password1 !== new_password2}
                            inputProps={{minLength: 8}}
                            helperText={new_password1 !== new_password2 ? "Las contraseñas no coinciden" : null}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="submit"
                            sx={{mt: "16px"}}
                        >
                            Cambiar Contraseña
                        </Button>
                    </form>
                </Box>
    );
}

export default PasswordUpdate;