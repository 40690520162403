import * as XLSX from "xlsx";

export function exportExcel(rows, columns, fileName) {
    const generatedRows = generateExcelRows(rows, columns);
    const worksheet = XLSX.utils.json_to_sheet(generatedRows);
    XLSX.utils.sheet_add_aoa(worksheet, [], {
        origin: 'A1',
    });

    const workbook = XLSX.utils.book_new();
    const fileName30chars = fileName.substring(0, 30);
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName30chars);
    XLSX.writeFile(workbook, fileName + ".xlsx", {compression: true});
}

function generateExcelRows(excelRows, excelColumns) {
    const finalRows = [];
    excelRows.forEach(row => {
        const finalRow = {};
        excelColumns.forEach(column => {
            finalRow[column.headerName] = row[column.field];
        });
        finalRows.push(finalRow);
    });
    return finalRows;
}