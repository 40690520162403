import ListItemButton from "@mui/material/ListItemButton";
import {Link, ListItemAvatar, Typography} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import {LEVELS} from "../utils";

export default function ClassroomListItem(props) {

    function _getAcademicYearString(academicYear) {
        let nextYear = academicYear - 1999;
        return [academicYear, nextYear].join('/');
    }

    function _getLevelString() {
        let text = "";
        if (props.level) text = props.level.name + " - ";
        if (props.lomloe_level) text = LEVELS.find(level => level.id === props.lomloe_level).name + " - ";
        return text;
    }

    return (
        <ListItemButton {...props} component={Link} to={"/clases/" + props.id + "/"}>
            <ListItemAvatar>
                <SchoolIcon color="primary"/>
            </ListItemAvatar>
            <ListItemText primary={props.name} secondary={
                <React.Fragment>
                    {_getLevelString()}
                    <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body"
                    >
                        Curso {_getAcademicYearString(props.academic_year)}
                    </Typography>
                    {" - "}{props.description}
                </React.Fragment>
            }/>
        </ListItemButton>
    );
}