import React, {useState} from "react";
import GenericModal from "../genericui/modals/GenericModal";
import {Checkbox, DialogActions, FormControlLabel, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import {DatePicker, esES, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import Button from "@mui/material/Button";
import TextEditor from "../genericui/formcomponents/TextEditor";
import ColorsSelectInput from "../genericui/calendar/ColorsSelectInput";
import {submitPlannerEvent} from "./PlannerCustomEditorUtils";
import {ClassroomsPlannerInput} from "./PlannerFormInputComponents";
import CalendarDateInput from "../genericui/calendar/CalendarDateInput";
import {EventTitleInput} from "../genericui/calendar/EventTitleInput";

function CalendarTimeInput(props) {
    return <TimePicker
        onChange={props.onChange}
        value={props.value}
        size='small'
        label={props.label}
        fullWidth
        required
        renderInput={(params) => <TextField required size='small' {...params} />}
    />;
}

const END_OF_QUARTER = new Date("2024-06-30T22:00:00");

export default function PlannerCustomEditor(props) {
    const scheduler = props.scheduler;
    const classrooms = props.classrooms;

    const [state, setState] = useState({
        id: scheduler.state?.event_id.value || "",
        title: scheduler.state?.title.value || "",
        body: scheduler.state?.body.value || "",
        date: scheduler.state?.start.value || "",
        start: scheduler.state?.start.value.getTime() || "",
        end: scheduler.state?.end.value.getTime() || "",
        color: scheduler.state?.color.value || "#039BE5",
        classroom_id: scheduler.state?.classroom_id.value || "",
        repeatDate: END_OF_QUARTER,
        isRepeating: false,
    });

    const handleChange = (value, name) => {
        setState((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    function handleSubmit(event) {
        event.preventDefault();
        submitPlannerEvent(scheduler, state, classrooms, props.handleAPIError);
    }

    return (
        <GenericModal onClose={() => scheduler.close()}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3} maxWidth="sm">
                    <Grid item xs={12}>
                        <EventTitleInput value={state.title} handleChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ClassroomsPlannerInput state={state} handleChange={handleChange} classrooms={classrooms}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ColorsSelectInput color={state.color} handleChange={handleChange}/>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}
                                          adapterLocale={es}
                                          localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <Grid item xs={12} sm={12}>
                            <Grid item xs={12} sm={6}>
                                <CalendarDateInput state={state} handleChange={handleChange}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <CalendarTimeInput onChange={(e) => handleChange(e.getTime(), "start")} value={state.start}
                                               label="Inicio"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <CalendarTimeInput onChange={(e) => handleChange(e.getTime(), "end")} value={state.end}
                                               label="Fin"/>
                            <Grid/>
                        </Grid>
                        {props.scheduler.edited ? <></> :
                            <Grid item xs={12} sm={12}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={state.isRepeating}
                                                           onChange={() => handleChange(!state.isRepeating, "isRepeating")}/>}
                                        label="Repetir semanalmente hasta: "/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        id="repeat"
                                        inputFormat='dd MMMM yyyy'
                                        label="Repetir hasta"
                                        openTo="day"
                                        views={['day']}
                                        renderInput={(params) => <TextField required size='small' {...params} />}
                                        value={state.repeatDate}
                                        onChange={(e) => handleChange(e, "repeatDate")}
                                        required
                                        disabled={!state.isRepeating}
                                        state={state}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </Grid>}
                    </LocalizationProvider>
                    <Grid item xs={12} sm={12}>
                        <TextEditor theme="snow" onChange={(value) => handleChange(value, "body")} value={state.body}/>
                    </Grid>
                    <Grid/>
                    <Grid item xs={12} sm={12}>
                        <DialogActions>
                            <Button onClick={scheduler.close}>Cancelar</Button>
                            <Button variant='contained' type="submit">Guardar</Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </form>
        </GenericModal>
    )
        ;
};