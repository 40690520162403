import React, {Component} from 'react';
import Urls from './Urls';
import AuthService from "./services/AuthService";
import AuthVerify from "./common/AuthVerify";
import {createTheme, ThemeProvider} from "@mui/material";
import {blue, deepPurple} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: deepPurple[500],
        },
        secondary: {
            main: blue[200],
        },
        evaluation: {
            background: deepPurple[200],
            headerLightBackground: deepPurple[200],
            headerBackground: deepPurple[400],
        }
    },
});

class App extends Component {
    constructor(props) {
        super(props);
        this._logOut = this._logOut.bind(this);

        this.state = {
            currentUser: undefined,
        }
    }

    _logOut() {
        AuthService.logout();
        this.setState({
            currentUser: undefined,
        });
    }

    render() {
        return (
            <div>
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <Urls {...this.props}/>
                    </div>

                    <AuthVerify logOut={this._logOut}/>
                </ThemeProvider>
            </div>
        );
    }
}

export default App;