import api from "./Api";
import AuthService from "./AuthService";


class EvaluationService {

    createNewActivity(classroomId, data) {
        return api.post("/classrooms/" + classroomId + "/activity/", data);
    }

    editActivity(classroomId, activityId, data) {
        return api.patch("/classrooms/" + classroomId + "/activity/" + activityId + "/", data);
    }

    getActivitiesByTab(classroomId, tabId, subjectId) {
        if (tabId === 0) {
            return api.get("/classrooms/" + classroomId + "/activity/?subject_id=" + subjectId);
        }
        return api.get("/classrooms/" + classroomId + "/activity/?tab_id=" + tabId);
    }

    getFinalMarksSummaryByTabs(classroomId, subjectId, selectedTabs) {
        const url = "/classrooms/" + classroomId + "/activity/?subject_id=" + subjectId;
        return api.get(selectedTabs ? url + "&selected_tabs=" + selectedTabs?.join(",") : url);
    }

    sendMarks(classroomId, activityId, studentId, marks_list) {
        const data = {"marks": marks_list};
        return api.patch("/classrooms/" + classroomId + "/activity/" + activityId + "/marks/", data);
    }

    deleteActivity(classroomId, activityId) {
        return api.delete("/classrooms/" + classroomId + "/activity/" + activityId + "/");
    }

    getCriteriaBySubject(subjectId) {
        return api.get("/lomloev2/criteria/?subject_id=" + subjectId);
    }

    getLomloeCriteriosBySubject(subjectId) {
        return api.get("/lomloev2/curriculo/?subject_id=" + subjectId);
    }

    getTabs(classroomId, subjectId) {
        return api.get('/classrooms/' + classroomId + '/subjects/' + subjectId + '/tabs/');
    }

    createTab(classroomId, subjectId, newTab, copyFromId) {
        newTab.subject_id = subjectId;
        newTab.created_by_id = AuthService.getCurrentUser().user.pk;
        const copyFromContent = copyFromId ? '?copy_from=' + copyFromId : '';
        return api.post('/classrooms/' + classroomId + '/subjects/' + subjectId + '/tabs/' + copyFromContent, newTab);
    }

    deleteTab(classroomId, subjectId, tabId) {
        return api.delete('/classrooms/' + classroomId + '/subjects/' + subjectId + '/tabs/' + tabId + '/');
    }

    updateTab(classroomId, subjectId, tabId, newTab) {
        newTab.subject_id = subjectId;
        newTab.created_by_id = AuthService.getCurrentUser().id;
        return api.patch('/classrooms/' + classroomId + '/subjects/' + subjectId + '/tabs/' + tabId + '/', newTab);
    }

    copyMarks(classroomId, columnsToCopy, columnId) {
        return api.post('/classrooms/' + classroomId + '/activity/' + columnId + '/copy-marks/', columnsToCopy);
    }

    getFinalMarksForAllSubjects(classroomId) {
        return api.get('/classrooms/' + classroomId + '/activity/all-subjects-summary/');
    }
}

export default new EvaluationService();
