import {lazy, Suspense, useCallback, useState} from 'react';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function StudentsRoulette(props) {
    const [isSpinning, setIsSpinning] = useState(false);
    const Wheel = lazy(() => import('./Wheel'))
    const initialStudentsData = props.students.map((student, i) => {
                return {option: student.first_name + " " + student.last_name};
            })
    const [students, setStudents] = useState(initialStudentsData)
    const [winningNumber, setWinningNumber] = useState(-1);
    const [studentName, setStudentName] = useState('\xa0');

    const handleSpinClick = useCallback(() => {
        setStudentName('\xa0');
        const newPrizeNumber = Math.floor(Math.random() * students.length);
        setWinningNumber(newPrizeNumber);
        setIsSpinning(true);
    }, [students])

    function removeCurrentStudent() {
        const localStudents = [...students];
        localStudents.splice(winningNumber, 1);
        setStudents(localStudents)
        setStudentName('\xa0');
    }

    const wheel = useCallback(() => {
        return <Wheel
            key="wheel"
            prizeNumber={winningNumber}
            data={students}
            backgroundColors={['#fafafa', '#ffaaaa', '#f5baff', '#bdd2ff', '#c4e9cb', '#fff69c', '#ffe3c7']}
            textColors={['#000000']}
            mustStartSpinning={isSpinning}
            onStopSpinning={() => {
                setIsSpinning(false);
                setStudentName(students[winningNumber].option);
            }}
            spinDuration={0.4}
            fontSize={14}
        />
    }, [students, winningNumber, isSpinning])

    return <Suspense fallback={() => <Typography>Cargando...</Typography>}>
        <Typography variant="h6">{studentName !== '\xa0' ? "Alumn@ elegid@:" : '\xa0'} </Typography><Typography
        color='primary' variant="h5">{studentName}</Typography>
        {studentName !== '\xa0' && <Button variant="contained" onClick={removeCurrentStudent} sx={{m: 1}}>ELIMINAR ALUMNO</Button>}
        {wheel()}
            <Button variant="contained" onClick={handleSpinClick} sx={{m: 1}}>ELEGIR ALUMNO AL AZAR</Button>
    </Suspense>
}
