import api from "./Api";


class AttendanceService {
    getMissingStudents(classroomId) {
        return api.get('/classrooms/' + classroomId + '/attendance/');
    }

    markMissing(classroomId, day, studentId) {
        const content = {
            "is_missing": true,
            "date": day,
        }
        return api.put('/classrooms/' + classroomId + '/attendance/' + studentId + '/', content);
    }

    unmarkMissing(classroomId, day, studentId) {
        const content = {
            "is_missing": false,
            "date": day,
        }
        return api.put('/classrooms/' + classroomId + '/attendance/' + studentId + '/', content);
    }
}

export default new AttendanceService();
