import api from "./Api";


class PlannerEventsService {
    getExistingPlannerEvent(start, end) {
        const params = {"start": start, "end": end};
        return api.get('/scheduler/planner-events/', { params });
    }

    createNewPlannerEvent(data) {
        return api.post('/scheduler/planner-events/', data);
    }

    createNewRecurringPlannerEvent(data, finalRecurrentDate) {
        return api.post(`/scheduler/planner-events/?repeatUntil=${finalRecurrentDate.toISOString()}`, data);
    }

    updatePlannerEvent(data, eventId) {
        return api.patch('/scheduler/planner-events/' + eventId + '/', data);
    }

    deletePlannerEvent(eventId) {
        return api.delete('/scheduler/planner-events/' + eventId + '/');
    }
}

export default new PlannerEventsService();
