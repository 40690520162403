import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <g>
      <path
        fill="#d1c4e9"
        stroke="#512da8"
        strokeWidth={1.712}
        d="M.049 14.706c-8.118 0-14.755-6.588-14.755-14.706S-8.118-14.706 0-14.706 14.706-8.118 14.706 0c0 3.767-1.418 7.205-3.751 9.808-.341.38-.689.781-1.068 1.125"
        style={{
          paintOrder: "fill",
        }}
        transform="matrix(12.63704 0 0 12.63704 254.776 204.835)"
      />
      <path
        fill="#d1c4e9"
        stroke="#512da8"
        strokeWidth={21.63870736}
        d="m254.774 379.974 2.064 98.393 138.753-152.343 4.769-5.897"
      />
      <path
        fill="none"
        stroke="#512da8"
        strokeWidth={8.241}
        d="M28.076 0h-54.355 54.355z"
        transform="matrix(2.44007 0 0 2.81176 87.507 68.32)"
      />
      <path
        fill="none"
        stroke="#512da8"
        strokeWidth={8.241}
        d="M28.076 0h-54.355 54.355z"
        transform="matrix(-2.44007 0 0 2.81176 421.374 68.32)"
      />
      <path
        fill="#512da8"
        stroke="#512da8"
        strokeWidth={16.634}
        d="m60 40 20 40H0L40 0l20 40z"
        transform="matrix(1.2281 0 0 -1.37357 205.347 266.131)"
      />
      <path
        fill="#fff"
        stroke="#512da8"
        strokeWidth={7.885}
        d="M0-34.247c18.904 0 34.247 15.342 34.247 34.247 0 18.904-15.342 34.247-34.247 34.247-18.904 0-34.247-15.342-34.247-34.247 0-18.904 15.342-34.247 34.247-34.247z"
        transform="matrix(2.74409 0 0 2.74409 162.122 162.098)"
      />
      <path
        fill="#512da8"
        stroke="#512da8"
        strokeWidth={2.321}
        d="M0-14.706c8.118 0 14.706 6.588 14.706 14.706 0 8.118-6.588 14.706-14.706 14.706-8.118 0-14.706-6.588-14.706-14.706 0-8.118 6.588-14.706 14.706-14.706z"
        transform="matrix(3.81422 0 0 3.81422 165.319 168.777)"
      />
      <path
        fill="#fff"
        d="M0-14.706c8.118 0 14.706 6.588 14.706 14.706 0 8.118-6.588 14.706-14.706 14.706-8.118 0-14.706-6.588-14.706-14.706 0-8.118 6.588-14.706 14.706-14.706z"
        transform="matrix(2.24364 0 0 2.24364 134.336 142.043)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#fff"
        stroke="#512da8"
        strokeWidth={8.057}
        d="M0-34.247c18.904 0 34.247 15.342 34.247 34.247 0 18.904-15.342 34.247-34.247 34.247-18.904 0-34.247-15.342-34.247-34.247 0-18.904 15.342-34.247 34.247-34.247z"
        transform="matrix(2.68557 0 0 2.68557 348.816 160.092)"
      />
      <path
        fill="#512da8"
        stroke="#512da8"
        strokeWidth={2.479}
        d="M-1.077-14.177c8.485 0 15.372 6.886 15.372 15.371S7.408 16.565-1.077 16.565-16.448 9.679-16.448 1.194s6.886-15.371 15.371-15.371z"
        style={{
          paintOrder: "fill",
        }}
        transform="translate(355.8 162.354) scale(3.57128)"
      />
      <path
        fill="#fff"
        d="M0-14.706c8.118 0 14.706 6.588 14.706 14.706 0 8.118-6.588 14.706-14.706 14.706-8.118 0-14.706-6.588-14.706-14.706 0-8.118 6.588-14.706 14.706-14.706z"
        transform="matrix(2.19574 0 0 2.19574 321.62 140.489)"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
)
export default SvgComponent