import Box from "@mui/material/Box";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export function MeetingTypeInput(props) {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <FormControl required fullWidth>
            <InputLabel size="small" id="meeting">Tipo de Reunión</InputLabel>
            <Select
                labelId="meeting"
                label="Tipo de Reunión"
                id="meeting_type"
                size='small'
                value={props.state.meeting_type}
                onChange={(e) => props.handleChange(e.target.value, "meeting_type")}
            >
                <MenuItem key={0} value={"CLAUSTRO"}>Claustro</MenuItem>
                <MenuItem key={1} value={"EQUIPO DE CICLO"}>Equipo de Ciclo</MenuItem>
                <MenuItem key={2} value={"EQUIPO DE NIVEL"}>Equipo de Nivel</MenuItem>
                <MenuItem key={3} value={"EQUIPO DOCENTE"}>Equipo Docente</MenuItem>
                <MenuItem key={4} value={"CONSEJO ESCOLAR"}>Consejo Escolar</MenuItem>
                <MenuItem key={5} value={"ETCP"}>ETCP</MenuItem>
            </Select>
        </FormControl>
    </Box>;
}
