import Box from "@mui/material/Box";
import {Checkbox, Dialog, DialogActions, DialogTitle, Grid, IconButton, Stack, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, {useEffect} from "react";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TODOService from "../../services/TODOService";

const TodoList = () => {
    const [text, setText] = React.useState('');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [currentTaskId, setCurrentTaskId] = React.useState(0);
    const [tasks, setTasks] = React.useState([{text: 'Tarea 1', completed: false}, {
        text: 'Tarea 2',
        completed: false
    }, {text: 'Tarea 1', completed: true}, {text: 'Tarea 2', completed: true}]);

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    useEffect(() => {
        TODOService.getAllTasks().then(response => {
            setTasks(response.data);
        });
    }, []);

    const handleNewTask = () => {
        TODOService.addTask({text: text, completed: false}).then(response => {
            setTasks([...tasks, response.data]);
            setText("");
        });
    };

    const handleCheck = (taskId) => {
        const newTasks = [...tasks];
        const index = newTasks.findIndex(task => task.id === taskId);
        TODOService.updateTask(taskId, {
            completed: !newTasks[index].completed
        }).then(response => {
            newTasks[index].completed = !newTasks[index].completed;
            setTasks(newTasks);
        })
    };

    const handleDelete = () => {
        const newTasks = [...tasks];
        TODOService.deleteTask(currentTaskId).then(response => {
            const currentTaskIndex = newTasks.findIndex(task => task.id === currentTaskId);
            newTasks.splice(currentTaskIndex, 1);
            setTasks(newTasks);
            setIsDeleteDialogOpen(false);
        });
    };

    return (
        <Box width='100%'>
            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)} sx={{m: 2}}>
                <DialogTitle>¿De verdad quieres eliminar esta tarea?</DialogTitle>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsDeleteDialogOpen(false)}>Cancelar</Button>
                    <Button variant="contained" color="error" onClick={() => handleDelete()}>Eliminar</Button>
                </DialogActions>
            </Dialog>
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                        <TextField
                            id="new-task"
                            label="Nombre de nueva tarea"
                            fullWidth
                            onChange={handleTextChange}
                            value={text}
                            size='small'
                            autoComplete="off"
                        />
                        <Button variant="contained" color="info" startIcon={<AddIcon/>} onClick={handleNewTask}>Añadir</Button>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {tasks.filter(task => !task.completed).length > 0 &&
                        <Typography variant="h6" sx={{mt: 2}}>Por hacer:</Typography>}
                </Grid>
                {tasks.filter(task => !task.completed).map((task, i) => {
                    return (
                        <Grid item xs={12} key={i} sx={{backgroundColor: i % 2 === 0 ? '#f5f5f5' : '#ffffff'}}>
                            <Grid container direction="row" spacing={1} sx={{justifyContent: 'space-between'}}>
                                <Grid item xs={9}>
                                    <Stack direction="row" sx={{alignItems: 'center'}}>
                                        <Checkbox checked={false} onChange={() => handleCheck(task.id)}/>
                                        <Typography variant="body1">{task.text}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton color="error" onClick={() => {
                                        setCurrentTaskId(task.id);
                                        setIsDeleteDialogOpen(true);
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid item xs={12} sx={{mt: 2}}>
                    {tasks.filter(task => task.completed).length > 0 && <Typography variant="h6">Hechas:</Typography>}
                </Grid>
                {tasks.filter(task => task.completed).map((task, i) => {
                    return (
                        <Grid item xs={12} key={i} sx={{backgroundColor: i % 2 === 0 ? '#f5f5f5' : '#ffffff'}}>
                            <Grid container direction="row" spacing={1}
                                  sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Grid item xs={9}>
                                    <Stack direction="row" sx={{alignItems: 'center'}}>
                                        <Checkbox checked={true} onChange={() => handleCheck(task.id)}/>
                                        <Typography variant="body1"
                                                    sx={{textDecoration: 'line-through'}}>{task.text}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton color="error" onClick={() => {
                                        setCurrentTaskId(task.id);
                                        setIsDeleteDialogOpen(true);
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>

        </Box>
    );
}

export default TodoList;