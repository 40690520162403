import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {DataObject} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export default function CopyMarksColumnMenuItem(props) {
    return (
            <MenuItem onClick={props.handleClick}>
                <ListItemIcon>
                    <DataObject fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Copiar notas a otra columna</ListItemText>
            </MenuItem>
    );
}
