import {
    Dialog, DialogActions, DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar, Skeleton,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useOutletContext} from 'react-router-dom';
import CRUDMenu from "../../genericui/menus/CRUDMenu";
import Button from "@mui/material/Button";
import {Add, PanoramaFishEye, Search} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import ListItemButton from "@mui/material/ListItemButton";
import SchoolIcon from "@mui/icons-material/School";
import ListItemText from "@mui/material/ListItemText";
import SubjectModal from "./SubjectModal";
import SubjectService from "../../../services/SubjectService";
import {Link, useParams} from "react-router-dom";
import AllSubjectsSummary from "./AllSubjectsSummary";
import {evaluationBoxStyles, LATEST_CLASSROOM_ID_FROM_2023} from "../utils";


function SubjectListItem(params) {
    return (
        <><ListItemButton key={params.key} component={Link} to={params.url}>
            <ListItemAvatar>
                <SchoolIcon color="primary"/>
            </ListItemAvatar>
            <ListItemText primary={params.name} secondary={params.description}/>
        </ListItemButton></>
    );
}

export default function SubjectList(props) {
    const {classroomId} = useParams();
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [subjectItems, setSubjectItems] = useState([]);
    const [search, setSearch] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [subjectToDelete, setSubjectToDelete] = useState(null);

    useEffect(loadInitialData);

    function loadInitialData() {
        if (isFirstLoad) {
            SubjectService.getSubjectsByClassroom(classroomId).then(
                subjects => {
                    setSubjectItems(subjects.data);
                    setIsFirstLoad(false);
                },
                _ => context.handleAPIError("Ha ocurrido un error cargando tus asignaturas. Inténtalo de nuevo más tarde.")
            );
        }
    }

    function mapSubjectIntoList() {
        return (
            <>
                {
                    subjectItems.map((subject, i) => {
                            if ((subject.name).toLowerCase().includes(search.toLowerCase())) {
                                return (
                                    <React.Fragment key={'maincontainer' + i}>
                                        <ListItem disableGutters sx={{
                                            "& .MuiListItemButton-root": {
                                                paddingRight: "10px!important",
                                                paddingLeft: "20px!important",
                                            },
                                        }} secondaryAction={
                                            <CRUDMenu
                                                key={i}
                                                subject={subject}
                                                handleClickOnDelete={() => handleClickOnDelete(subject.id)}
                                            />
                                        }>
                                            <SubjectListItem
                                                id={subject.id}
                                                name={subject.name}
                                                url={subject.id + "/"}
                                            />
                                        </ListItem>
                                        {i + 1 !== subjectItems.length && <Divider key={i} sx={{my: 1}}/>}
                                    </React.Fragment>
                                );
                            } else {
                                return <></>
                            }
                        }
                    )
                }
            </>);
    }

    function handleClickOnDelete(subject_id) {
        setSubjectToDelete(subject_id);
    }

    function deleteSubject() {
        SubjectService.deleteSubject(classroomId, subjectToDelete).then(
            (response) => {
                let currentSubjects = [...subjectItems];
                currentSubjects = currentSubjects.filter(item => item.id !== subjectToDelete);
                setSubjectItems(currentSubjects);
                setSubjectToDelete(null);
            },
            _ => context.handleAPIError("Ha ocurrido un error eliminando esta asignatura. Recarga la página" +
                " o inténtalo de nuevo más tarde.")
        );
    }

    function displayEmptyClassroomsMessage() {
        return <ListItem>🎓 No tienes ninguna Asignatura creada todavía. ¡Prueba añadiendo una! 🎓</ListItem>
    }

    function searchField() {
        return (
            <TextField
                label="Buscar"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                fullWidth
                size="small"
                sx={{display: 'flex', alignItems: 'right', width: 300, m: 2}}
                onChange={(event) => setSearch(event.target.value)}
            />
        );
    }

    function addNewSubject(subject) {

        SubjectService.createNewSubject(classroomId, subject).then((response) => {
                addSubjectToList(response.data);
                setShowModal(!showModal);
            },
            _ => context.handleAPIError("Ha ocurrido un error creando esta asignatura. " +
                "Revisa los datos o intenta de nuevo más tarde.")
        );
    }

    function addSubjectToList(subject) {
        const localSubjectItems = [...subjectItems];
        localSubjectItems.push(subject);
        setSubjectItems(localSubjectItems);
    }

    return <>
        {showModal ? <SubjectModal onSave={addNewSubject} onClose={() => setShowModal(false)} {...context}/> : <></>}
        <Dialog open={subjectToDelete !== null} onClose={() => setSubjectToDelete(null)} sx={{m: 2}}>
            <DialogTitle>¿De verdad quieres eliminar esta asignatura? Todas sus notas y sus evaluaciones se
                perderán.</DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={() => setSubjectToDelete(null)}>Cancelar</Button>
                <Button variant="contained" color="error" onClick={() => deleteSubject()}>Eliminar</Button>
            </DialogActions>
        </Dialog>
        <Grid container sx={{p: 1, display: 'flex', alignItems: 'center'}}>
            {subjectItems.length > 0 && <Grid item sx={{m: 2}} xs={12} lg={4}>
                <Button variant="outlined" startIcon={<PanoramaFishEye/>} onClick={() => setShowSummary(!showSummary)}
                        fullWidth>{showSummary ? "Ocultar" : "Ver"} notas de Asignaturas</Button>
            </Grid>}
            {showSummary ?
                <Grid item xs={12} sx={evaluationBoxStyles}>
                    <AllSubjectsSummary subjects={subjectItems}/>
                </Grid>
                : <>
                    {classroomId > LATEST_CLASSROOM_ID_FROM_2023 && <Grid item sx={{m: 2}} xs={12} lg={4}>
                        <Button variant="contained" startIcon={<Add/>} onClick={() => setShowModal(true)}
                                fullWidth>Añadir Asignatura</Button>
                    </Grid>}
                    <Grid item xs={12}>
                        {searchField()}
                        {isFirstLoad ? <Skeleton variant="rectangular" height={"1000px"}/> : <List sx={{
                            width: '100%',
                            bgcolor: 'background.paper'
                        }}>{subjectItems.length > 0 ? mapSubjectIntoList() : displayEmptyClassroomsMessage()}</List>}
                    </Grid>
                </>}
        </Grid>
    </>
}
