import TabsActionMenu from "./TabsActionMenu";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import {alpha, Grid, Tab, Tabs} from "@mui/material";
import EditTabModal from "./EditTabModal";
import EvaluationService from "../../../../services/EvaluationService";
import ConfirmDeletionModal from "../../../genericui/modals/ConfirmDeletionModal";
import AddTabModal from "./AddTabModal";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {LATEST_CLASSROOM_ID_FROM_2023} from "../../utils";

export default function EvaluationTabs(props) {
    const {classroomId, subjectId} = useParams();
    const [currentTab, setCurrentTab] = useState({});
    const [isAddTabModalOpen, setIsAddTabModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const message = "¿De verdad quieres borrar esta pestaña? Esta acción no podrá deshacerse";

    function handleClickOnEdit(element) {
        setCurrentTab(element);
        setIsEditModalOpen(true);
    }

    function handleEdit(formData) {
        EvaluationService.updateTab(classroomId, subjectId, formData.id, formData).then((response) => {
                const localTabs = [...props.tabs];
                const itemFound = localTabs.find(element => element.id === formData.id);
                Object.assign(itemFound, response.data);
                props.setTabs(localTabs);
            },
            _ => props.handleAPIError("Ha ocurrido un error. Recarga la página o inténtalo de nuevo más tarde."))
    }

    function addTab(newTabContent) {
        EvaluationService.createTab(classroomId, subjectId, newTabContent, newTabContent.copyFrom).then((response) => {
            const localTabs = [...props.tabs];
            localTabs.push(response.data);
            props.setTabs(localTabs);
        })
    }

    function handleClickOnDelete(element) {
        setCurrentTab(element);
        setIsDeleteModalOpen(true);
    }

    function handleDelete() {
        setIsDeleteModalOpen(false);
        EvaluationService.deleteTab(classroomId, subjectId, currentTab.id).then((response) => {
                const itemFound = props.tabs.find(element => element.id === currentTab.id);
                const index = props.tabs.indexOf(itemFound);
                const localTabs = [...props.tabs];
                localTabs.splice(index, 1);
                props.setTabs(localTabs);
            },
            _ => props.handleAPIError("Ha ocurrido un error. Recarga la página o inténtalo de nuevo más tarde."))
    }

    return <Grid container alignItems="center">
        <Grid item xs={9} sm={10}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                {isEditModalOpen &&
                    <EditTabModal tab={currentTab} onClose={() => setIsEditModalOpen(false)} onSubmit={handleEdit}/>}
                {isDeleteModalOpen &&
                    <ConfirmDeletionModal message={message} isOpen={isDeleteModalOpen}
                                          onClose={() => setIsDeleteModalOpen(false)}
                                          onConfirm={handleDelete} {...props}/>}
                {isAddTabModalOpen &&
                    <AddTabModal onSubmit={addTab} tabs={props.tabs} onClose={() => setIsAddTabModalOpen(false)}/>}
                <Tabs value={props.currentTab} onChange={(event, value) => props.setCurrentTab(value)}
                      variant="scrollable"
                      allowScrollButtonsMobile>
                    {props.tabs.map(element => {
                        let tabsActionMenu = <TabsActionMenu sx={[{
                            '&:hover': {
                                backgroundColor: '#f4f2fc',
                                borderRadius: "10px",
                            }
                        }]}
                                                             handleClickOnEdit={() => handleClickOnEdit(element)}
                                                             handleClickOnDelete={() => handleClickOnDelete(element)}
                        />
                        return <Tab icon={element.id !== 0 && tabsActionMenu} iconPosition="end" label={element.name}
                                    sx={{
                                        backgroundColor: alpha(element.color, .1),
                                    }}/>
                    })}
                </Tabs>
            </Box>
        </Grid>
        {classroomId > LATEST_CLASSROOM_ID_FROM_2023 && <Grid item xs={3} sm={2}>
            <Button variant="contained" fullWidth size="small" onClick={() => setIsAddTabModalOpen(true)}
                    startIcon={<Add/>}>
                Nueva Pestaña
            </Button>
        </Grid>}
    </Grid>
}