import ListItemButton from "@mui/material/ListItemButton";
import {ListItemAvatar} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import React, {Component} from "react";
import {stringAvatar} from "../../genericui/avatars/AvatarUtils";

export default class StudentListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: props.first_name,
            last_name: props.last_name,
            number: props.number,
        }
    }

    render() {
        return (
            <ListItemButton onClick={this.props.onClick}>
                <ListItemAvatar>
                    <Avatar sx={stringAvatar(this.state.first_name, this.state.last_name)}>
                        {this.state.number}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={this.state.last_name + ', ' + this.state.first_name}/>
            </ListItemButton>
        );
    }
}
