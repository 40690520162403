import api from './Api';

class SubscriptionService {
    getSession(sessionId) {
        return api.get('/subscription/session/?session_id=' + sessionId);
    }

    createSession() {
        return api.post('/subscription/session/');
    }

    getPortalLink() {
        return api.get('/subscription/portal-link/');
    }
}

export default new SubscriptionService();