import {Typography} from "@mui/material";

export default function PlannerEventCustomRenderer(event) {
    const isEventLongerThan15Mins = ((event.end.getTime() - event.start.getTime()) / 1000) > (60 * 15);
    return (
        <div style={{padding: 2}}>
            <Typography sx={{fontWeight: 'bold'}} variant="subtitle1" style={{fontSize: 12}} noWrap>
                {event.title}
            </Typography>
            {isEventLongerThan15Mins ? _classroomName(event) : null}
        </div>
    );
}

function _classroomName(event) {
    return (<Typography variant="subtitle2" style={{fontSize: 12}}>
        {event.body.replace(/<[^>]+>/g, '')}
    </Typography>);
}
