import {Typography} from "@mui/material";
import TextFormInput from "../genericui/formcomponents/TextFormInput";
import Button from "@mui/material/Button";
import * as React from "react";
import GoogleFormsService from "../../services/GoogleFormsService";
import {useState} from "react";

const GOOGLE_FORM_ID = "1FAIpQLSdlodFCWoDw96rBYX-amk_B7Or4vqhop-zDKKeAIt62DZUjHQ";

export default function HomePageFeedbackForm(props) {
    const [formContent, setFormContent] = useState("");

    function submitFeedbackForm(event) {
        event.preventDefault();
        const formData = {
            "entry.623116461": formContent,
        }
        const entryNameUser = "entry.559587813";
        GoogleFormsService.submitFormResponse(GOOGLE_FORM_ID, formData, entryNameUser).then(
            _ => props.showSuccessMessage("Tu opinión ha sido recibida con éxito. Muchas gracias."),
            errorResponse => {
                if (errorResponse.message === "Network Error") {
                    props.showSuccessMessage("Tu opinión ha sido recibida con éxito. Muchas gracias.");
                } else {
                    props.handleAPIError("Ha ocurrido un error. Por favor, inténtalo más tarde.")
                }
            }
        )
    }

    return <>
        <Typography align="center" color="primary" component="h2" variant="h5">Danos tu
            opinión</Typography>
        <Typography>¿Te gustaría que añadiésemos algo a la app? ¿Tienes problemas usándola? ¿Quieres
            decirnos lo que más te gusta? ¡Por favor, háznoslo saber en este
            formulario!</Typography>
        <form onSubmit={submitFeedbackForm}>
            <TextFormInput id="description"
                           label="Tu opinión"
                           onChange={event => setFormContent(event.target.value)}
                           value={formContent}
                           multiline
                           minRows={3}
                           fullWidth
                           required
            />
            <Button
                type="submit"
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >Enviar</Button>
        </form>
    </>
}