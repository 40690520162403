import api from "./Api";


class MaterialService {
    getMaterial(classroomId) {
        return api.get('/classrooms/' + classroomId + '/material/');
    }

    addColumn(classroomId, columnName) {
        const escapedColumnName = columnName.replaceAll("/", ",.,.");
        return api.post('/classrooms/' + classroomId + '/material/columns/' + escapedColumnName + '/');
    }

    removeColumn(classroomId, columnName) {
        const escapedColumnName = columnName.replaceAll("/", ",.,.");
        return api.delete('/classrooms/' + classroomId + '/material/columns/' + escapedColumnName + '/');
    }

    markDelivered(classroomId, columnName, studentId) {
        const escapedColumnName = columnName.replaceAll("/", ",.,.");
        return api.post('/classrooms/' + classroomId + '/material/columns/' + escapedColumnName + '/' + studentId + '/');
    }

    unmarkDelivered(classroomId, columnName, studentId) {
        const escapedColumnName = columnName.replaceAll("/", ",.,.");
        return api.delete('/classrooms/' + classroomId + '/material/columns/' + escapedColumnName + '/' + studentId + '/');
    }
}

export default new MaterialService();
