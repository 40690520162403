import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Logo from './Logo';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import AuthService from "../services/AuthService";
import {withRouter} from "../withRouter";
import ErrorMessage from "./genericui/feedback/ErrorMessage";
import SubCard from "./genericui/cards/SubCard";
import {Navigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";

function PasswordReset(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    useEffect(() => {
        if (props.isAuthenticated) {
            return <Navigate to='/'/>
        }
    })

    function _onChangeUsername(e) {
        setEmail(e.target.value);
    }

    function _handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        AuthService.resetPassword(email).then(
            () => {
                setIsRequestSent(true);
            },
            error => {
                const responseMessage = "Ha ocurrido un error. Por favor, intenta de nuevo más tarde."

                setErrorMessage(responseMessage);
                setIsErrorMessageVisible(true);
            }
        ).finally(() => setIsLoading(false));
    }

    function SentMessagePanel() {
        return <><Grid item className="form" component="form" onSubmit={_handleSubmit} xs={12}>
            <Typography>Te hemos enviado un correo con instrucciones para restablecer tu contraseña.</Typography></Grid><Grid
            item className="form" component="form" onSubmit={_handleSubmit} xs={12}><Typography> Si no lo has
            recibido en 5 minutos, por favor, mira en la carpeta de Spam o revisa la dirección introducida.</Typography>
        </Grid></>
    }

    return (
        <>
            {isErrorMessageVisible &&
                <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={errorMessage}/>}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    alignItems: 'center',
                    height: '100vh',
                    overflow: 'auto',
                    color: 'white',
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23003A75' fill-opacity='0.1'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                }}
            >
                <Container maxWidth="md" sx={{paddingTop: 4}}>
                    <SubCard>
                        <CssBaseline/>
                        <Box sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        >
                            <Box sx={{width: "75px", justifyContent: 'center', alignItems: 'center'}}><Logo
                                alt={"4docent.es logo"}/></Box>
                            <Typography variant={"h4"} color={"primary"}>Recupera tu contraseña</Typography>
                            <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center', mt: 1}}
                                  maxWidth="328px">
                                {isRequestSent ? <SentMessagePanel/> :
                                    <Grid item className="form" component="form" onSubmit={_handleSubmit} xs={12}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Correo Electrónico"
                                            name="email"
                                            value={email}
                                            autoComplete="email"
                                            size="small"
                                            onChange={_onChangeUsername}
                                        />
                                        <LoadingButton
                                            loading={isLoading}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{mt: 2, mb: 1}}
                                        >
                                            Recuperar Contraseña
                                        </LoadingButton>
                                    </Grid>}
                                <Grid item xs={12} sx={{mb: 2}}>
                                    <Button fullWidth variant="outlined" href="/login">
                                        Volver a iniciar sesión
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </SubCard>
                </Container>
            </Box>
        </>
    );
}

export default withRouter(PasswordReset);
