import api from "./Api";
import TokenService from "./TokenService";

class AuthService {
    login(email, password) {
        return api
            .post("/dj-rest-auth/login/", {
                "email": email,
                "password": password,
            })
            .then(response => {
                if (response.data.access) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    google_login(code) {
        return api.post("/dj-rest-auth/google/", {"access_token": code}).then(response => {
                if (response.data.access) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }

    register(email, password) {
        return api.post("/dj-rest-auth/registration/", {
            username: email,
            email: email,
            password1: password,
            password2: password,
        });
    }

    resetPassword(email) {
        return api.post("/dj-rest-auth/password/reset/", {"email": email});
    }

    resetPasswordConfirm(userId, token, password1, password2) {
        const data = {
            "uid": userId,
            "token": token,
            "new_password1": password1,
            "new_password2": password2,
        }
        return api.post("/dj-rest-auth/password/reset/confirm/", data);
    }

    updatePassword(new_password1, new_password2) {
        return api.post('/auth/update-password/', {
            new_password1,
            new_password2,
        });
    }

    getCurrentUser() {
        return TokenService.getUser();
    }
}

export default new AuthService();