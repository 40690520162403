import GenericModal from "../../genericui/modals/GenericModal";
import {
    Autocomplete,
    Checkbox, DialogActions,
    FormControl,
    FormControlLabel, FormLabel, Grid, Skeleton,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import EvaluationService from "../../../services/EvaluationService";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function CopyMarksModal(props) {
    const column = props.column;
    const [columnsToCopy, setColumnsToCopy] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [currentTab, setCurrentTab] = useState(props.tabs[0]);
    const [isLoading, setIsLoading] = useState(true);
    const finalMarksTabIndex = props.tabs.indexOf(props.tabs.find((element) => element.id === 0));
    const tabs = [...props.tabs];
    tabs.splice(finalMarksTabIndex, 1);

    useEffect(() => {
        if (isLoading) {
            EvaluationService.getActivitiesByTab(props.classroomId, currentTab.id).then((response) => {
                setColumnsToCopy([]);
                const localColumns = []
                response.data.forEach((element) => {
                    if (element.id && element.id !== -999999999 && element !== column.id) {
                        localColumns.push(element);
                    }
                    setColumnsToShow(localColumns);
                    setIsLoading(false);
                });
            })
        }
    }, [column.id, currentTab, isLoading, props.classroomId, props.tab]);

    function handleSubmit(e) {
        e.preventDefault();
        EvaluationService.copyMarks(props.classroomId, {'column_ids': columnsToCopy}, props.column.id).then(response => {
                props.onClose();
                props.showSuccessMessage("Las notas se han copiado correctamente.")
            },
            _ => props.handleAPIError("No se han podido copiar las notas. Revisa los datos o intenta de nuevo más tarde."));
    }

    function TabSelector() {
        return <Grid item xs={12} sm={12}>
            <Autocomplete
                id="tabs"
                inputValue={currentTab.name}
                value={currentTab}
                options={tabs}
                getOptionLabel={(option) => option.name}
                onChange={handleTabSelectorChange}
                renderInput={(params) => <TextField sx={{m: '1%', mt: 1.5, minWidth: '48%'}} {...params}
                                                    size="small" label="Selecciona una pestaña"
                                                    variant="standard"/>}
            />
        </Grid>
    }

    function handleTabSelectorChange(event, value) {
        const tab = value ? value : "";
        setCurrentTab(tab);
        setIsLoading(true);
    }

    function handleChange(event) {
        const id = parseInt(event.target.id);
        let localColumnsToCopy = [...columnsToCopy];

        if (event.target.checked) {
            localColumnsToCopy.push(id);
        }
        if (!event.target.checked) {
            const index = localColumnsToCopy.indexOf(id);
            if (index > -1) {
                localColumnsToCopy.splice(index, 1);
            }
        }
        setColumnsToCopy(localColumnsToCopy);
    }

    function renderColumnItems() {
        if (columnsToShow.length < 1) {
            return <Grid item xs={12} sm={12} key={0}>Esta pestaña no tiene ninguna columna todavía.</Grid>
        }
        return (
            <>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend">Selecciona las columnas a las que quieres copiar las
                        notas</FormLabel>
                </Grid>
                {columnsToShow.map((currentColumn, i) => {
                        const id = currentColumn.id;
                        const selected = columnsToCopy.includes(id);
                        return (
                            <Grid item xs={12} sm={12} key={id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={selected} onChange={handleChange} id={id.toString()}/>
                                    }
                                    label={currentColumn.title}
                                />
                            </Grid>
                        );
                    }
                )}</>);
    }

    return <GenericModal>
        <Box maxWidth="sm">
            <Grid container spacing={1} sx={{justifyContent: 'center', alignItems: 'center', mt: 1}}>
                <Grid item xs={12} sm={12}>
                    <Typography>¡ATENCIÓN! Las columnas a las que copies perderán sus notas actuales y serán
                        reemplazadas por las notas de la columna "{column.headerName}".</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TabSelector/>
                </Grid>
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{m: 3}} component="fieldset" variant="standard">

                        {isLoading ? <Skeleton variant="rectangular" height={300} width={500}/> : renderColumnItems()}

                        <Grid item xs={12} sm={12}>
                            <DialogActions>
                                <Button onClick={props.onClose}>Cancelar</Button>
                                <Button variant='contained' type="submit">Copiar notas</Button>
                            </DialogActions>
                        </Grid>
                    </FormControl>
                </form>
            </Grid>
        </Box>
    </GenericModal>
}