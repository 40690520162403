import MainCard from "../genericui/cards/MainCard";
import ProcessPayment from "./ProcessPayment";
import CheckoutForm from "./CheckoutForm";

const Subscription = () => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id');

    return <MainCard>
        {sessionId ? <ProcessPayment sessionId={sessionId}/> : <CheckoutForm/>}
    </MainCard>;
}

export default Subscription;
