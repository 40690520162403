import React from "react";
import GenericModal from "./GenericModal";
import {DialogActions, Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";

export default function ConfirmDeletionModal(props) {
    const {message, onConfirm, onClose, isOpen} = props;

    function buttonsDialog() {
        return <Grid item xs={12} sm={12}>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={onConfirm} variant='contained'>Eliminar</Button>
            </DialogActions>
        </Grid>
    }

    if (isOpen) {
        return <GenericModal onClose={onClose}>
            <Grid container spacing={1} maxWidth="sm" alignItems="flex-end" direction="column">
                <Grid item>
                    <Typography>{message}</Typography>
                </Grid>
                <Grid>
                    {buttonsDialog()}
                </Grid>
            </Grid>
        </GenericModal>
    }
    return <></>
}