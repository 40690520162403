import {forwardRef} from 'react';

import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';

const headerSX = {
    '& .MuiCardHeader-action': {mr: 0}
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {
                p: 0.3, "&:last-child": {
                    paddingBottom: 0.3
                }
            },
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            header,
            ...others
        },
        ref
    ) => {
        return (
            <Card
                ref={ref}
                {...others}
                sx={sx}
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary}/>}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary}/>
                )}
                {header && (<CardHeader sx={headerSX} title={header} action={secondary}/>)}

                {/* content & header divider */}
                {title && <Divider/>}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
