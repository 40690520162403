import React, {useState} from "react";
import GenericModal from "../genericui/modals/GenericModal";
import {DialogActions, Grid} from "@mui/material";
import {esES, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import Button from "@mui/material/Button";
import TextEditor from "../genericui/formcomponents/TextEditor";
import ColorsSelectInput from "../genericui/calendar/ColorsSelectInput";
import CalendarDateInput from "../genericui/calendar/CalendarDateInput";
import {submitDiaryEvent} from "./DiaryCustomEditorUtils";

export default function DiaryCustomEditor(props) {
    const scheduler = props.scheduler;

    const [state, setState] = useState({
        id: scheduler.state?.event_id.value || "",
        title: scheduler.state?.title.value || "",
        body: scheduler.state?.body.value || "",
        date: scheduler.state?.start.value || "",
        start: scheduler.state?.start.value.getTime() || "",
        end: scheduler.state?.end.value.getTime() || "",
        color: scheduler.state?.color.value || "#039BE5",
    });

    const handleChange = (value, name) => {
        setState((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    function handleSubmit(event) {
        event.preventDefault();
        submitDiaryEvent(scheduler, state, props.handleAPIError);
    }

    return (
        <GenericModal onClose={() => scheduler.close()}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3} maxWidth="sm">
                    <Grid item xs={12} sm={6}>
                        <ColorsSelectInput color={state.color} handleChange={handleChange}/>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}
                                          adapterLocale={es}
                                          localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <Grid item xs={12} sm={6}>
                            <CalendarDateInput state={state} handleChange={handleChange}/>
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} sm={12}>
                        <TextEditor theme="snow" onChange={(value) => handleChange(value, "body")} value={state.body}/>
                    </Grid>
                    <Grid/>
                    <Grid item xs={12} sm={12}>
                        <DialogActions>
                            <Button onClick={scheduler.close}>Cancelar</Button>
                            <Button variant='contained' type="submit">Guardar</Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </form>
        </GenericModal>
    );
};